import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Routing from "./routes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AppBarComponent from "./components/appBar";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-WR7QQ8NQ",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <BrowserRouter>
      <Routing />
      <ToastContainer position="top-center" />
    </BrowserRouter>
  );
}

export default App;
