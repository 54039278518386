import { Box, Dialog, IconButton } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';


const VideoDialogRepresentation = ({ isVideoOpen, handleCloseVideo, selectedVideo }) => {
    return (
        <div>
            <Dialog
                open={isVideoOpen}
                onClose={handleCloseVideo}
                maxWidth="md"
                fullWidth
                onBackdropClick={() => { }}
            >
                <Box sx={{ position: 'relative' }}>
                    <IconButton
                        onClick={handleCloseVideo}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            backgroundColor: 'white',
                            zIndex: 1,
                            padding: '4px',
                            '&:hover': {
                                backgroundColor: '#f5f5f5',
                            },
                            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                    <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
                        <iframe
                            src={`${selectedVideo}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                            }}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                            title="Training Video"
                        />
                    </Box>
                </Box>
            </Dialog>
        </div>
    )
}

export default VideoDialogRepresentation
