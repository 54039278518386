import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { Square } from "@mui/icons-material";
import { Chart } from "react-google-charts";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import { API } from "aws-amplify";
import { Button } from "@mui/material";
import tagManagerEvents from "../utills/GtmUtils";
import googleTagTriggers from "../utills/google-tag-triggers.json";
const AnalysisCashReport = () => {
  const componentRef = useRef();
  const companyName = localStorage.getItem("companyName");
  const { state } = useLocation();
  const cashDataFromState = state?.formData;
  const [cashFlowData, setCashFlowData] = useState();
  const [stateList, setStateList] = useState([]);
  const [isConcordeBroker, setIsConcordeBroker] = useState(false);
  const domRef = sessionStorage.getItem("domRef");

  const stateCode = cashFlowData?.propertyState?.split(" - ")[1];
  const matchingState = stateList?.find((state) => state.code === stateCode);
  const percentTax = matchingState ? matchingState.percent / 100 : "0";
  useEffect(() => {
    tagManagerEvents(googleTagTriggers.cashFlowReport);
  }, []);
  const propertyTax =
    cashFlowData?.annualPropertyTax === ""
      ? +cashFlowData?.estimatedGrossValue * +percentTax
      : +cashFlowData?.annualPropertyTax;

  useEffect(() => {
    if (cashDataFromState && typeof cashDataFromState === "object") {
      setCashFlowData(cashDataFromState);
    }
  }, [cashDataFromState]);

  useEffect(() => {
    getStates();
    fetchBrokerDealers();
  }, []);

  useEffect(() => {
    if (cashFlowData) {
      const dynamicValues = {
        allOther: cashFlowData.allOther,
        amountBorrowed: cashFlowData.amountBorrowed,
        OtherMisc: cashFlowData.OtherMisc,
        adjustedbasis: cashFlowData.adjustedbasis,
        annualPropertyTax: cashFlowData.annualPropertyTax,
        associationDues: cashFlowData.associationDues,
        bookkeeping: cashFlowData.bookkeeping,
        capexSincePurchase: cashFlowData.capexSincePurchase,
        depreciationClaimed: cashFlowData.depreciationClaimed,
        eachUnitVacany: cashFlowData.eachUnitVacany,
        estimatedGrossValue: cashFlowData.estimatedGrossValue,
        expectedSale: cashFlowData.expectedSale,
        facilityMaintenance: cashFlowData.facilityMaintenance,
        forwardlosses: cashFlowData.forwardlosses,
        grossRent: cashFlowData.grossRent,
        hardscaping: cashFlowData.hardscaping,
        hardscapingYears: cashFlowData.hardscapingYears,
        hoaFee: cashFlowData.hoaFee,
        hvac: cashFlowData.hvac,
        hvacYears: cashFlowData.hvacYears,
        interestRate: cashFlowData.interestRate,
        issurance: cashFlowData.issurance,
        landscape: cashFlowData.landscape,
        legal: cashFlowData.legal,
        loanBalance: cashFlowData.loanBalance,
        loanTerms: cashFlowData.loanTerms,
        majorRepairs: cashFlowData.majorRepairs,
        marketing: cashFlowData.marketing,
        mortgagepayment: cashFlowData.mortgagepayment,
        personalhoursSpent: cashFlowData.personalhoursSpent,
        plumbing: cashFlowData.plumbing,
        plumbingYears: cashFlowData.plumbingYears,
        propertyManagement: cashFlowData.propertyManagement,
        principal: cashFlowData.principal,
        purchaseAmount: cashFlowData.purchaseAmount,
        reimbursedUtility: cashFlowData.reimbursedUtility,
        reimbursements: cashFlowData.reimbursements,
        roof: cashFlowData.roof,
        roofYears: cashFlowData.roofYears,
        security: cashFlowData.security,
        structural: cashFlowData.structural,
        structuralYears: cashFlowData.structuralYears,
        tenants: cashFlowData.tenants,
        trash: cashFlowData.trash,
        unitTurnOver: cashFlowData.unitTurnOver,
        water: cashFlowData.water,
        // fetchedPrincipal:cashFlowData.fetchedPrincipal,
      };

      updateNumericFields(cashFlowData, dynamicValues);
    }
  }, [cashFlowData]);
  const fetchBrokerDealers = async () => {
    try {
      const params = {
        headers: { "Content-Type": "application/json" },
      };
      const res = await API.get("dynamoRW", "/1031_broker_Dealer", params);
      if (res.items) {
        const activeBrokers = res.items.filter(
          (broker) =>
            broker.status !== false && broker.name.toLowerCase() === "concorde"
        );
        setIsConcordeBroker(activeBrokers.length > 0);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Error fetching broker dealers! ${error.message}`);
    }
  };

  function updateNumericFields(dataObject, updates) {
    if (dataObject) {
      const updatedCashFlowData = { ...dataObject };
      let hasChanges = false;

      for (const fieldName in updates) {
        if (updates.hasOwnProperty(fieldName)) {
          const newValue = updates[fieldName];
          const cleanedValue = newValue.toString().replace(/[^0-9.]/g, "");

          if (dataObject[fieldName] !== cleanedValue) {
            updatedCashFlowData[fieldName] = cleanedValue;
            hasChanges = true;
          }
        }
      }

      if (hasChanges) {
        setCashFlowData(updatedCashFlowData);
      }
    } else {
      console.warn("Invalid data structure.");
    }
  }

  // const annualizedVacc =
  //   ((+cashFlowData?.eachUnitVacany / 52.14) *
  //     ((+cashFlowData?.grossRent * 12 + +cashFlowData?.reimbursements * 12) /
  //       +cashFlowData?.rentableUnits) *
  //     (+cashFlowData?.unitTurnOver / 10) +
  //     +cashFlowData?.tenants * (+cashFlowData?.unitTurnOver / 10)) *
  //   +cashFlowData?.rentableUnits;

  const annualizedVacc =
    (((+cashFlowData?.eachUnitVacany / 52.14) *
      ((+cashFlowData?.grossRent + +cashFlowData?.reimbursements) * 12) +
      +cashFlowData?.tenants) *
      +cashFlowData?.unitTurnOver *
      +cashFlowData?.rentableUnits) /
    10;

  const monthlyExpensesSum =
    +removeCommas(cashFlowData?.mortgagepayment) +
    +removeCommas(cashFlowData?.propertyManagement) +
    +removeCommas(cashFlowData?.water) +
    +removeCommas(cashFlowData?.trash) +
    +removeCommas(cashFlowData?.landscape) +
    +removeCommas(cashFlowData?.facilityMaintenance) +
    +removeCommas(cashFlowData?.security) +
    +removeCommas(cashFlowData?.reimbursedUtility) +
    +removeCommas(cashFlowData?.hoaFee) +
    +removeCommas(cashFlowData?.associationDues) +
    +removeCommas(cashFlowData?.majorRepairs) +
    +removeCommas(cashFlowData?.OtherMisc) +
    +removeCommas(cashFlowData?.personalhoursSpent) * 50;

  function removeCommas(value) {
    if (typeof value === "string") {
      return value.replace(/[^0-9.]/g, "");
    }
    return value;
  }

  const yearlyExpensesSum =
    (+removeCommas(cashFlowData?.mortgagepayment) +
      +removeCommas(cashFlowData?.propertyManagement) +
      +removeCommas(cashFlowData?.water) +
      +removeCommas(cashFlowData?.trash) +
      +removeCommas(cashFlowData?.landscape) +
      +removeCommas(cashFlowData?.facilityMaintenance) +
      +removeCommas(cashFlowData?.security) +
      +removeCommas(cashFlowData?.reimbursedUtility) +
      +removeCommas(cashFlowData?.hoaFee) +
      +removeCommas(cashFlowData?.associationDues) +
      +removeCommas(cashFlowData?.majorRepairs) +
      +removeCommas(cashFlowData?.OtherMisc) +
      +removeCommas(cashFlowData?.personalhoursSpent) * 50) *
    12;

  const annExpMonth =
    +(+removeCommas(cashFlowData?.bookkeeping) / 12) +
    +(+removeCommas(cashFlowData?.marketing) / 12) +
    +(+removeCommas(cashFlowData?.issurance) / 12) +
    +(+removeCommas(propertyTax / 12)) +
    +removeCommas(annualizedVacc / 12) +
    (cashFlowData?.roof !== "" &&
    cashFlowData?.roofYears !== "" &&
    +removeCommas(cashFlowData?.roofYears) !== 0
      ? +(
          +removeCommas(cashFlowData?.roof) /
          +removeCommas(cashFlowData?.roofYears) /
          12
        )
      : 0) +
    (cashFlowData?.plumbing !== "" &&
    cashFlowData?.plumbingYears !== "" &&
    +removeCommas(cashFlowData?.plumbingYears) !== 0
      ? +(
          +removeCommas(cashFlowData?.plumbing) /
          +removeCommas(cashFlowData?.plumbingYears) /
          12
        )
      : 0) +
    (cashFlowData?.hvac !== "" &&
    cashFlowData?.hvacYears !== "" &&
    +removeCommas(cashFlowData?.hvacYears) !== 0
      ? +(
          +removeCommas(cashFlowData?.hvac) /
          +removeCommas(cashFlowData?.hvacYears) /
          12
        )
      : 0) +
    (cashFlowData?.hardscaping !== "" &&
    cashFlowData?.hardscapingYears !== "" &&
    +removeCommas(cashFlowData?.hardscapingYears) !== 0
      ? +(
          +removeCommas(cashFlowData?.hardscaping) /
          +removeCommas(cashFlowData?.hardscapingYears) /
          12
        )
      : 0) +
    (cashFlowData?.structural !== "" &&
    cashFlowData?.structuralYears !== "" &&
    +removeCommas(cashFlowData?.structuralYears) !== 0
      ? +(
          +removeCommas(cashFlowData?.structural) /
          +removeCommas(cashFlowData?.structuralYears) /
          12
        )
      : 0) +
    (cashFlowData?.allOther !== "" &&
    cashFlowData?.otherYears !== "" &&
    +removeCommas(cashFlowData?.otherYears) !== 0
      ? +(
          +removeCommas(cashFlowData?.allOther) /
          +removeCommas(cashFlowData?.otherYears) /
          12
        )
      : 0
    ).toFixed();

  const annExpAnnul = (
    +cashFlowData?.bookkeeping +
    +cashFlowData?.marketing +
    +cashFlowData?.issurance +
    +propertyTax +
    +cashFlowData?.legal +
    +annualizedVacc +
    (cashFlowData?.roof !== "" &&
    cashFlowData?.roofYears !== "" &&
    +removeCommas(cashFlowData?.roofYears) !== 0
      ? +(
          +removeCommas(cashFlowData?.roof) /
          +removeCommas(cashFlowData?.roofYears)
        )
      : 0) +
    (cashFlowData?.plumbing !== "" &&
    cashFlowData?.plumbingYears !== "" &&
    +removeCommas(cashFlowData?.plumbingYears) !== 0
      ? +(
          +removeCommas(cashFlowData?.plumbing) /
          +removeCommas(cashFlowData?.plumbingYears)
        )
      : 0) +
    (cashFlowData?.hvac !== "" &&
    cashFlowData?.hvacYears !== "" &&
    +removeCommas(cashFlowData?.hvacYears) !== 0
      ? +(
          +removeCommas(cashFlowData?.hvac) /
          +removeCommas(cashFlowData?.hvacYears)
        )
      : 0) +
    (cashFlowData?.hardscaping !== "" &&
    cashFlowData?.hardscapingYears !== "" &&
    +removeCommas(cashFlowData?.hardscapingYears) !== 0
      ? +(
          +removeCommas(cashFlowData?.hardscaping) /
          +removeCommas(cashFlowData?.hardscapingYears)
        )
      : 0) +
    (cashFlowData?.structural !== "" &&
    cashFlowData?.structuralYears !== "" &&
    +removeCommas(cashFlowData?.structuralYears) !== 0
      ? +(
          +removeCommas(cashFlowData?.structural) /
          +removeCommas(cashFlowData?.structuralYears)
        )
      : 0) +
    (cashFlowData?.allOther !== "" &&
    cashFlowData?.otherYears !== "" &&
    +removeCommas(cashFlowData?.otherYears) !== 0
      ? +(
          +removeCommas(cashFlowData?.allOther) /
          +removeCommas(cashFlowData?.otherYears)
        )
      : 0)
  ).toFixed();

  const EstYeildOnEquityIfPrincipal =
    ((+cashFlowData?.grossRent * 12 +
      +cashFlowData?.reimbursements * 12 -
      (+yearlyExpensesSum + +annExpAnnul) +
      +cashFlowData?.principal * 12) /
      (cashFlowData?.estimatedGrossValue *
        (1 - cashFlowData?.expectedSale / 100) -
        cashFlowData?.loanBalance)) *
    100;

  const EstYeildOnEquityIfNotPrincipal =
    ((+cashFlowData?.grossRent * 12 +
      +cashFlowData?.reimbursements * 12 -
      (+yearlyExpensesSum + +annExpAnnul) +
      +cashFlowData?.principal) /
      (cashFlowData?.estimatedGrossValue *
        (1 - cashFlowData?.expectedSale / 100) -
        cashFlowData?.loanBalance)) *
    100;
  {
    /* Calculations for graph ----------- */
  }
  const graphMortgage = +cashFlowData?.mortgagepayment * 12;
  const graphproperty = +cashFlowData?.propertyManagement * 12;
  const graphWater = +cashFlowData?.water * 12;
  const graphTrash = +cashFlowData?.trash * 12;
  const graphLasdscape = +cashFlowData?.landscape * 12;
  const graphOtherFacility = +cashFlowData?.facilityMaintenance * 12;
  const graphSecurity = +cashFlowData?.security * 12;
  const graphOtherReimbursed = +cashFlowData?.reimbursedUtility * 12;
  const graphHOA = +cashFlowData?.hoaFee * 12;
  const graphOtherAssoc = +cashFlowData?.associationDues * 12;
  const graphMajorRep = +cashFlowData?.majorRepairs * 12;
  const graphOtherMisc = +cashFlowData?.OtherMisc * 12;
  const graphPersonal = +cashFlowData?.personalhoursSpent * 50 * 12;
  const graphbookkeeping = +(+cashFlowData?.bookkeeping / 12).toFixed();
  const graphMarketing = +(+cashFlowData?.marketing / 12).toFixed();
  const graphInsurance = +(cashFlowData?.issurance / 12).toFixed();
  const graphPropertyTax = +(+propertyTax / 12).toFixed();
  const graphLegal = +(+cashFlowData?.legal / 12).toFixed();
  const graphAnnualVac = +(+annualizedVacc / 12).toFixed();
  const graphTotal =
    graphMortgage +
    graphproperty +
    graphWater +
    graphTrash +
    graphLasdscape +
    graphOtherFacility +
    graphSecurity +
    graphOtherReimbursed +
    graphHOA +
    graphOtherAssoc +
    graphMajorRep +
    graphOtherMisc +
    graphPersonal +
    graphbookkeeping +
    graphMarketing +
    graphInsurance +
    graphPropertyTax +
    graphLegal +
    +graphAnnualVac;

  const percentGraphMortgage = +(
    ((+cashFlowData?.mortgagepayment * 12) / graphTotal) *
    100
  );
  const percentGraphproperty = +(
    ((+cashFlowData?.propertyManagement * 12) / graphTotal) *
    100
  );
  const percentGraphWater = +(((+cashFlowData?.water * 12) / graphTotal) * 100);
  const percentGraphTrash = +(((+cashFlowData?.trash * 12) / graphTotal) * 100);
  const percentGraphLasdscape = +(
    ((+cashFlowData?.landscape * 12) / graphTotal) *
    100
  );
  const percentGraphOtherFacility = +(
    ((+cashFlowData?.facilityMaintenance * 12) / graphTotal) *
    100
  );
  const percentGraphSecurity = +(
    ((+cashFlowData?.security * 12) / graphTotal) *
    100
  );
  const percentGraphOtherReimbursed = +(
    ((+cashFlowData?.reimbursedUtility * 12) / graphTotal) *
    100
  );
  const percentGraphHOA = +(((+cashFlowData?.hoaFee * 12) / graphTotal) * 100);
  const percentGraphOtherAssoc = +(
    ((+cashFlowData?.associationDues * 12) / graphTotal) *
    100
  );
  const percentGraphMajorRep = +(
    ((+cashFlowData?.majorRepairs * 12) / graphTotal) *
    100
  );
  const percentGraphOtherMisc = +(
    ((+cashFlowData?.OtherMisc * 12) / graphTotal) *
    100
  );
  const percentGraphPersonal = +(
    ((+(+cashFlowData?.personalhoursSpent * 50) * 12) / graphTotal) *
    100
  );
  const percentGraphbookkeeping = +(
    (+(+cashFlowData?.bookkeeping / 12) / graphTotal) *
    100
  );
  const percentGraphMarketing = +(
    (+(+cashFlowData?.marketing / 12) / graphTotal) *
    100
  );
  const percentGraphInsurance = +(
    (+(+cashFlowData?.issurance / 12) / graphTotal) *
    100
  );
  const percentGraphPropertyTax = +((+(+propertyTax / 12) / graphTotal) * 100);
  const percentGraphLegal = +((+cashFlowData?.legal / 12 / graphTotal) * 100);
  const percentGraphAnnualVac = +((+annualizedVacc / 12 / graphTotal) * 100);

  {
    /* Calculations for graph ----------- */
  }

  const data = [
    ["Fields", "Values"],
    ["Mortgage payment (P+I only)", percentGraphMortgage],
    ["Property Management", percentGraphproperty],
    ["Water/sewer", percentGraphWater],
    ["Trash", percentGraphTrash],
    ["Landscape/Gardening", percentGraphLasdscape],
    ["Other Facility Maintenance", percentGraphOtherFacility],
    ["Security", percentGraphSecurity],
    ["Other unreimbursed utility", percentGraphOtherReimbursed],
    ["HOA fee", percentGraphHOA],
    ["Other assoc. dues", percentGraphOtherAssoc],
    ["Major Repairs", percentGraphMajorRep],
    ["Other/Misc", percentGraphOtherMisc],
    ["Personal Time (hrs x $50)", percentGraphPersonal],
    ["Bookkeeping", percentGraphbookkeeping],
    ["Marketing", percentGraphMarketing],
    ["Insurance", percentGraphInsurance],
    ["Property Tax", percentGraphPropertyTax],
    ["Legal", percentGraphLegal],
    ["annualized vacancy", percentGraphAnnualVac],
  ];
  const COLORS = [
    "#3366CC",
    "#DC3912",
    "#FF9900",
    "#109618",
    "#990099",
    "#0099C6",
    "#DD4477",
    "#66AA00",
    "#B82E2E",
    "#316395",
    "#02B2AF",
    "#3B48E0",
    "#1FA2D4",
    "#DA00FF",
    "#9001CB",
    "#2E96FF",
    "#EC724C",
    "#643A52",
    "#1D265F",
  ];

  const options = {
    title: "Current Expense Breakdown",
    is3D: true,
    pieSliceText: "percentage",
    backgroundColor: "transparent",
    colors: COLORS,
  };

  const newDate = new Date(cashFlowData?.reportDate);
  const formattedDate = newDate.toLocaleDateString("en-US");

  const getStates = async () => {
    try {
      const payload = {
        queryStringParameters: {
          tableName: "state_info-dev",
          id: "all",
          LastEvaluatedKey: "None",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      setStateList(res);
    } catch (error) {
      console.log(error.message);
      toast(`No State Found! ${error.message}`, { type: "error" });
    }
  };
  return (
    <Fragment>
      <div style={{ overflow: "hidden" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "relative",
          }}
        >
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                style={{ marginRight: "1.3rem", position: "absolute", top: 20 }}
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        </div>
        <div ref={componentRef}>
          <Grid container style={{ margin: "0rem 2rem" }}>
            <Grid item xs={12} sm={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3>PROPERTY CASH FLOW ANALYSIS</h3>
              </div>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <p style={{ margin: "0rem", fontSize: "0.9rem" }}>
                  <i>Prepared For</i> | <b>{cashFlowData?.clientName}</b>
                </p>
                <p style={{ marginTop: "0.2rem", fontSize: "0.9rem" }}>
                  <i>Property</i> | <b>{cashFlowData?.streetAddress}</b>
                </p>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "end" }}>
                <p style={{ marginTop: "0.2rem", fontSize: "0.9rem" }}>
                  <i>Report Date</i> |{" "}
                  <b>
                    {formattedDate === "Invalid Date"
                      ? "N/A"
                      : formattedDate || ""}
                  </b>
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ margin: "0.3rem 2rem" }}>
            <Grid item xs={12} sm={4}>
              <div style={{ padding: `5px 0` }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14, color: "#c18e0d" }}>
                    <div>Property Income*</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      Monthly
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      Annual
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Gross rents</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${Math.round(
                        Number(cashFlowData?.grossRent)
                      ).toLocaleString("en-US")}`}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${Math.round(
                        Number(cashFlowData?.grossRent * 12)
                      ).toLocaleString("en-US")}`}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Other revenue</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(cashFlowData?.reimbursements)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(cashFlowData?.reimbursements * 12)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <br />

                <hr />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "1.5rem",
                  }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Total</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${Math.round(
                        Number(
                          +cashFlowData?.grossRent +
                            +cashFlowData?.reimbursements
                        )
                      ).toLocaleString("en-US")}`}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${Math.round(
                        Number(
                          +cashFlowData?.grossRent * 12 +
                            +cashFlowData?.reimbursements * 12
                        )
                      ).toLocaleString("en-US")}`}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14, color: "#c18e0d" }}>
                    <div>Monthly Expenses*</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      Monthly
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      Annual
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ fontSize: 14, flex: 2 }}>
                    <div>Mortgage payment (P+I only) </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(cashFlowData?.mortgagepayment)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.mortgagepayment * 12)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Property management </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.propertyManagement)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.propertyManagement * 12)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Water/Sewer</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(Number(cashFlowData?.water)).toLocaleString(
                        "en-US"
                      )}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.water * 12)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Trash</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(Number(cashFlowData?.trash)).toLocaleString(
                        "en-US"
                      )}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.trash * 12)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Landscape Gardening</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(cashFlowData?.landscape)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.landscape * 12)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ fontSize: 14, flex: 2 }}>
                    <div>Other facility maintenance</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(cashFlowData?.facilityMaintenance)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.facilityMaintenance * 12)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Security</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(cashFlowData?.security)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.security * 12)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ fontSize: 14, flex: 2 }}>
                    <div>Other unreimbursed utility</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(cashFlowData?.reimbursedUtility)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.reimbursedUtility * 12)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>HOA fee</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(Number(cashFlowData?.hoaFee)).toLocaleString(
                        "en-US"
                      )}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.hoaFee * 12)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Other assoc. dues</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(cashFlowData?.associationDues)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.associationDues * 12)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Major repairs</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(cashFlowData?.majorRepairs)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.majorRepairs * 12)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Other/misc.</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(cashFlowData?.OtherMisc)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.OtherMisc * 12)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ fontSize: 14, flex: 2 }}>
                    <div>Personal time (hrs x $50)</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.personalhoursSpent * 50)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(+cashFlowData?.personalhoursSpent * 50 * 12)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    borderTop: `1px solid black`,
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Total</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${Math.round(
                        Number(+monthlyExpensesSum)
                      ).toLocaleString("en-US")}`}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${Math.round(
                        Number(+yearlyExpensesSum)
                      ).toLocaleString("en-US")}`}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 1, fontSize: 14, color: "#c18e0d" }}>
                  <div>Monthly Income*</div>
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                    {`$ ${Math.round(
                      Number(
                        +cashFlowData?.grossRent + +cashFlowData?.reimbursements
                      )
                    ).toLocaleString("en-US")}`}
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 1, fontSize: 14, color: "#c18e0d" }}>
                  <div>Monthly Expense*</div>
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                    {`$ ${Math.round(
                      Number(+monthlyExpensesSum + +annExpMonth)
                    ).toLocaleString("en-US")}`}
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 1, fontSize: 14, color: "#c18e0d" }}>
                  <div>Monthly Cash Flow*</div>
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                    {`$ ${Math.round(
                      Number(
                        +(
                          +cashFlowData?.grossRent +
                          +cashFlowData?.reimbursements -
                          +(+monthlyExpensesSum + +annExpMonth)
                        )
                      )
                    ).toLocaleString("en-US")}`}
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={14} sm={4} style={{ paddingLeft: 15 }}>
              <div style={{ padding: `5px 0` }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 1, fontSize: 14, color: "#c18e0d" }}>
                    <div>Current Equity*</div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 1, fontSize: 14 }}>
                    <div>Est. gross value (sale price)</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${
                        Number(
                          (+cashFlowData?.estimatedGrossValue).toFixed()
                        ).toLocaleString("en-US") || 0
                      }`}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 1, fontSize: 14 }}>
                    <div>Est. net property value</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${Math.round(
                        Number(
                          +cashFlowData?.estimatedGrossValue *
                            (1 - +cashFlowData?.expectedSale / 100)
                        )
                      ).toLocaleString("en-US")}`}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 1, fontSize: 14 }}>
                    <div>Current loan balance</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${Math.round(
                        Number(cashFlowData?.loanBalance)
                      ).toLocaleString("en-US")}`}
                    </div>
                  </div>
                </div>
                <hr />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 1, fontSize: 14 }}>
                    <div>Total</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${Math.round(
                        Number(
                          cashFlowData?.estimatedGrossValue *
                            (1 - cashFlowData?.expectedSale / 100) -
                            cashFlowData?.loanBalance
                        )
                      ).toLocaleString("en-US")}`}
                    </div>
                  </div>
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14, color: "#c18e0d" }}>
                    <div>Annual Expenses*</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      Monthly
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      Annual
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Bookkeeping/tax prep</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number((+cashFlowData?.bookkeeping / 12).toFixed())
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(cashFlowData?.bookkeeping)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Marketing </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number((+cashFlowData?.marketing / 12).toFixed())
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(cashFlowData?.marketing)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Insurance</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number((+cashFlowData?.issurance / 12).toFixed())
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number(cashFlowData?.issurance)
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Property Tax</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Number((propertyTax / 12).toFixed()).toLocaleString(
                        "en-US"
                      )}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Number(propertyTax).toLocaleString("en-US")}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Legal</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number((+cashFlowData?.legal / 12).toFixed())
                      ).toLocaleString("en-US")}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(Number(cashFlowData?.legal)).toLocaleString(
                        "en-US"
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Annualized vacancy</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(
                        Number((+annualizedVacc / 12).toFixed())
                      ).toLocaleString("en-US") || 0}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {Math.round(Number(annualizedVacc)).toLocaleString(
                        "en-US"
                      ) || 0}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 1, fontSize: 14, color: "#c18e0d" }}>
                    <div>Future Capital Expenditures*</div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Roof</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {cashFlowData?.roof && cashFlowData?.roofYears
                        ? Math.round(
                            Number(
                              +cashFlowData?.roof /
                                +cashFlowData?.roofYears /
                                12
                            )
                          ).toLocaleString("en-US")
                        : 0}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {cashFlowData?.roof && cashFlowData?.roofYears
                        ? Math.round(
                            Number(
                              +cashFlowData?.roof / +cashFlowData?.roofYears
                            )
                          ).toLocaleString("en-US")
                        : 0}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Plumbing</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {cashFlowData?.plumbing !== "" &&
                      cashFlowData?.plumbing > 0 &&
                      cashFlowData?.plumbingYears !== "" &&
                      cashFlowData?.plumbingYears > 0
                        ? Math.round(
                            Number(
                              +cashFlowData?.plumbing /
                                +cashFlowData?.plumbingYears /
                                12
                            )
                          ).toLocaleString("en-US")
                        : 0}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {cashFlowData?.plumbing !== "" &&
                      cashFlowData?.plumbing > 0 &&
                      cashFlowData?.plumbingYears !== "" &&
                      cashFlowData?.plumbingYears > 0
                        ? Math.round(
                            Number(
                              +cashFlowData?.plumbing /
                                +cashFlowData?.plumbingYears
                            )
                          ).toLocaleString("en-US")
                        : 0}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>HVAC</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {cashFlowData?.hvac && cashFlowData?.hvacYears
                        ? Math.round(
                            Number(
                              +cashFlowData?.hvac /
                                +cashFlowData?.hvacYears /
                                12
                            )
                          ).toLocaleString("en-US")
                        : 0}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {cashFlowData?.hvac && cashFlowData?.hvacYears
                        ? Math.round(
                            Number(
                              +cashFlowData?.hvac / +cashFlowData?.hvacYears
                            )
                          ).toLocaleString("en-US")
                        : 0}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Hardscaping</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {cashFlowData?.hardscaping !== "" &&
                      cashFlowData?.hardscaping > 0 &&
                      cashFlowData?.hardscapingYears !== "" &&
                      cashFlowData?.hardscapingYears > 0
                        ? Math.round(
                            Number(
                              +cashFlowData?.hardscaping /
                                +cashFlowData?.hardscapingYears /
                                12
                            )
                          ).toLocaleString("en-US")
                        : 0}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {cashFlowData?.hardscaping !== "" &&
                      cashFlowData?.hardscaping > 0 &&
                      cashFlowData?.hardscapingYears !== "" &&
                      cashFlowData?.hardscapingYears > 0
                        ? Math.round(
                            Number(
                              +cashFlowData?.hardscaping /
                                +cashFlowData?.hardscapingYears
                            )
                          ).toLocaleString("en-US")
                        : 0}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Structural</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {cashFlowData?.structural && cashFlowData?.structuralYears
                        ? Math.round(
                            Number(
                              +cashFlowData?.structural /
                                +cashFlowData?.structuralYears /
                                12
                            )
                          ).toLocaleString("en-US")
                        : 0}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {cashFlowData?.structural && cashFlowData?.structuralYears
                        ? Math.round(
                            Number(
                              +cashFlowData?.structural /
                                +cashFlowData?.structuralYears
                            )
                          ).toLocaleString("en-US")
                        : 0}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>All Other</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {cashFlowData?.allOther !== "" &&
                      cashFlowData?.allOther > 0 &&
                      cashFlowData?.otherYears !== "" &&
                      cashFlowData?.otherYears > 0
                        ? Math.round(
                            Number(
                              +removeCommas(cashFlowData?.allOther) /
                                +removeCommas(cashFlowData?.otherYears) /
                                12
                            )
                          ).toLocaleString("en-US")
                        : 0}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {cashFlowData?.allOther !== "" &&
                      cashFlowData?.allOther > 0 &&
                      cashFlowData?.otherYears !== "" &&
                      cashFlowData?.otherYears > 0
                        ? Math.round(
                            Number(
                              +removeCommas(cashFlowData?.allOther) /
                                +removeCommas(cashFlowData?.otherYears)
                            )
                          ).toLocaleString("en-US")
                        : 0}
                    </div>
                  </div>
                </div>
                {/* sddddddddddddddddddddddddddddddddddddddddddddd */}
                <div
                  style={{
                    display: "flex",
                    borderTop: `1px solid black`,
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flex: 2, fontSize: 14 }}>
                    <div>Total</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${Math.round(Number(annExpMonth)).toLocaleString(
                        "en-US"
                      )}`}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${Math.round(Number(annExpAnnul)).toLocaleString(
                        "en-US"
                      )}`}
                    </div>
                  </div>
                </div>
                <br />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 1, fontSize: 14, color: "#c18e0d" }}>
                    <div>Annualized Income*</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${Math.round(
                        Number(
                          +cashFlowData?.grossRent * 12 +
                            +cashFlowData?.reimbursements * 12
                        )
                      ).toLocaleString("en-US")}`}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 1, fontSize: 14, color: "#c18e0d" }}>
                    <div>Annualized Expense*</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${Math.round(
                        Number(+yearlyExpensesSum + +annExpAnnul)
                      ).toLocaleString("en-US")}`}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 4, fontSize: 14, color: "#c18e0d" }}>
                    <div>
                      Estimated Yield on Equity* -{" "}
                      <span style={{ fontSize: "0.7rem", color: "#111" }}>
                        <i>Adds back principal payments</i>
                      </span>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {cashFlowData?.fetchedPrincipal === false
                        ? `${(EstYeildOnEquityIfPrincipal || 0).toFixed(2)}%`
                        : `${(EstYeildOnEquityIfNotPrincipal || 0).toFixed(
                            2
                          )}%`}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 1, fontSize: 14, color: "#c18e0d" }}>
                    <div>Annualized Cash Flow ($)*</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`$ ${Math.round(
                        Number(
                          +cashFlowData?.grossRent * 12 +
                            +cashFlowData?.reimbursements * 12 -
                            (+yearlyExpensesSum + +annExpAnnul)
                        )
                      ).toLocaleString("en-US")}`}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 1, fontSize: 14, color: "#c18e0d" }}>
                    <div>Annualized Cash Flow (%)*</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, fontSize: 14, textAlign: "right" }}>
                      {`${(
                        ((+cashFlowData?.grossRent * 12 +
                          +cashFlowData?.reimbursements * 12 -
                          (+yearlyExpensesSum + +annExpAnnul)) /
                          (+cashFlowData?.estimatedGrossValue *
                            (1 - +cashFlowData?.expectedSale / 100) -
                            +cashFlowData?.loanBalance)) *
                          100 || 0
                      ).toFixed(2)}%`}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
            </Grid>
            <Grid item xs={12} sm={4} spacing={8}>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <div
                    style={{
                      position: "relative",
                      width: "70%",
                      height: "220px",
                    }}
                  >
                    <Chart
                      chartType="PieChart"
                      data={data}
                      options={{
                        ...options,
                        legend: "none",
                        title: "",
                      }}
                      width={"100%"}
                      height={"100%"}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "5%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <p style={{ margin: 0, fontSize: "0.8rem" }}>
                        <b>Current Expense Breakdown</b>
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={12}>
                  <List style={{ width: "100%", padding: `0rem 1rem` }}>
                    {[
                      {
                        name: `Mortgage payment(P+I only)`,
                        value: `${percentGraphMortgage.toFixed(1)}%`,
                        color: "#3366CC",
                      },
                      {
                        name: `Property management`,
                        value: `${percentGraphproperty.toFixed(1)}%`,
                        color: "#DC3912",
                      },
                      {
                        name: `Water/sewer`,
                        value: `${percentGraphWater.toFixed(1)}%`,
                        color: "#FF9900",
                      },
                      {
                        name: `Trash`,
                        value: `${percentGraphTrash.toFixed(1)}%`,
                        color: "#109618",
                      },
                      {
                        name: `Landscape/gardening to invest`,
                        value: `${percentGraphLasdscape.toFixed(1)}%`,
                        color: "#990099",
                      },
                      {
                        name: `Other facility maintenance`,
                        value: `${percentGraphOtherFacility.toFixed(1)}%`,
                        color: "#0099C6",
                      },
                      {
                        name: `Security`,
                        value: `${percentGraphSecurity.toFixed(1)}%`,
                        color: "#DD4477",
                      },
                      {
                        name: `Other unreimbursed utility`,
                        value: `${percentGraphOtherReimbursed.toFixed(1)}%`,
                        color: "#66AA00",
                      },
                      {
                        name: `HOA fee`,
                        value: `${percentGraphHOA.toFixed(1)}%`,
                        color: "#B82E2E",
                      },
                      {
                        name: `Other assoc. dues`,
                        value: `${percentGraphOtherAssoc.toFixed(1)}%`,
                        color: "#316395",
                      },
                      {
                        name: `Major repairs`,
                        value: `${percentGraphMajorRep.toFixed(1)}%`,
                        color: "#02B2AF",
                      },
                      {
                        name: `Other/misc.`,
                        value: `${percentGraphOtherMisc.toFixed(1)}%`,
                        color: "#3B48E0",
                      },
                      {
                        name: `Personal time`,
                        value: `${percentGraphPersonal.toFixed(1)}%`,
                        color: "#1FA2D4",
                      },
                      {
                        name: `Bookkeeping`,
                        value: `${percentGraphbookkeeping.toFixed(1)}%`,
                        color: "#DA00FF",
                      },
                      {
                        name: `Marketing`,
                        value: `${percentGraphMarketing.toFixed(1)}%`,
                        color: "#9001CB",
                      },
                      {
                        name: `Insurance`,
                        value: `${percentGraphInsurance.toFixed(1)}%`,
                        color: "#2E96FF",
                      },
                      {
                        name: `Property tax`,
                        value: `${percentGraphPropertyTax.toFixed(1)}%`,
                        color: "#EC724C",
                      },
                      {
                        name: `Legal`,
                        value: `${percentGraphLegal.toFixed(1)}%`,
                        color: "#643A52",
                      },
                      {
                        name: `Annualized vacancy`,
                        value: `${percentGraphAnnualVac.toFixed(1)}%`,
                        color: "#1D265F",
                      },
                    ].map((value, index) => (
                      <ListItem
                        key={value}
                        style={{ padding: `0 6px`, margin: "-7.3px 0px" }}
                        disableGutters
                      >
                        <div
                          style={{
                            display: `flex`,
                            alignItems: "center",
                            textAlign: "center",
                            paddingLeft: 40,
                          }}
                        >
                          <Square
                            fontSize="2"
                            style={{
                              backgroundColor: value.color,
                              color: value.color,
                            }}
                          />
                          <ListItemText
                            primaryTypographyProps={{
                              style: {
                                marginLeft: 5,
                                fontSize: 12,
                                textAlign: "center",
                                color: value.color,
                              },
                            }}
                            primary={`${value.name} ${value.value}`}
                          />
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <hr />
          <div style={{ marginTop: "auto", margin: "1rem 2rem" }}>
            <Grid container spacing={2}>
              <Grid item xs={9} className="Grid-Gap">
                <p style={{ fontSize: "0.7rem" }}>
                  <i>
                    <span style={{ fontWeight: "bold" }}>
                      *Based on client-provided estimates
                    </span>{" "}
                    <br />
                    This illustration is for information purposes only and is
                    not a solicitation to buy or cell any security. Such
                    solicitations must be made by prospectus or offering
                    memorandum. <b>{companyName}</b> does not provide tax or
                    legal advice, as such advice can only be provided by a
                    qualified tax or legal professional, who all investors
                    should consult prior to making any investment decision.
                    {isConcordeBroker && (
                      <i>
                        {" "}
                        Securities offered through Concorde Investment Services,
                        LLC (CIS), member FINRA/SIPC. Advisory services offered
                        through Concorde Asset Management, LLC (CAM), an SEC
                        registered investment adviser.
                      </i>
                    )}
                  </i>
                </p>
              </Grid>
              <Grid item xs={3} className="Grid-Gap">
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "2rem",
                  }}
                >
                  <img
                    src="/faviconPNG.png"
                    style={{ height: "30px" }}
                    className="img-invert"
                  />
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div
            style={{ pageBreakInside: "avoid" }}
            className="print-only"
            dangerouslySetInnerHTML={{ __html: domRef }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default AnalysisCashReport;
