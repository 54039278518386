import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { toast } from "react-toastify";

import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from "recharts";
import tagManagerEvents from "../utills/GtmUtils";
import googleTagTriggers from "../utills/google-tag-triggers.json";
function CashOutAnalysis() {
  let navigate = useNavigate();
  const [scenario1, setScenario1] = useState({
    equity: "",
    cashFlow: "",
    growth: "",
    income: "",
    futureValue: "",
    tenYearTotal: "",
  });
  const [scenario2, setScenario2] = useState({
    equity: "",
    cashFlow: "",
    growth: "",
    income: "",
    futureValue: "",
    tenYearTotal: "",
  });

  const [name, setName] = useState("");
  const [rows, setRows] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    tagManagerEvents(googleTagTriggers.cashOutAnalysis);
    const storedState = JSON.parse(sessionStorage.getItem("cashOutSession"));
    if (storedState) {
      setName(storedState.name);
      setScenario1((prevState) => ({
        ...prevState,
        ...storedState.scenario1,
      }));
      setScenario2((prevState) => ({
        ...prevState,
        ...storedState.scenario2,
      }));
    }
  }, []);
  const applyMask = (inputVal) => {
    const cleanInput = inputVal.replace(/,/g, "").replace(/\.(?=.*\.)/g, "");
    const [wholePart, decimalPart] = cleanInput.split(".");
    const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (decimalPart !== undefined) {
      return `${formattedWholePart}.${decimalPart}`;
    } else {
      return formattedWholePart;
    }
  };
  function isScenarioEmpty(scenario) {
    console.log("The scenario is", scenario);
    const { income, futureValue, tenYearTotal, ...rest } = scenario;
    return Object.values(rest).some(
      (value) => value === "" || value === null || value === undefined
    );
  }

  // function calculateFutureValue(equity, cashFlow, growthRate, periods) {
  //   const futureValueEquity = equity * Math.pow(1 + growthRate, periods);
  //   const futureValueCashFlow =
  //     cashFlow * ((Math.pow(1 + growthRate, periods) - 1) / growthRate);
  //   return futureValueEquity + futureValueCashFlow;
  // }
  function calculateFutureValue(equity, cashFlow, growthRate, periods) {
    // Check if cashFlow or growthRate is zero, if so, return future value using only equity
    if (cashFlow === 0 || growthRate === 0) {
      return equity * Math.pow(1 + growthRate, periods);
    }

    // Calculate future value including cashFlow
    const futureValueEquity = equity * Math.pow(1 + growthRate, periods);
    const futureValueCashFlow =
      cashFlow * ((Math.pow(1 + growthRate, periods) - 1) / growthRate);
    return futureValueEquity + futureValueCashFlow;
  }

  const handleCompare = async () => {
    if (isScenarioEmpty(scenario1) || isScenarioEmpty(scenario2)) {
      toast("Please fill in all fields.", { type: "error" });
      return;
    }
    setShow(true);
    //for scenario 1
    const cleanedEquity = scenario1.equity
      .replace(/[^\w\s]|_/g, "")
      .replace(/\s+/g, "");
    const cleanedCashFlow = scenario1.cashFlow.replace(/[^0-9.]/g, "");
    const cleanedGrowthRate = scenario1.growth.replace(/[^0-9.]/g, "");
    const Income = Math.floor(
      (parseFloat(cleanedEquity) * (cleanedCashFlow / 100)) / 12
    );
    const futureValue = Math.floor(
      calculateFutureValue(
        cleanedEquity,
        cleanedCashFlow === 0 ? cleanedCashFlow : cleanedCashFlow / 100,
        cleanedGrowthRate === 0 ? cleanedGrowthRate : cleanedGrowthRate / 100,
        10
      )
    );
    console.log("The future value is", futureValue);
    const tenYearTotal = Math.floor(
      parseFloat(futureValue + cleanedEquity * (cleanedCashFlow / 100) * 10)
    );
    //for scenario 2
    const cleanedEquity2 = scenario2.equity
      .replace(/[^\w\s]|_/g, "")
      .replace(/\s+/g, "");

    const cleanedCashFlow2 = scenario2.cashFlow.replace(/[^0-9.]/g, "");
    const cleanedGrowthRate2 = scenario2.growth.replace(/[^0-9.]/g, "");

    const Income2 = Math.floor(
      (parseFloat(cleanedEquity2) * (cleanedCashFlow2 / 100)) / 12
    );
    const futureValue2 = Math.floor(
      calculateFutureValue(
        cleanedEquity2,
        cleanedCashFlow2 / 100,
        cleanedGrowthRate2 / 100,
        10
      )
    );
    const tenYearTotal2 = Math.floor(
      parseFloat(futureValue2 + cleanedEquity2 * (cleanedCashFlow2 / 100) * 10)
    );
    setScenario1({
      ...scenario1,
      income: Income,
      futureValue: futureValue,
      tenYearTotal: tenYearTotal,
    });
    setScenario2({
      ...scenario2,
      income: Income2,
      futureValue: futureValue2,
      tenYearTotal: tenYearTotal2,
    });
    const table = handleTable(
      cleanedEquity,
      cleanedCashFlow,
      cleanedGrowthRate
    );
    setTableData1(table);
    const table2 = handleTable2(
      cleanedEquity2,
      cleanedCashFlow2,
      cleanedGrowthRate2
    );
    setTableData2(table2);
    // Merge the two tables for charts
    const newArray = [];
    for (let i = 0; i < Math.max(table.length, table2.length); i++) {
      const entry1 = table[i];
      const entry2 = table2[i];
      const mergedEntry = {};
      if (entry1) {
        mergedEntry.scenario1 = entry1.total;
        mergedEntry.year = entry1.year;
      }
      if (entry2) {
        mergedEntry.scenario2 = entry2.total2;
      }
      newArray.push(mergedEntry);
    }
    setRows(newArray);
  };
  const handleTable = (cleanedEquity, cleanedCashFlow, cleanedGrowthRate) => {
    const rows = [];
    let beg = parseInt(cleanedEquity);
    let growth = cleanedGrowthRate / 100;
    let growthbeg = beg * growth;
    let end = Math.round(beg) + Math.round(growthbeg); // Calculate end for the 0 index outside the loop
    let cf = Math.round((cleanedEquity * cleanedCashFlow) / 100);
    for (let index = 0; index < 10; index++) {
      rows.push({
        year: `${index + 1}`,
        beg: beg,
        growth: cleanedGrowthRate,
        end: Math.round(end),
        cf: Math.round(cleanedEquity * (cleanedCashFlow / 100)),
        total: end + cf * (index + 1),
      });
      beg = end; // Update beg for the next iteration
      end = Math.round(beg + beg * (cleanedGrowthRate / 100)); // Calculate end for the next iteration
    }
    return rows;
  };
  const handleTable2 = (cleanedEquity, cleanedCashFlow, cleanedGrowthRate) => {
    const rows = [];
    let beg = parseInt(cleanedEquity);
    let growth = cleanedGrowthRate / 100;
    let growthbeg = beg * growth;
    let end = Math.round(beg) + Math.round(growthbeg); // Calculate end for the 0 index outside the loop
    let cf = Math.round((cleanedEquity * cleanedCashFlow) / 100);
    for (let index = 0; index < 10; index++) {
      rows.push({
        year: `${index + 1}`,
        beg: beg,
        growth: cleanedGrowthRate,
        end: Math.round(end),
        cf: Math.round(cleanedEquity * (cleanedCashFlow / 100)),
        total2: end + cf * (index + 1),
      });
      beg = end; // Update beg for the next iteration
      end = Math.round(beg + beg * (cleanedGrowthRate / 100)); // Calculate end for the next iteration
    }
    return rows;
  };
  const handleGenerateReport = async () => {
    if (!show) {
      toast("First Compare the Scenarios!", { type: "error" });
      return;
    }
    sessionStorage.setItem(
      "cashOutSession",
      JSON.stringify({
        name,
        scenario1,
        scenario2,
      })
    );
    navigate("/cash-out-analysis-report", {
      state: {
        name,
        scenario1,
        scenario2,
        rows,
        tableData1,
        tableData2,
      },
    });
  };
  const handleSessionState = (updatedFormData) => {
    sessionStorage.setItem(
      "cashOutSession",
      JSON.stringify({
        name,
        scenario1,
        scenario2,
      })
    );
  };
  return (
    <>
      <div style={{ margin: "2rem" }}>
        <Grid container spacing={2}>
          <Card
            style={{
              width: "100%",
              minHeight: "85vh",
              marginTop: "1rem",
              marginLeft: "1rem",
              padding: "1rem 0rem",
            }}
          >
            <div>
              <Grid
                style={{ justifyContent: "space-around", alignItems: "center" }}
                container
              >
                <Grid item xs={4} className="Grid-Gap">
                  <div style={{ display: "flex" }}>
                    <TextField
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      size="small"
                      margin="normal"
                      fullWidth
                      name="client"
                      label="Prepared For"
                    />
                  </div>
                </Grid>
                <Grid item xs={4} className="Grid-Gap">
                  <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1, pl: 1.5, pt: 1, alignItems: "center" }}
                  >
                    <img src="/AI-logo-black.png" style={{ height: "30px" }} />
                  </Typography>
                </Grid>
                <Grid item xs={2} className="Grid-Gap">
                  <Button
                    onClick={() => handleGenerateReport()}
                    type="submit"
                    variant="contained"
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      marginLeft: "auto",
                    }}
                  >
                    View Report
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div style={{ margin: "0rem 2rem" }}>
              <div>
                <h5>Scenario #1</h5>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={4} className="Grid-Gap">
                  <TextField
                    value={scenario1.equity.toLocaleString()}
                    onChange={(e) =>
                      // setScenario1({
                      //   ...scenario1,
                      //   equity: applyMask(e.target.value),
                      // })
                      setScenario1((prevData) => {
                        const newFormData = {
                          ...prevData,
                          equity: applyMask(e.target.value),
                        };
                        handleSessionState(newFormData);
                        return newFormData;
                      })
                    }
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Equity"
                    name="equity"
                    // InputProps={{
                    //   style: { color: "white", backgroundColor: "#203864" },
                    // }}
                    // InputLabelProps={{
                    //   style: { color: "white" }, // Specify the color style for the label
                    // }}
                  />
                </Grid>
                <Grid item xs={4} className="Grid-Gap">
                  <TextField
                    value={scenario1.cashFlow}
                    onChange={(e) =>
                      // setScenario1({
                      //   ...scenario1,
                      //   cashFlow: e.target.value,
                      // })
                      setScenario1((prevData) => {
                        const newFormData = {
                          ...prevData,
                          cashFlow: e.target.value,
                        };
                        handleSessionState(newFormData);
                        return newFormData;
                      })
                    }
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Cash Flow"
                  />
                </Grid>
                <Grid item xs={4} className="Grid-Gap">
                  <TextField
                    value={scenario1.growth}
                    onChange={(e) =>
                      // setScenario1({
                      //   ...scenario1,
                      //   growth: e.target.value,
                      // })
                      setScenario1((prevData) => {
                        const newFormData = {
                          ...prevData,
                          growth: e.target.value,
                        };
                        handleSessionState(newFormData);
                        return newFormData;
                      })
                    }
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Growth"
                    name="growth"
                  />
                </Grid>
              </Grid>
            </div>

            <div style={{ margin: "0rem 2rem" }}>
              <div>
                <h5>Scenario #2</h5>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={4} className="Grid-Gap">
                  <TextField
                    value={scenario2.equity.toLocaleString()}
                    onChange={(e) =>
                      // setScenario2({
                      //   ...scenario2,
                      //   equity: applyMask(e.target.value),
                      // })
                      setScenario2((prevData) => {
                        const newFormData = {
                          ...prevData,
                          equity: applyMask(e.target.value),
                        };
                        handleSessionState(newFormData);
                        return newFormData;
                      })
                    }
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Equity"
                    name="equity"
                  />
                </Grid>
                <Grid item xs={4} className="Grid-Gap">
                  <TextField
                    value={scenario2.cashFlow}
                    onChange={(e) =>
                      // setScenario2({
                      //   ...scenario2,
                      //   cashFlow: e.target.value,
                      // })
                      setScenario2((prevData) => {
                        const newFormData = {
                          ...prevData,
                          cashFlow: e.target.value,
                        };
                        handleSessionState(newFormData);
                        return newFormData;
                      })
                    }
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Cash Flow"
                    name="cashflow"
                  />
                </Grid>
                <Grid item xs={4} className="Grid-Gap">
                  <TextField
                    value={scenario2.growth}
                    onChange={(e) =>
                      // setScenario2({
                      //   ...scenario2,
                      //   growth: e.target.value,
                      // })
                      setScenario2((prevData) => {
                        const newFormData = {
                          ...prevData,
                          growth: e.target.value,
                        };
                        handleSessionState(newFormData);
                        return newFormData;
                      })
                    }
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Growth"
                    name="growth"
                  />
                </Grid>
                <div style={{ marginLeft: "auto", display: "flex" }}>
                  <Button onClick={handleCompare} variant="contained">
                    Compare
                  </Button>
                </div>
              </Grid>
            </div>
            {show && (
              <div style={{ margin: "0rem 0rem" }}>
                <Grid>
                  <div
                    style={{
                      margin: "0rem 0rem",
                      marginTop: "4rem",
                      marginRight: "0.5rem",
                      marginLeft: "0.5rem",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <Box
                          style={{
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "center",
                            backgroundColor: "#203864",
                            alignSelf: "center",
                            color: "white",
                            padding: "0.1rem",
                            textAlign: "center",
                          }}
                        >
                          <h5
                            style={{
                              color: "white",
                              alignSelf: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            Scenario #1
                          </h5>
                        </Box>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{
                              border: "1px solid #f3f3f3",
                            }}
                          >
                            <TableBody>
                              <TableRow
                                sx={{
                                  borderBottom: "1px solid #f3f3f3",
                                  backgroundColor: "#e1f2fa",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    borderRight: "1px solid #777",
                                    width: "50%",
                                  }}
                                >
                                  Income
                                </TableCell>
                                <TableCell align="right">
                                  {scenario1.income.toLocaleString()}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  borderBottom: "1px solid #f3f3f3",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    borderRight: "1px solid gray",
                                    width: "50%",
                                  }}
                                >
                                  Future Value
                                </TableCell>
                                <TableCell align="right">
                                  {scenario1.futureValue.toLocaleString()}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  borderBottom: "1px solid #f3f3f3",
                                  backgroundColor: "#e1f2fa",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    borderRight: "1px solid gray",
                                    fontWeight: "bold",
                                    width: "50%",
                                  }}
                                >
                                  10-Yr Total
                                </TableCell>
                                <TableCell
                                  sx={{ fontWeight: "bold" }}
                                  align="right"
                                >
                                  {scenario1.tenYearTotal.toLocaleString()}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <br />
                        <br />
                        <Box
                          alignItems="center"
                          textAlign="center"
                          style={{
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "center",
                            backgroundColor: "#C55A11",
                            alignSelf: "center",
                            color: "white",
                            padding: "0.1rem",
                            textAlign: "center",
                          }}
                        >
                          <h5
                            style={{
                              color: "white",
                              alignSelf: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            Scenario #2
                          </h5>
                        </Box>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{
                              border: "1px solid #f3f3f3",
                            }}
                          >
                            <TableBody>
                              <TableRow
                                sx={{
                                  borderBottom: "1px solid #f3f3f3",
                                  backgroundColor: "#e1f2fa",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    borderRight: "1px solid gray",
                                    width: "50%",
                                  }}
                                >
                                  Income
                                </TableCell>
                                <TableCell align="right">
                                  {scenario2.income.toLocaleString()}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{ borderBottom: "1px solid #f3f3f3" }}
                              >
                                <TableCell
                                  sx={{
                                    borderRight: "1px solid gray",
                                    width: "50%",
                                  }}
                                >
                                  Future Value
                                </TableCell>
                                <TableCell align="right">
                                  {scenario2.futureValue.toLocaleString()}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  borderBottom: "1px solid #f3f3f3",
                                  backgroundColor: "#e1f2fa",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    borderRight: "1px solid gray",
                                    fontWeight: "bold",
                                    width: "50%",
                                  }}
                                >
                                  10-Yr Total
                                </TableCell>
                                <TableCell
                                  sx={{ fontWeight: "bold" }}
                                  align="right"
                                >
                                  {scenario2.tenYearTotal.toLocaleString()}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={5}>
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <LineChart
                            style={{ marginLeft: "0.5rem" }}
                            width={600}
                            height={500}
                            data={rows}
                          >
                            <CartesianGrid strokeDasharray="5 5" />
                            <XAxis dataKey="year" />
                            <YAxis width={150} />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="scenario1"
                              stroke="#203864"
                            />{" "}
                            {/* Assuming 'total1' is the key for Y-axis data in table */}
                            <Line
                              type="monotone"
                              dataKey="scenario2"
                              stroke="#C55A11"
                            />{" "}
                          </LineChart>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </div>
            )}
          </Card>
        </Grid>
      </div>
    </>
  );
}

export default CashOutAnalysis;
