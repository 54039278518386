import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import BarChartIcon from "@mui/icons-material/BarChart";
import WebAssetOutlinedIcon from "@mui/icons-material/WebAssetOutlined";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import BalanceIcon from "@mui/icons-material/Balance";
import EmojiFlagsOutlinedIcon from "@mui/icons-material/EmojiFlagsOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MyContext from "../context/appContext";
import ScienceIcon from "@mui/icons-material/Science";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import MapIcon from "@mui/icons-material/Map";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PeopleIcon from "@mui/icons-material/People";
import Groups2Outlined from "@mui/icons-material/Groups2Outlined";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import VillaIcon from "@mui/icons-material/Villa";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import { VisibilityOutlined } from "@material-ui/icons";

const drawerWidth = 350;
const role = localStorage.getItem("role");

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: role?.toLowerCase() == "investor" ? 0 : drawerWidth,
    backgroundColor: "#212934",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const useStyles = makeStyles({
  sidebarTopIcon: {
    color: "#fff",
  },
  sideBarText: {
    color: "#fff",
  },
  sidebarSubmenuText: {
    color: "#a48d31",
  },
  activeItem: {
    backgroundColor: "black !important",
  },
  listItemButton: {
    display: 'flex !important',
    width: '100%',
  },
  nestedListItemButton: {
    display: 'flex !important',
    padding: '15px !important',
    width: '100% !important',
    // paddingLeft: '28px !important',
  }
});

export default function DrawerComponent({ open, setOpen }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const appContext = useContext(MyContext);
  const location = useLocation();
  const userRole = localStorage.getItem("role");
  const [activeItem, setActiveItem] = useState(location.pathname);

  const [list, setList] = React.useState([
    {
      title: "Home",
      icon: <HomeIcon />,
      page: "/",
    },
    {
      title: "Analyst Tools",
      icon: <InboxIcon />,
      isOpen: false,
      subMenu: [
        { title: "Add New DST", page: "/add-dst", icon: <AddIcon /> },
        {
          title: "Manage DSTs",
          page: "/manage-dst",
          icon: <MapsHomeWorkIcon />,
        },
        // {
        //   title: "View All DSTs",
        //   page: "/viewAllDsts",
        //   icon: <HolidayVillageIcon />,
        // },
      ],
      allowedRoles: ["Advisor", "Admin", "Super Admin"],
    },
    {
      title: "Advisor Tools",
      icon: <PanToolAltIcon />,
      isOpen: false,
      subMenu: [
        {
          title: "Client Questionnaire",
          page: "/analysis",
          icon: <BarChartIcon />,
        },
        {
          title: "ID Letter Exhibit",
          page: "/lettergenerator",
          icon: <DescriptionIcon />,
        },
        {
          title: "Portfolio Illustration",
          page: "/portfolioIllustaion",
          icon: <BarChartIcon />,
        },
        // {
        // .  title: "Quick Calculations",
        //   page: "/quick-calculations",
        //   icon: <ManageHistoryIcon />,
        // },
        { title: "NOI/CAP Rate", page: "/noi-cap-rate", icon: <BalanceIcon /> },
        {
          title: "Sniff Test",
          page: "/sniff-test",
          icon: <ScienceIcon />,
        },
        {
          title: "State",
          page: "/manage-state",
          icon: <MapIcon />,
        },
        {
          title: "Tax Details",
          page: "/tax-details",
          icon: <FileCopyIcon />,
        },
      ],
      allowedRoles: ["Admin", "Super Admin"],
    },
    {
      title: "Agent Tools",
      subMenu: [
        {
          title: "Client Questionnaire",
          page: "/analysis",
          icon: <BarChartIcon />,
        },
        {
          title: "ID Letter Exhibit",
          page: "/lettergenerator",
          icon: <DescriptionIcon />,
        },
        {
          title: "Portfolio Illustration",
          page: "/portfolioIllustaion",
          icon: <BarChartIcon />,
        },
        // {
        // .  title: "Quick Calculations",
        //   page: "/quick-calculations",
        //   icon: <ManageHistoryIcon />,
        // },
        { title: "NOI/CAP Rate", page: "/noi-cap-rate", icon: <BalanceIcon /> },
        {
          title: "Sniff Test",
          page: "/sniff-test",
          icon: <ScienceIcon />,
        },
        {
          title: "State",
          page: "/manage-state",
          icon: <MapIcon />,
        },
        {
          title: "Tax Details",
          page: "/tax-details",
          icon: <FileCopyIcon />,
        },
      ],
      allowedRoles: ["Agent"],
    },
  ]);

  const handleClick = (index) => {
    const newList = [...list];
    newList[index].isOpen = !list[index].isOpen;
    setList(newList);
  };
  const hasAccess = (allowedRoles) => {
    return allowedRoles.includes(userRole);
  };
  function toggleDrawer() {
    setOpen(!open);
  }

  const handleItemClick = (path, specificId = null) => {
    setActiveItem(specificId || path);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: 1,

        }}
        className={classes.sidebarTop}
      >
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1, pl: 1.5, pt: 1 }}
        >
          <img src="/faviconPNG.png" style={{ height: "21px" }} />
        </Typography>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            color: "#fff !important",
          }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List
        component="nav"
        sx={{
          mt: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {hasAccess(["Agent"]) && (
          <List component="div" disablePadding>
            {/* Client Questionnaire */}
            {hasAccess(["Agent"]) && (
              <Link
                to="/analysis"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() =>
                    handleItemClick("/analysis", "cash-flow-analysis")
                  }
                  className={
                    activeItem === "cash-flow-analysis"
                      ? classes.activeItem
                      : ""
                  }
                >
                  <ListItemIcon style={{ color: "#a48d31" }}>
                    <LocalAtmIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Cash Flow Analysis Questionnaire"
                    className={classes.sidebarSubmenuText}
                  />
                </ListItemButton>
              </Link>
            )}

            {hasAccess(["Agent"]) && (
              <Link
                to="/analysis"
                state={{ customProp: "taxIllustrator" }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() =>
                    handleItemClick("/analysis", "tax-estimator")
                  }
                  className={
                    activeItem === "tax-estimator" ? classes.activeItem : ""
                  }
                >
                  <ListItemIcon style={{ color: "#a48d31" }}>
                    <AccountBalanceIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Tax Estimator Questionnaire"
                    className={classes.sidebarSubmenuText}
                  />
                </ListItemButton>
              </Link>
            )}
            {hasAccess(["Agent"]) && (
              <Link
                to="/cash-out-analysis"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleItemClick("/cash-out-analysis")}
                  className={
                    activeItem === "/cash-out-analysis"
                      ? classes.activeItem
                      : ""
                  }
                >
                  <ListItemIcon style={{ color: "#a48d31" }}>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Cash Out Analysis"
                    className={classes.sidebarSubmenuText}
                  />
                </ListItemButton>
              </Link>
            )}



            {hasAccess(["Agent"]) && (
              <Link
                to="/noi-cap-rate"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleItemClick("/noi-cap-rate")}
                  className={
                    activeItem === "/noi-cap-rate" ? classes.activeItem : ""
                  }
                >
                  <ListItemIcon style={{ color: "#a48d31" }}>
                    <BalanceIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="NOI/CAP Rate"
                    className={classes.sidebarSubmenuText}
                  />
                </ListItemButton>
              </Link>
            )}
            {hasAccess(["Agent"]) && (
              <Link
                to="/tax-equivalent-yield-calculator"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() =>
                    handleItemClick("/tax-equivalent-yield-calculator")
                  }
                  className={
                    activeItem === "/tax-equivalent-yield-calculator"
                      ? classes.activeItem
                      : ""
                  }
                >
                  <ListItemIcon style={{ color: "#a48d31" }}>
                    <CalculateOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Tax-Equivalent Yield Calculator"
                    className={classes.sidebarSubmenuText}
                  />
                </ListItemButton>
              </Link>
            )}



          </List>
        )}
        {/* Home */}
        {hasAccess(["Investor"]) && (
          <Link
            to="/dst-list"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ListItemButton
            // onClick={() => handleItemClick("/dst-list")}
            // className={activeItem === "/dst-list" ? classes.activeItem : ""}
            >
              <ListItemIcon style={{ color: "#fff" }}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Property Page"
                className={classes.sideBarText}
              />
            </ListItemButton>
          </Link>
        )}
        {/* Home */}
        {hasAccess(["Admin", "Super Admin", "Advisor"]) && (
          <Link to="/" style={{ textDecoration: "none", color: "black", width: "100%" }}>
            <ListItemButton
              // onClick={() => handleItemClick("/")}
              onClick={() => handleItemClick("/")}
              className={`${classes.nestedListItemButton} ${activeItem === "/" ? classes.activeItem : ""
                }`}
            >
              <ListItemIcon style={{ color: "#fff" }}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" className={classes.sideBarText} />
            </ListItemButton>
          </Link>
        )}
        {/* Analyst Tools */}
        {hasAccess(["Admin", "Super Admin"]) && (
          <React.Fragment style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <ListItemButton onClick={() => handleClick(1)} className={classes.listItemButton}>
              <ListItemIcon style={{ color: "#fff" }}>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText
                primary="Analyst Tools"
                className={classes.sideBarText}
              />
              {list[1].subMenu && list[1].isOpen ? (
                <ExpandLess className={classes.sideBarText} />
              ) : list[1].subMenu && list[1].isOpen === false ? (
                <ExpandMore className={classes.sideBarText} />
              ) : null}
            </ListItemButton>
            <Collapse in={list[1].isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* Add New DST */}
                {hasAccess(["Admin", "Super Admin"]) && (
                  <Link
                    to="/add-dst"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleItemClick("/add-dst")}
                      className={`${classes.nestedListItemButton} ${activeItem === "/add-dst" ? classes.activeItem : ""
                        }`}
                    >
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Add New DST"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )}
                {/* Manage DSTs */}
                {hasAccess(["Admin", "Super Admin"]) && (
                  <Link
                    to="/manage-dst"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleItemClick("/manage-dst")}
                      className={`${classes.nestedListItemButton} ${activeItem === "/manage-dst" ? classes.activeItem : ""
                        }`}
                    >
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <MapsHomeWorkIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Manage DSTs"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )}
                {/* View All DSTs */}
                {/* {hasAccess(["Admin", "Super Admin"]) && (
                  <Link
                    to="/viewAllDsts"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <HolidayVillageIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="View All DSTs"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )} */}
              </List>
              {/* State */}
              {hasAccess(["Admin", "Super Admin"]) && (
                <Link
                  to="/manage-state"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleItemClick("/manage-state")}
                    className={`${classes.nestedListItemButton} ${activeItem === "/manage-state" ? classes.activeItem : ""
                      }`}
                  >
                    <ListItemIcon style={{ color: "#a48d31" }}>
                      <MapIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="State"
                      className={classes.sidebarSubmenuText}
                    />
                  </ListItemButton>
                </Link>
              )}
              {/* Tax Details */}
              {hasAccess(["Admin", "Super Admin"]) && (
                <Link
                  to="/tax-details"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleItemClick("/tax-details")}
                    className={`${classes.nestedListItemButton} ${activeItem === "/tax-details" ? classes.activeItem : ""
                      }`}
                  >
                    <ListItemIcon style={{ color: "#a48d31" }}>
                      <FileCopyIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Tax Details"
                      className={classes.sidebarSubmenuText}
                    />
                  </ListItemButton>
                </Link>
              )}
              {hasAccess(["Super Admin"]) && (
                <Link
                  to="/manage-accounts"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleItemClick("/manage-accounts")}
                    className={`${classes.nestedListItemButton} ${activeItem === "/manage-accounts"
                      ? classes.activeItem
                      : ""
                      }`}
                  >
                    <ListItemIcon style={{ color: "#a48d31" }}>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Manage Users"
                      className={classes.sidebarSubmenuText}
                    />
                  </ListItemButton>
                </Link>
              )}
              {/* Manage Broker Dealers */}
              {hasAccess(["Super Admin"]) && (
                <Link
                  to="/manage-broker-dealers"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleItemClick("/manage-broker-dealers")}
                    className={`${classes.nestedListItemButton} ${activeItem === "/manage-broker-dealers"
                      ? classes.activeItem
                      : ""
                      }`}
                  >
                    <ListItemIcon style={{ color: "#a48d31" }}>
                      <Groups2Outlined />
                    </ListItemIcon>
                    <ListItemText
                      primary="Manage Broker Dealers"
                      className={classes.sidebarSubmenuText}
                    />
                  </ListItemButton>
                </Link>
              )}
              {hasAccess(["Super Admin"]) && (
                <Link
                  to="/visibility-settings"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleItemClick("/visibility-settings")}
                    className={`${classes.nestedListItemButton} ${activeItem === "/visibility-settings"
                      ? classes.activeItem
                      : ""
                      }`}
                  >
                    <ListItemIcon style={{ color: "#a48d31" }}>
                      <VisibilityOutlined />
                    </ListItemIcon>
                    <ListItemText
                      primary="Visibility Settings"
                      className={classes.sidebarSubmenuText}
                    />
                  </ListItemButton>
                </Link>
              )}
            </Collapse>
          </React.Fragment>
        )}

        {/* Advisor Tools (only for Admin) */}
        {hasAccess(["Advisor", "Admin", "Super Admin"]) && (
          <React.Fragment>
            <ListItemButton onClick={() => handleClick(2)} className={classes.listItemButton}>
              <ListItemIcon style={{ color: "#fff" }}>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText
                primary="Advisor Tools"
                className={classes.sideBarText}
              />
              {list[2].subMenu && list[2].isOpen ? (
                <ExpandLess className={classes.sideBarText} />
              ) : list[2].subMenu && list[2].isOpen === false ? (
                <ExpandMore className={classes.sideBarText} />
              ) : null}
            </ListItemButton>
            <Collapse in={list[2].isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* Client Questionnaire */}
                {hasAccess(["Advisor", "Admin", "Super Admin"]) && (
                  <Link
                    to="/analysis"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() =>
                        handleItemClick("/analysis", "cash-flow-analysis")
                      }
                      className={`${classes.nestedListItemButton} ${activeItem === "cash-flow-analysis"
                        ? classes.activeItem
                        : ""
                        }`}
                    >
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <LocalAtmIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Cash Flow Analysis Questionnaire"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )}

                {hasAccess(["Advisor", "Admin", "Super Admin"]) && (
                  <Link
                    to="/analysis"
                    state={{ customProp: "taxIllustrator" }}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() =>
                        handleItemClick("/analysis", "tax-estimator")
                      }
                      className={`${classes.nestedListItemButton} ${activeItem === "tax-estimator" ? classes.activeItem : ""
                        }`}
                    >
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <AccountBalanceIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Tax Estimator Questionnaire"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )}
                {hasAccess(["Advisor", "Admin", "Super Admin"]) && (
                  <Link
                    to="/cash-out-analysis"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleItemClick("/cash-out-analysis")}
                      className={`${classes.nestedListItemButton} ${activeItem === "/cash-out-analysis"
                        ? classes.activeItem
                        : ""
                        }`}
                    >
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <AttachMoneyIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Cash Out Analysis"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )}
                {/* Sniff Test */}
                {hasAccess(["Advisor", "Admin", "Super Admin"]) && (
                  <Link
                    to="/sniff-test"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleItemClick("/sniff-test")}
                      className={`${classes.nestedListItemButton} ${activeItem === "/sniff-test" ? classes.activeItem : ""
                        }`}
                    >
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <ScienceIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="DST Data Grid"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )}

                {/* Offering Summary */}

                {hasAccess(["Advisor", "Admin", "Super Admin"]) && (
                  <Link
                    to={{
                      pathname: "/offering-summary-library",
                    }}
                    state={{ customProp: "Offering_Summary" }}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() =>
                        handleItemClick("/offering-summary-library")
                      }
                      className={`${classes.nestedListItemButton} ${activeItem === "/offering-summary-library"
                        ? classes.activeItem
                        : ""
                        }`}
                    >
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <WebAssetOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Offering Summary"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )}

                {/* Offering Summary Library */}

                {/* {hasAccess(["Advisor", "Admin"]) && (
                  <Link
                    to={{
                      pathname: "/offering-summary-library",
                    }}
                    state={{ customProp: "Offering_Summary" }}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <WebAssetOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Offering Summary Library"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )} */}

                {/* Portfolio Illustration */}
                {hasAccess(["Advisor", "Admin", "Super Admin"]) && (
                  <Link
                    to="/portfolio-illustration"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleItemClick("/portfolio-illustration")}
                      className={`${classes.nestedListItemButton} ${activeItem === "/portfolio-illustration"
                        ? classes.activeItem
                        : ""
                        }`}
                    >
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <BarChartIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Portfolio Illustration"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )}
                {/* Property Page */}

                {hasAccess(["Advisor", "Admin", "Super Admin"]) && (
                  <Link
                    to={{
                      pathname: "/dst-list",
                    }}
                    state={{ customProp: "PropertyPage" }}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleItemClick("/dst-list")}
                      className={`${classes.nestedListItemButton} ${activeItem === "/dst-list" ? classes.activeItem : ""
                        }`}
                    >
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <VillaIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Properties Page"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )}
                {hasAccess(["Advisor", "Admin", "Super Admin"]) && (
                  <Link
                    to="/noi-cap-rate"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleItemClick("/noi-cap-rate")}
                      className={`${classes.nestedListItemButton} ${activeItem === "/noi-cap-rate" ? classes.activeItem : ""
                        }`}
                    >
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <BalanceIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="NOI/Cap Rate"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )}
                {hasAccess(["Advisor", "Admin", "Super Admin"]) && (
                  <Link
                    to="/tax-equivalent-yield-calculator"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() =>
                        handleItemClick("/tax-equivalent-yield-calculator")
                      }
                      className={`${classes.nestedListItemButton} ${activeItem === "/tax-equivalent-yield-calculator"
                        ? classes.activeItem
                        : ""
                        }`}
                    >
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <CalculateOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Tax-Equivalent Yield Calculator"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )}
                {/* ID Letter Exhibit */}
                {hasAccess(["Advisor", "Admin", "Super Admin"]) && (
                  <Link
                    to="/lettergenerator"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleItemClick("/lettergenerator")}
                      className={`${classes.nestedListItemButton} ${activeItem === "/lettergenerator"
                        ? classes.activeItem
                        : ""
                        }`}
                    >
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <DescriptionIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="ID Letter Exhibit"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )}

                {hasAccess(["Advisor"]) && (
                  <Link
                    to="/manage-accounts"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleItemClick("/manage-accounts")}
                      className={`${classes.nestedListItemButton} ${activeItem === "/manage-accounts"
                        ? classes.activeItem
                        : ""
                        }`}
                    >
                      <ListItemIcon style={{ color: "#a48d31" }}>
                        <SupervisedUserCircleIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Manage Investors"
                        className={classes.sidebarSubmenuText}
                      />
                    </ListItemButton>
                  </Link>
                )}
              </List>
            </Collapse>
          </React.Fragment>
        )}
      </List>
    </Drawer>
  );
}
