import Login from "../screens/login";
import Home from "../screens/home";
import ManageDST from "../screens/manageDST";
import ADDDST from "../screens/addDSTs";
import ManageDstDetails from "../screens/DST-details";
import ManageProperties from "../screens/manageProperties";
import SignUp from "../screens/signUp";
import Protected from "./protectedRoutes";
import VerficationCode from "../screens/verificationCode";
import PortfolioIllustration from "../screens/PortfolioIllustration";
import ViewAllDsts from "../screens/viewAllDSTs";
import Analysis from "../screens/analysis";
import NoiCapRate from "../screens/NoiCapRate";
import LetterGenerator from "../components/LetterGenerator";
import DSTPrintView from "../screens/DST-PrintView";
import AnalysisReport from "../screens/analysis-tax-report";
import AnalysisTaxReport from "../screens/analysis-tax-report";
import AnalysisCashReport from "../screens/analysis-cash-report";
import ManageState from "../screens/manageState";
import TaxDetails from "../screens/tax-details";
import IdLetterReport from "../components/printScreens/idLetterPrint";
import IllustrationReport from "../components/printScreens/illustrationPrint";
import NoiCapRatePrint from "../components/printScreens/noiCapPrint";
import QuickCalculations from "../screens/QuickCalculations";
import QuickCalculationReport from "../components/printScreens/quickCalculationPrint";
import SniffTest from "../screens/sniffTest";
import SniffReport from "../components/printScreens/sniffPrint";
import PropertiesPage from "../screens/PropertiesPage";
import OfferingLibrary from "../screens/offeringLibrary";
import LoanCalculator from "../screens/loanCalculator";
import Profile from "../screens/profile";
import ResetPassword from "../screens/resetPassword";
import PasswordRequest from "../screens/passwordRequest";
import ManageUsers from "../screens/manageUsers";
import ManageRole from "../screens/ManageRole";
import InvestorSignup from "../screens/investor_signup";
import AgentSignup from "../screens/AgentSignUp";
import ViewPropertiesPdf from "../components/viewPropertiesPdf";
import CashOutAnalysis from "../screens/cashOutAnalysis";
import CashOutAnalysisReport from "../components/printScreens/cashOutAnalysis";
import TaxYieldCalculator from "../screens/tax-yield-calculator";
import EquityCalcultorPrint from "../components/printScreens/taxEquityCalculatorPrint";
import LandingPageScreen from "../screens/landingPageScreen";
import ManageBrokerDealers from "../screens/ManageBrokerDealers";
import VisibilitySettings from "../screens/VisibilitySettings";
import DstLIst from "../components/dstLIst";
import Dashboard from "../screens/Dashboard";
export const RoutingList = [
  {
    route: "/home",
    element: <LandingPageScreen />,
    is_protected: false,
  },
  {
    route: "/login",
    element: <Login />,
    is_protected: false,
  },
  {
    route: "/",
    element: <Dashboard />,
    is_protected: true,
    allowedRoles: ["Advisor", "Admin", "Super Admin", "Agent"],
  },
  {
    route: "/profile",
    element: <Profile />,
    is_protected: true,
    allowedRoles: ["Advisor", "Admin", "Super Admin"],
  },
  {
    route: "/reset-password",
    element: <ResetPassword />,
    is_protected: false,
  },
  {
    route: "/password-request",
    element: <PasswordRequest />,
    is_protected: false,
  },
  {
    route: "/add-dst/:id?",
    element: <ADDDST />,
    is_protected: true,
    allowedRoles: ["Admin", "Super Admin"],
  },
  {
    route: "/manage-dst/:id",
    element: <ManageDstDetails />,
    is_protected: true,
    allowedRoles: ["Admin", "Super Admin"],
  },
  {
    route: "/manage-dst",
    element: <ManageDST />,
    is_protected: true,
    allowedRoles: ["Admin", "Super Admin"],
  },
  {
    route: "/dst-data/:id",
    element: <PropertiesPage />,
    is_protected: true,
    allowedRoles: ["Advisor", "Admin", "Super Admin"],
  },
  {
    route: "/view-properties-pdf/:id",
    element: <ViewPropertiesPdf />,
    is_protected: true,
    allowedRoles: ["Advisor", "Admin", "Super Admin", "Investor"],
    children: [
      {
        route: "/dst-data/:id",
        element: <PropertiesPage />,
        is_protected: true,
        allowedRoles: ["Advisor", "Admin", "Super Admin", "Investor"],
      },
    ],
  },
  {
    route: "/dst-list",
    element: <DstLIst />,
    is_protected: true,
    allowedRoles: ["Advisor", "Admin", "Super Admin", "Investor"],
    children: [
      {
        route: "/dst-data/:id",
        element: <PropertiesPage />,
        is_protected: true,
        allowedRoles: ["Advisor", "Admin", "Super Admin", "Investor"],
      },
    ],
  },
  {
    route: "/manageProperties",
    element: <ManageProperties />,
    is_protected: true,
    allowedRoles: ["Admin", "Super Admin"],
  },
  {
    route: "/signUp",
    element: <SignUp />,
    is_protected: false,
  },
  {
    route: "/investor_signup",
    element: <InvestorSignup />,
    is_protected: false,
  },
  {
    route: "/agent_signup",
    element: <AgentSignup />,
    is_protected: false,
  },
  {
    route: "/verifyCode",
    element: <VerficationCode />,
    is_protected: false,
  },
  {
    route: "/portfolio-illustration",
    element: <PortfolioIllustration />,
    allowedRoles: ["Advisor", "Admin", "Super Admin"],
    is_protected: true,
  },
  // {
  //   route: "/viewAllDsts",
  //   element: <ViewAllDsts />,
  //   is_protected: true,
  //   allowedRoles: ["Admin", "Super Admin"],
  // },
  {
    route: "/analysis",
    element: <Analysis />,
    allowedRoles: ["Advisor", "Admin", "Super Admin", "Agent"],
    is_protected: true,
  },
  {
    route: "/noi-cap-rate",
    element: <NoiCapRate />,
    is_protected: true,
    allowedRoles: ["Advisor", "Admin", "Super Admin", "Agent"],
  },
  {
    route: "/tax-equivalent-yield-calculator",
    element: <TaxYieldCalculator />,
    is_protected: true,
    allowedRoles: ["Advisor", "Admin", "Super Admin", "Agent"],
  },
  {
    route: "/lettergenerator",
    allowedRoles: ["Advisor", "Admin", "Super Admin"],
    element: <LetterGenerator />,
    is_protected: true,
  },
  {
    route: "/tax-report",
    element: <AnalysisTaxReport />,
    allowedRoles: ["Advisor", "Admin", "Super Admin", "Agent"],
    is_protected: true,
  },
  {
    route: "/print-dst/:id?",
    element: <DSTPrintView />,
    is_protected: true,
    allowedRoles: ["Advisor", "Admin", "Super Admin"],
  },
  {
    route: "/cashflow-report",
    element: <AnalysisCashReport />,
    is_protected: true,
    allowedRoles: ["Advisor", "Admin", "Super Admin", "Agent"],
  },
  {
    route: "/manage-state",
    element: <ManageState />,
    is_protected: true,
    allowedRoles: ["Admin", "Super Admin"],
  },
  {
    route: "/tax-details",
    element: <TaxDetails />,
    is_protected: true,
    allowedRoles: ["Admin", "Super Admin"],
  },
  {
    route: "/id-letter-report",
    element: <IdLetterReport />,
    allowedRoles: ["Advisor", "Admin", "Super Admin"],
    is_protected: true,
  },
  {
    route: "/portfolio-illustration-report",
    element: <IllustrationReport />,
    allowedRoles: ["Advisor", "Admin", "Super Admin"],
    is_protected: true,
  },
  {
    route: "/tax-equivalent-yield-calculator-print-view",
    element: <EquityCalcultorPrint />,
    allowedRoles: ["Advisor", "Admin", "Super Admin", "Agent"],
    is_protected: true,
  },
  {
    route: "/cash-out-analysis-report",
    element: <CashOutAnalysisReport />,
    allowedRoles: ["Advisor", "Admin", "Super Admin", "Agent"],
    is_protected: true,
  },
  {
    route: "/noi-cap-rate-report",
    element: <NoiCapRatePrint />,
    allowedRoles: ["Advisor", "Admin", "Super Admin"],
    is_protected: true,
  },
  // {
  //   route: "/quick-calculations",
  //   element: <QuickCalculations />,
  //   is_protected: true,
  //   allowedRoles: ["Advisor","Admin"],
  // },
  // {
  //   route: "/quick-calculations-report",
  //   element: <QuickCalculationReport />,
  //   is_protected: true,
  //   allowedRoles: ["Advisor", "Admin"],
  // },
  {
    route: "/sniff-test",
    element: <SniffTest />,
    is_protected: true,
    allowedRoles: ["Advisor", "Admin", "Super Admin"],
  },
  {
    route: "/sniff-test-report",
    element: <SniffReport />,
    is_protected: true,
    allowedRoles: ["Advisor", "Admin", "Super Admin"],
  },
  {
    route: "/offering-summary-library",
    element: <OfferingLibrary />,
    is_protected: true,
    allowedRoles: ["Advisor", "Admin", "Super Admin"],
  },
  {
    route: "/loan-calculation",
    element: <LoanCalculator />,
    is_protected: true,
    allowedRoles: ["Advisor", "Admin", "Super Admin"],
  },
  {
    route: "/approve-user",
    element: <ManageUsers />,
    is_protected: true,
    allowedRoles: ["Super Admin", "Advisor"],
  },
  {
    route: "/manage-accounts",
    element: <ManageRole />,
    is_protected: true,
    allowedRoles: ["Super Admin", "Advisor"],
  },
  {
    route: "/manage-broker-dealers",
    element: <ManageBrokerDealers />,
    is_protected: true,
    allowedRoles: ["Super Admin"],
  },
  {
    route: "/visibility-settings",
    element: <VisibilitySettings />,
    is_protected: true,
    allowedRoles: ["Super Admin"],
  },
  {
    route: "/cash-out-analysis",
    element: <CashOutAnalysis />,
    is_protected: true,
    allowedRoles: ["Advisor", "Admin", "Super Admin", "Agent"],
  },
];
