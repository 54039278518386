import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Input, TextField, Typography } from '@mui/material';
import React from 'react'

const SupportModal = ({ isSupportModalOpen, email, setEmail, handleCloseSupportModal, handleSubmit, message, setMessage }) => {
    return (
        <div >
            <Dialog maxWidth="md" fullWidth open={isSupportModalOpen} onClose={handleCloseSupportModal}>
                <DialogTitle>Contact Support</DialogTitle>
                <DialogContent >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField fullWidth
                            type="email"
                            placeholder="Your Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            style={{ padding: '10px', borderRadius: '4px' }}
                        />
                        <TextField
                            multiline
                            rows={5}
                            fullWidth
                            placeholder="Your Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                            style={{ padding: '10px', borderRadius: '4px' }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSupportModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} type="submit" color="primary">
                        Send Message
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default SupportModal
