import React, { useContext, useEffect, useState } from "react";
import MyContext from "../context/appContext";
import { Link } from "react-router-dom";
import "../css/styles.css";
import { toast } from "react-toastify";
import { API } from "aws-amplify";
import Box from "@mui/material/Box";
import { InputAdornment, TextField, tableCellClasses } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

let userEmail = localStorage.getItem("userEmail");

const ManageDST = () => {
  const appContext = useContext(MyContext);
  const [dsts, setDsts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [userAssignedDsts, setUserAssignedDsts] = useState([]);

  const handleSearch = (event) => {
    const query = event.target.value;
    console.log(query);
    setSearchText(query);
    const filteredRows = dsts.filter((dst) => {
      const dstName = dst?.basicInfo?.fullLegalNameOfDst || "";
      return dstName.toLowerCase().includes(query.toLowerCase());
    });
    setRows(
      filteredRows.map((dst) => ({
        draft: dst?.is_draft ? "Draft" : null,
        name: dst?.basicInfo?.fullLegalNameOfDst,
        sponser: dst?.basicInfo?.nameOfTheSponsor,
        id: dst.id,
      }))
    );
  };

  useEffect(() => {
    // fetchUserAssignments();
    getIdData();
  }, []);

  useEffect(() => {
    const filteredRows = dsts.filter((dst) => {
      const dstName = dst?.basicInfo?.fullLegalNameOfDst || "";
      return dstName.toLowerCase().includes(searchText.toLowerCase());
    });
    setRows(
      filteredRows.map((dst) => ({
        draft: dst?.is_draft ? "Draft" : null,
        name: dst?.basicInfo?.fullLegalNameOfDst,
        sponser: dst?.basicInfo?.nameOfTheSponsor,
        id: dst.id,
      }))
    );
  }, [dsts, searchText]);

  const fetchUserAssignments = async () => {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (!userEmail) return;

      const res = await API.get(
        "dynamoRW",
        `/notifications?tablename=approvedUsers-dev`
      );

      const currentUser = res.Items.find(
        (user) => user.userEmail === userEmail
      );
      if (currentUser?.assignedDsts) {
        setUserAssignedDsts(currentUser.assignedDsts);
      }
    } catch (error) {
      console.error("Error fetching user assignments:", error);
      toast.error(`Error fetching user details! ${error.message}`);
    }
  };

  const getIdData = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: "id-name",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);

      // Get user role and check assignments
      const userRole = localStorage.getItem("userRole");
      const userEmail = localStorage.getItem("userEmail");

      // Fetch user's assigned DSTs
      const userRes = await API.get(
        "dynamoRW",
        `/notifications?tablename=approvedUsers-dev`
      );

      const currentUser = userRes.Items.find(
        (user) => user.userEmail === userEmail
      );

      // Get all DSTs assigned to any of user's broker dealers
      let allAssignedDsts = [];
      if (currentUser?.brokerDealerIds?.length > 0) {
        // Get all users that share any of the current user's broker dealers
        const relatedUsers = userRes.Items.filter((user) =>
          user.brokerDealerIds?.some((id) =>
            currentUser.brokerDealerIds.includes(id)
          )
        );

        // Combine all assigned DSTs from related users
        allAssignedDsts = [
          ...new Set(relatedUsers.flatMap((user) => user.assignedDsts || [])),
        ];
      }

      let filteredDsts = res;

      // Show filtered DSTs only if:
      // 1. User is not Super Admin
      // 2. User has broker dealers with assigned DSTs
      if (userRole !== "Super Admin" && allAssignedDsts.length > 0) {
        filteredDsts = res.filter((dst) => allAssignedDsts.includes(dst.id));
      }
      // If no DSTs are assigned or user is Super Admin, show all DSTs

      const activeDsts = filteredDsts.filter(
        (item) => item.is_active !== false
      );
      console.log("Filtered DSTS: ", filteredDsts.length);
      const sortedDsts = filteredDsts.sort((a, b) =>
        a?.basicInfo?.fullLegalNameOfDst?.localeCompare(
          b.basicInfo.fullLegalNameOfDst
        )
      );
      setDsts(sortedDsts);
    } catch (error) {
      console.log(error.message);
      toast(`No Data Found! ${error.message}`, { type: "error" });
    } finally {
      appContext.updateState("loading", false);
    }
  };
  console.log("the length of dsts are;;", dsts.length);
  return (
    <div style={{ padding: "20px 20px" }}>
      <h2 style={{ marginBottom: "0px" }}>DSTs</h2>
      <Box sx={{ height: "800px", width: 1 }}>
        <div>
          <TextField
            size="small"
            margin="normal"
            fullWidth
            style={{
              marginBottom: "2rem",
              width: "25%",
              backgroundColor: "#fff",
            }}
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => handleSearch(event)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <div>
            <TableContainer>
              <Table
                sx={{ minWidth: 700 }}
                stickyHeader
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow style={{ backgroundColor: "black" }}>
                    <StyledTableCell style={{ width: "40rem" }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "30rem" }}>
                      Sponsor
                    </StyledTableCell>
                    <StyledTableCell>Draft</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows &&
                    rows?.map((key) => (
                      <StyledTableRow key={"dstDetails" + key}>
                        <StyledTableCell scope="row">
                          <Link
                            to={`/manage-dst/${key.id}`}
                            style={{ textDecoration: "none", color: "initial" }}
                          >
                            {key.name}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell
                          scope="row"
                          style={{ textAlign: "left" }}
                        >
                          {key.sponser}
                        </StyledTableCell>
                        <StyledTableCell scope="row">
                          <Link
                            to={`/add-dst/${key.id}`}
                            style={{ color: "initial", color: "#1976D2" }}
                          >
                            {key.draft}
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ManageDST;
