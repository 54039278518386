import styled from "styled-components";
import laptopImage from "../assets/laptop-image.png";
import backgroundImage from "../assets/background-image.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import tagManagerEvents from "../utills/GtmUtils";
import googleTagTriggers from "../utills/google-tag-triggers.json";
import { useEffect } from "react";

const LandingPage = () => {
  let navigate = useNavigate();
  useEffect(() => {
    tagManagerEvents(googleTagTriggers.landingPage);
  }, []);
  const handleLogin = () => {
    tagManagerEvents(googleTagTriggers.login_pressed);
    navigate("/login");
  };
  return (
    <PageWrapper>
      <Header>
        <Logo>
          <img src="/faviconPNG.png" style={{ height: "30px" }} alt="Logo" />
        </Logo>
        <Nav>
          {/* <NavItem>FEATURES</NavItem>
          <NavItem>BROKER DEALERS</NavItem>
          <NavItem>RESOURCES</NavItem>
          <NavItem>CONTACT</NavItem> */}
          <LoginButton onClick={() => handleLogin()}>LOG IN</LoginButton>
        </Nav>
      </Header>
      <MainContent>
        <LeftContent>
          <div
            className="login-signin-btn"
            style={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <Title className="custom-font">
              A Better Way To Illustrate Alternative Investments
            </Title>
            <div>
              <GetStartedButton onClick={() => handleLogin()}>
                LOG IN
              </GetStartedButton>
            </div>
          </div>
        </LeftContent>
        <RightContent>
          <LaptopImage
            src={laptopImage}
            alt="Laptop showing AltsIllustrator interface"
          />
        </RightContent>
      </MainContent>
    </PageWrapper>
  );
};

// Styles

const PageWrapper = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    background-position: center center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #fff;

  @media (max-width: 768px) {
    img {
      height: 25px !important;
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
`;

const NavItem = styled.a`
  color: #fff;
  margin-left: 30px;
  text-decoration: none;
  font-size: 13px;
  cursor: pointer;
  font-weight: bold;
`;

const LoginButton = styled.button`
  background-color: #a48c28;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-left: 30px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;

  @media (max-width: 768px) {
    padding: 8px 20px;
    font-size: 14px;
  }
`;

const MainContent = styled.main`
  display: flex;
  flex: 1;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const LeftContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin-left: 4%;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 0 20px;

    .login-signin-btn {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center !important;
    }
  }
`;

const Title = styled.h1`
  font-size: 4vw;
  color: #fff;
  max-width: 700px;
  margin-bottom: 30px;
  font-weight: 200;
  word-spacing: 15px;

  @media (max-width: 768px) {
    font-size: 28px;
    word-spacing: normal;
    line-height: 1.2;
    margin-bottom: 40px;
    font-weight: 300;
    letter-spacing: 1px;
    text-align: center;
    width: 100%;
  }
`;

const GetStartedButton = styled.button`
  background-color: #a48c28;
  color: #fff;
  border: none;
  padding: 15px 25px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;

  @media (max-width: 768px) {
    padding: 12px 30px;
    font-size: 16px;
    align-self: center;
  }
`;

const RightContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 40px;
    display: none; // Hide laptop image on mobile
  }
`;

const LaptopImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export default LandingPage;
