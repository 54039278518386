import React, { useContext, useEffect, useState } from "react";
import { API } from "aws-amplify";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
  Chip,
  IconButton,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import MyContext from "../context/appContext";

const VisibilitySettings = () => {
  const appContext = useContext(MyContext);
  const [brokerDealers, setBrokerDealers] = useState([]);
  const [dsts, setDsts] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [openDSTDialog, setOpenDSTDialog] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedDSTs, setSelectedDSTs] = useState({});
  const [filteredDSTs, setFilteredDSTs] = useState([]);
  const [tempSelectedDSTs, setTempSelectedDSTs] = useState({});

  useEffect(() => {
    fetchBrokerDealers();
    fetchDsts();
  }, []);

  useEffect(() => {
    const filtered = dsts.filter((dst) => {
      const dstName = dst?.basicInfo?.fullLegalNameOfDst || "";
      return dstName.toLowerCase().includes(searchText.toLowerCase());
    });
    setFilteredDSTs(filtered);
  }, [dsts, searchText]);

  const fetchBrokerDealers = async () => {
    try {
      const params = {
        headers: { "Content-Type": "application/json" },
      };
      const res = await API.get("dynamoRW", "/1031_broker_Dealer", params);
      if (res.items) {
        const activeBrokers = res.items.filter(
          (broker) => broker.status !== false
        );
        setBrokerDealers(activeBrokers);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Error fetching broker dealers! ${error.message}`);
    }
  };

  const fetchDsts = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: "id-name",
        },
      };
      const dstRes = await API.get("dynamoRW", "/dsts", payload);

      if (dstRes) {
        const activeDsts = dstRes.filter((dst) => !dst.is_deleted);
        setDsts(dstRes);
        setFilteredDSTs(dstRes);

        // Fetch all users from approvedUsers-dev
        const usersRes = await API.get(
          "dynamoRW",
          `/notifications?tablename=approvedUsers-dev`
        );

        // Group DST assignments by brokerDealerId
        const dstAssignments = {};
        usersRes.Items.forEach((user) => {
          const brokerDealerIds = user.brokerDealerIds || [];
          if (brokerDealerIds.length > 0 && user.assignedDsts) {
            const activeAssignedDsts = user.assignedDsts.filter(
              (dstId) => activeDsts.some((dst) => dst.id === dstId) // Adjust 'id' to match your DST identifier field
            );
            // Handle assignments for each broker dealer ID
            brokerDealerIds.forEach((brokerId) => {
              if (dstAssignments[brokerId]) {
                const combinedDsts = new Set([
                  ...dstAssignments[brokerId],
                  ...activeAssignedDsts,
                ]);
                dstAssignments[brokerId] = Array.from(combinedDsts);
              } else {
                dstAssignments[brokerId] = activeAssignedDsts;
              }
            });
          }
        });
        setSelectedDSTs(dstAssignments);
      }
      appContext.updateState("loading", false);
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Error fetching DSTs! ${error.message}`);
      appContext.updateState("loading", false);
    }
  };

  const handleBrokerSelect = (broker) => {
    setSelectedBroker(broker);
    setTempSelectedDSTs({
      [broker.id]: selectedDSTs[broker.id] || [],
    });
    setOpenDSTDialog(true);
  };

  const handleDSTSelection = (dstId, checked) => {
    if (!selectedBroker) return;

    setTempSelectedDSTs((prev) => ({
      ...prev,
      [selectedBroker.id]: checked
        ? [...new Set([...(prev[selectedBroker.id] || []), dstId])]
        : (prev[selectedBroker.id] || []).filter((id) => id !== dstId),
    }));
  };

  const handleAssignDSTs = async () => {
    if (!selectedBroker) return;

    try {
      appContext.updateState("loading", true);

      // Get all users with this brokerDealerId
      const usersRes = await API.get(
        "dynamoRW",
        `/notifications?tablename=approvedUsers-dev`
      );

      // Find users that have this broker dealer in their brokerDealerIds array
      const brokersUsers = usersRes.Items.filter((user) =>
        (user.brokerDealerIds || []).includes(selectedBroker.id)
      );

      // Update DST assignments for all users with this brokerDealerId
      const updatePromises = brokersUsers.map(async (user) => {
        const updateParams = {
          body: {
            tableName: "approvedUsers-dev",
            Item: {
              ...user,
              assignedDsts: tempSelectedDSTs[selectedBroker.id] || [],
              updatedAt: new Date().toISOString(),
            },
          },
        };

        return API.post("signUpAuthentication", "/signUpAuth", updateParams);
      });

      await Promise.all(updatePromises);

      // Update local state
      setSelectedDSTs((prev) => ({
        ...prev,
        [selectedBroker.id]: tempSelectedDSTs[selectedBroker.id] || [],
      }));

      toast.success("DSTs assigned successfully");
      setOpenDSTDialog(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Error assigning DSTs! ${error.message}`);
    } finally {
      appContext.updateState("loading", false);
    }
  };

  const isDSTSelected = (dstId) => {
    return (
      selectedBroker && tempSelectedDSTs[selectedBroker.id]?.includes(dstId)
    );
  };

  const getAssignedDSTCount = (brokerId) => {
    return selectedDSTs[brokerId]?.length || 0;
  };
  const handleSelectAllDSTs = (checked) => {
    if (checked) {
      const selectedDSTsIds = filteredDSTs.map((dst) => dst.id);
      setTempSelectedDSTs((prev) => ({
        ...prev,
        [selectedBroker.id]: selectedDSTsIds,
      }));
    } else {
      setTempSelectedDSTs((prev) => ({
        ...prev,
        [selectedBroker.id]: [],
      }));
    }
  };
  return (
    <Box sx={{ p: 3 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem",
        }}
      >
        <h3>Broker Dealer DST Assignments</h3>
      </div>
      <Grid container spacing={3}>
        {brokerDealers.map((broker) => (
          <Grid item xs={12} md={6} lg={4} key={broker.id}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography variant="h6">{broker.name}</Typography>
                  <Button
                    variant="contained"
                    startIcon={<EditIcon />}
                    onClick={() => handleBrokerSelect(broker)}
                  >
                    Manage DSTs
                  </Button>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {broker.email}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Chip
                    label={`${getAssignedDSTCount(broker.id)} DSTs Assigned`}
                    color="primary"
                    variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openDSTDialog}
        onClose={() => setOpenDSTDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              Assign DSTs to {selectedBroker?.name}
            </Typography>
            <div style={{ display: "flex" }}>
              <TextField
                size="small"
                placeholder="Search DSTs..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControlLabel
                sx={{ ml: 2 }}
                control={
                  <Checkbox
                    checked={filteredDSTs.every((dst) => isDSTSelected(dst.id))}
                    onChange={(e) => handleSelectAllDSTs(e.target.checked)}
                  />
                }
                label="Select All"
              />
            </div>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <List>
            {filteredDSTs.map((dst) => (
              <React.Fragment key={dst.id}>
                <ListItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isDSTSelected(dst.id)}
                        onChange={(e) =>
                          handleDSTSelection(dst.id, e.target.checked)
                        }
                      />
                    }
                    label={
                      <Box>
                        <Typography variant="subtitle1">
                          {dst.basicInfo.fullLegalNameOfDst}
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAssignDSTs}
            disabled={!selectedBroker}
          >
            Assign DSTs
          </Button>
          <Button
            onClick={() => {
              setOpenDSTDialog(false);
              setTempSelectedDSTs({}); // Clear temporary selections
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default VisibilitySettings;
