import React, { useEffect, useState } from 'react';
import AWS from "aws-sdk";
import emailjs from '@emailjs/browser';

import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Container
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import SupportAgentOutlined from '@mui/icons-material/SupportAgentOutlined';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { ReactComponent as CustomSupportIcon } from '../assets/support_8302431 1.svg';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import VideoDialogRepresentation from '../components/DialogComponents/VideoDialogRepresentation';
import SupportModal from '../components/DialogComponents/SupportModal';

import axios from 'axios';
import { toast } from 'react-toastify';

emailjs.init("QrftoOLZbHZC9VKrq");


const DashboardCard = styled(Card)(({ theme }) => ({
    height: '45vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
    }
}));

const videoLinks = {
    'Portfolio Illustration': 'https://player.vimeo.com/video/1033661213',
    'Cash Flow Analysis': 'https://player.vimeo.com/video/1033662046',
    'TEY Calculator': 'https://player.vimeo.com/video/1033661569',
    'Tax Estimator': 'https://player.vimeo.com/video/1033661346',
    'Cash Out Analysis': 'https://player.vimeo.com/video/1033660674',
    'Offering Summary': 'https://player.vimeo.com/video/1033661037',
    'NOI / Cap Rate': 'https://player.vimeo.com/video/1033660842',
    'DST Data Grid': 'https://player.vimeo.com/video/1033660718',
    'ID Letter': 'https://player.vimeo.com/video/1033660801'
};

const pdfLinks = {
    'TEY Calculator': '/TEYCalculator.pdf',
    'Cash Out Analysis': '/cash_out_analysis.pdf',
    'Tax Estimator': '/tax_estimator.pdf',
    'Portfolio Illustration': '/portfolio_illustration.pdf',
    'Cash Flow Analysis': '/cash_flow_analysis.pdf',
};

const Dashboard = () => {
    const [username, setUsername] = useState("");
    let navigate = useNavigate();
    const features = [
        {
            title: 'Cash Flow Analysis',
            icon: <LocalAtmIcon />,
            path: '/analysis',
            analysisType: 'cash-flow-analysis'
        },
        {
            title: 'Tax Estimator Analysis',
            icon: <AccountBalanceIcon />,
            path: '/analysis',
            analysisType: 'tax-estimator'
        },
        {
            title: 'Cash Out Analysis',
            icon: <AttachMoneyIcon />,
            path: '/cash-out-analysis'
        },
        {
            title: 'Portfolio Illustration',
            icon: <BarChartIcon />,
            path: '/portfolio-illustration'
        }
    ];

    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
    const firmName = localStorage.getItem("companyName")
    const apiKey = process.env.REACT_APP_API_KEY;
    const secret = process.env.REACT_APP_SECRET_KEY;
    const REGION = process.env.REACT_APP_LAMBDA_REGION;
    const Lambda_ARN = process.env.REACT_APP_LAMBDA_ARN;
    useEffect(() => {
        fetchUserData();
    }, []);

    const handleOpenSupportModal = () => {
        setIsSupportModalOpen(true);
    };

    const handleCloseSupportModal = () => {
        setIsSupportModalOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const templateParams = {
                from_name: email,
                message,
            };
            console.log("The params are::", templateParams)

            await emailjs.send("service_7ipytxg", "template_sll3buy", {
                from_name: email,
                message: message,
                reply_to: email,
            })

            alert('Your query has been sent successfully!');

            // Reset form fields
            setMessage('');
            handleCloseSupportModal(); // Close modal after submission
        } catch (error) {
            console.log("The error is:::", error);
            alert('Failed to send email.');
        }
    };
    const fetchUserData = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const fetchedUsername = user.attributes.name;
            const fetchEmail = user?.attributes?.email
            setEmail(fetchEmail)
            setUsername(fetchedUsername);
        } catch (error) {
            console.error("Error fetching user data:", error.message);
        }
    };

    const handleVideoClick = (videoTitle) => {
        setSelectedVideo(videoLinks[videoTitle]);
        setIsVideoOpen(true);
    };

    const handleCloseVideo = () => {
        setIsVideoOpen(false);
        setSelectedVideo(null);
    };
    if (!firmName || firmName === "undefined") {
        toast(`Firm name is missing. Please update your profile!`, { type: "warning" });
        navigate("/profile");
    }

    return (
        <Box sx={{ p: 3, backgroundColor: '#f5f5f5', minHeight: '90vh' }}>
            <Typography variant="h5" sx={{ mb: 3, fontWeight: 700, color: '#333', pl: 1 }}>
                Welcome, {username}
            </Typography>

            <Grid container spacing={3}>
                {/* Get Started Card */}
                <Grid item xs={12} md={6}>
                    <DashboardCard>
                        <CardContent sx={{ height: '100%', p: 3 }}>
                            <Typography style={{ fontWeight: 700 }} variant="h6" gutterBottom>
                                Get Started
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                Get started by watching training videos for each module.
                            </Typography>

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Grid container spacing={2}>
                                    {Object.keys(videoLinks).map((item, index) => (
                                        <Grid item xs={6} key={item}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                    cursor: 'pointer',
                                                    '&:hover': { opacity: 0.8 }
                                                }}
                                                onClick={() => handleVideoClick(item)}
                                            >
                                                <PlayCircleOutlineIcon sx={{ fontSize: 30, fontWeight: 700 }} />
                                                <Typography>{item}</Typography>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </CardContent>
                    </DashboardCard>
                </Grid>

                {/* Suggested Features Card */}
                <Grid item xs={12} md={6}>
                    <DashboardCard>
                        <CardContent sx={{ height: '100%', p: 3 }}>
                            <Typography style={{ fontWeight: 700 }} variant="h6" gutterBottom>
                                Suggested Features
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                Quick links to common features.
                            </Typography>

                            <Grid container spacing={2}>
                                {features.map((feature) => (
                                    <Grid item xs={6} key={feature.title}>
                                        <Box
                                            onClick={() => feature.analysisType ? navigate(feature.path, feature.analysisType) : navigate(feature.path)}
                                            sx={{
                                                p: 2,
                                                bgcolor: '#212934',
                                                color: '#ffd700',
                                                borderRadius: 1,
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                opacity: 0.9,
                                                '&:hover': {
                                                    opacity: 1,
                                                    transform: 'scale(1.02)',
                                                    transition: 'all 0.2s ease-in-out'
                                                }
                                            }}
                                        >
                                            <Typography variant="body2">{feature.icon}</Typography>
                                            <Typography variant="body2">{feature.title}</Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </CardContent>
                    </DashboardCard>
                </Grid>

                {/* Reports Card */}
                <Grid item xs={12} md={6}>
                    <DashboardCard>
                        <CardContent sx={{ height: '100%', p: 3 }}>
                            <Typography style={{ fontWeight: 700 }} variant="h6" gutterBottom>
                                Reports
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                Find sample reports below for each module.
                            </Typography>

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                {Object.keys(pdfLinks).map((item) => (
                                    <Box
                                        onClick={() => {
                                            const pdfUrl = pdfLinks[item];
                                            if (pdfUrl) {
                                                window.open(pdfUrl, '_blank'); // Opens the PDF in a new tab
                                            } else {
                                                console.error(`PDF link for ${item} is not defined.`);
                                            }
                                        }}
                                        key={item} sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }}>
                                        <DescriptionIcon />
                                        <Typography>{item}</Typography>
                                        <IconButton
                                            sx={{ ml: 1 }}
                                        >
                                        </IconButton>
                                    </Box>
                                ))}
                            </Box>
                        </CardContent>
                    </DashboardCard>
                </Grid>

                {/* Contact Support Card */}
                <Grid item xs={12} md={6}>
                    <DashboardCard >
                        <CardContent onClick={handleOpenSupportModal} sx={{ height: '100%', p: 3 }}>
                            <Typography style={{ fontWeight: 700 }} variant="h6" gutterBottom>
                                Contact Support
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                                Get help from AI&I Illustrator support.
                            </Typography>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <CustomSupportIcon style={{ width: '4rem', height: '4rem' }} />
                                <Box>
                                    <Typography variant="body2" fontWeight="bold">
                                        Support Email
                                    </Typography>
                                    <Typography variant="body2">
                                        help@altsillustrator.com
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </DashboardCard>
                </Grid>
            </Grid>

            <VideoDialogRepresentation isVideoOpen={isVideoOpen} handleCloseVideo={handleCloseVideo} selectedVideo={selectedVideo} />
            <SupportModal isSupportModalOpen={isSupportModalOpen} email={email} setEmail={setEmail} handleCloseSupportModal={handleCloseSupportModal} handleSubmit={handleSubmit} message={message} setMessage={setMessage} />
        </Box>
    );
};

export default Dashboard; 