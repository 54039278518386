import React, { useContext, useEffect, useState } from "react";
import { Card, Dialog, Select, ThemeProvider } from "@material-ui/core";
import { API, Auth } from "aws-amplify";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import MyContext from "../context/appContext";
import Switch from "@mui/material/Switch";
import {
  Button,
  FormControl,
  MenuItem,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import AWS from "aws-sdk";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ManageRole = () => {
  const appContext = useContext(MyContext);
  const role = localStorage.getItem("role");
  const [show, setShow] = useState(false);
  const [isAgent, setIsAgent] = useState(false);
  const [manageInvestors, setManageInvestors] = useState(false);
  const [email, setEmail] = useState([""]);
  const [stateList, setStateList] = useState([]);

  const apiKey = process.env.REACT_APP_API_KEY;
  const secret = process.env.REACT_APP_SECRET_KEY;
  const REGION = process.env.REACT_APP_LAMBDA_REGION;
  const Lambda_ARN = process.env.REACT_APP_LAMBDA_ARN;

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = async () => {
    try {
      appContext.updateState("loading", true);
      let tablename = "approvedUsers-dev";
      const res = await API.get(
        "dynamoRW",
        `/notifications?tablename=${tablename}`
      );
      let filteredList = [];
      const approvalAccountId = await Auth.currentAuthenticatedUser();

      // if (role === "Super Admin") {
      //   filteredList = res.Items.filter((item) => item.role !== "Investor");
      // } else if (role === "Advisor ") {
      //   filteredList = res.Items.filter(
      //     (item) =>
      //       item.role === "Investor" &&
      //       item.approvalAccountId === approvalAccountId.username
      //   );
      // }
      if (role === "Super Admin") {
        filteredList = res.Items;
        setStateList(filteredList);
      } else if (role === "Advisor") {
        filteredList = res.Items.filter(
          (item) =>
            item.role === "Investor" &&
            item.approvalAccountId === approvalAccountId.username
        );
        setStateList(filteredList);
      }
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No State Found! ${error.message}`, { type: "error" });
    }
  };

  const handleStatusChange = async (_index) => {
    appContext.updateState("loading", true);
    let { id, status } = stateList[_index];
    const params = {
      body: {
        tableName: "approvedUsers-dev",
        Key: {
          id: id,
        },
        AttributeUpdates: {
          status: {
            Action: "PUT",
            Value: !status,
          },
        },
      },
    };
    const res = await API.put("dynamoRW", "/dsts", params);
    if (res.ResponseMetadata.HTTPStatusCode === 200) {
      getStatus();
    }
  };
  const handleRoleChange = async (event, _index) => {
    const { value } = event.target;
    try {
      appContext.updateState("loading", true);
      let { id } = stateList[_index];
      const params = {
        body: {
          tableName: "approvedUsers-dev",
          Key: {
            id: id,
          },
          AttributeUpdates: {
            role: {
              Action: "PUT",
              Value: value,
            },
          },
        },
      };
      const res = await API.put("dynamoRW", "/dsts", params);
      if (res.ResponseMetadata.HTTPStatusCode === 200) {
        getStatus();
        appContext.updateState("loading", false);
        toast("Saved successfully", { type: "success" });
      }
    } catch (error) {
      appContext.updateState("loading", false);
      toast(`Error saving state tax! ${error.message}`, { type: "error" });
    }
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };
  const handleClose = () => {
    setShow(false);
    setEmail([""]);
  };

  const handleInvite = async () => {
    setShow(false);
    appContext.updateState("loading", true);
    try {
      AWS.config.update({
        accessKeyId: apiKey,
        secretAccessKey: secret,
        region: REGION,
      });
      const lambda = new AWS.Lambda();

      const functionArn = Lambda_ARN;

      const payload = {
        recipient: email,
        subject: `Invitation from Altsillustrator`,
        mailBody: `
        We are excited to invite you to join AltsIllustrator as an ${
          isAgent ? "agent" : "investor"
        }. Your involvement could play a key role in shaping the future of our platform.
        To get started, please click on ${
          isAgent
            ? "https://www.altsillustrator.com/agent_signup"
            : "https://www.altsillustrator.com/investor_signup"
        }
      `,
      };
      const params = {
        FunctionName: functionArn,
        InvocationType: "RequestResponse",
        Payload: JSON.stringify(payload),
      };

      const response = await lambda.invoke(params).promise();
      const currentUser = await Auth.currentAuthenticatedUser();
      const invitedBy = currentUser?.attributes?.email;

      // Store invitation record in DynamoDB
      const inviteParams = {
        body: {
          tableName: "altsnotifications-dev",
          Item: {
            id: email, // Using email as ID for now
            userEmail: email,
            status: false,
            invitedBy: invitedBy,
            invitedAt: new Date().toISOString(),
          },
        },
      };
      await API.post("signUpAuthentication", "/signUpAuth", inviteParams);

      appContext.updateState("loading", false);
      toast(`Email sent successfully!`, { type: "success" });
    } catch (error) {
      appContext.updateState("loading", false);
      console.log("Error invoking Lambda function:", error);
      toast(error.message, { type: "error" });
    }
  };

  const handleDeleteUser = async (userData) => {
    try {
      appContext.updateState("loading", true);
      // 1. Delete from Cognito using Amplify Auth
      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
      const accessToken =
        currentAuthenticatedUser.signInUserSession.accessToken.jwtToken;

      await Auth.deleteUserByEmail(userData.userEmail, accessToken);

      // 2. Delete from DynamoDB
      const dynamoParams = {
        body: {
          tableName: "approvedUsers-dev",
          Key: {
            id: userData.id,
          },
        },
      };

      await API.del("dynamoRW", "/dsts", dynamoParams);

      // 3. Refresh the list
      await getStatus();

      appContext.updateState("loading", false);
      toast("User deleted successfully", { type: "success" });
    } catch (error) {
      appContext.updateState("loading", false);
      console.error("Error deleting user:", error);
      toast(`Error deleting user: ${error.message}`, { type: "error" });
    }
  };

  return (
    <>
      <div style={{ padding: "20px 20px" }}>
        <ThemeProvider>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <h3>Manage Users</h3>
            <div style={{ display: "flex", gap: "1rem" }}>
              <Button
                sx={{ textTransform: "none" }}
                variant="contained"
                onClick={() => setShow(true)}
              >
                Invite Investor
              </Button>
              {/* <Button
                sx={{ textTransform: "none" }}
                variant="contained"
                onClick={() => {
                  setIsAgent(true);
                  setShow(true);
                }}
              >
                Invite Agent
              </Button> */}
              {/* <Button
                sx={{ textTransform: "none" }}
                variant="contained"
                onClick={() => {
                  setManageInvestors(true);
                }}
              >
                Manage Investors
              </Button> */}
            </div>
          </div>
          <TableContainer>
            <Table
              style={{ minWidth: 700, margin: "0 auto" }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Role </StyledTableCell>
                  {/* <StyledTableCell align="center">Actions</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {stateList?.map(
                  (row, ind) => (
                    <StyledTableRow key={ind}>
                      <StyledTableCell align="center">
                        {row.username}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {row.userEmail}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Switch
                          checked={row.status}
                          onChange={() => handleStatusChange(ind)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <FormControl>
                          {role === "Advisor" ? (
                            <Select
                              value={row.role}
                              onChange={(event) => handleRoleChange(event, ind)}
                            >
                              <MenuItem value={"Investor"}>Investor</MenuItem>
                            </Select>
                          ) : (
                            <Select
                              value={row.role}
                              onChange={(event) => handleRoleChange(event, ind)}
                            >
                              <MenuItem value={"Admin"}>Admin</MenuItem>
                              <MenuItem value={"Advisor"}>Advisor</MenuItem>
                              <MenuItem value={"Super Admin"}>
                                Super Admin
                              </MenuItem>
                              <MenuItem value={"Investor"}>Investor</MenuItem>
                              <MenuItem value={"Agent"}>Agent</MenuItem>
                            </Select>
                          )}
                        </FormControl>
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        <IconButton
                          color="error"
                          onClick={() => {
                            if (window.confirm('Are you sure you want to delete this user?')) {
                              handleDeleteUser(row);
                            }
                          }}
                          disabled={role !== 'Super Admin'}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </StyledTableCell> */}
                    </StyledTableRow>
                  )
                  // )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </ThemeProvider>
      </div>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Email Address</DialogTitle>
        <DialogContent sx={{ width: "450px" }}>
          <TextField
            size="small"
            margin="normal"
            fullWidth
            label={`Email`}
            onChange={(e) => handleEmailChange(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "none" }}
            variant="outlined"
            onClick={() => handleClose()}
          >
            Close
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            variant="contained"
            onClick={handleInvite}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManageRole;
