import React, { useEffect, useState, useContext } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "../css/styles.css";
import Grid from "@mui/material/Grid";
import { MenuItem } from "@material-ui/core";
import MyContext from "../context/appContext";
import { API, Auth } from "aws-amplify";
import AWS from "aws-sdk";
import { Alert } from "@mui/material";

const defaultTheme = createTheme();

export default function AddDstForm({
  formData,
  setFormData,
  highlights,
  setHighlights,
  yields,
  setYields,
  proceeds,
  setProceeds,
  otherInfo,
  setOtherInfo,
}) {
  let navigate = useNavigate();
  const [findBasicInfo, setFindBasicInfo] = useState({});
  const appContext = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  let { id } = useParams();
  const [stateList, setStateList] = useState([]);
  const [errMessage, setErrMessage] = useState(false);
  const [outputNotify, setOutputNotify] = useState(false);
  const [numericalDifference, setNumericalDifference] = useState(0);

  useEffect(() => {
    if (id) {
      basicInfoData();
      getStates();
    } else {
      setFormData((prevFormData) => {
        const updatedFormData = {};
        Object.keys(prevFormData).forEach((key) => {
          updatedFormData[key] = "";
        });
        return updatedFormData;
      });
      setHighlights((prevFormData) => {
        const updatedHighlightsData = {};
        Object.keys(prevFormData).forEach((key) => {
          updatedHighlightsData[key] = "";
        });
        return updatedHighlightsData;
      });
      setYields((prevYieldsData) => {
        const updatedYieldsData = {};
        Object.keys(prevYieldsData).forEach((key) => {
          updatedYieldsData[key] = "";
        });
        return updatedYieldsData;
      });
      setProceeds((prevProceedsData) => {
        const updatedProceedsData = {};
        Object.keys(prevProceedsData).forEach((key) => {
          updatedProceedsData[key] = "";
        });
        return updatedProceedsData;
      });
      setOtherInfo((prevOtherData) => {
        const updatedOtherData = {};
        Object.keys(prevOtherData).forEach((key) => {
          updatedOtherData[key] = "";
        });
        return updatedOtherData;
      });
      getStates();
    }
  }, [id]);
  useEffect(() => {
    const storedFormData = sessionStorage.getItem("formStateDst");
    if (storedFormData) {
      const { formData, highlights, yields, proceeds, otherInfo } =
        JSON.parse(storedFormData);
      setFormData(formData);
      setHighlights(highlights);
      setYields(yields);
      setProceeds(proceeds);
      setOtherInfo(otherInfo);
    }
  }, []);
  useEffect(() => {
    const storedFormState = sessionStorage.getItem("addDstBasicInfoSession");
    if (storedFormState) {
      const parsedFormState = JSON.parse(storedFormState);
      const { formData, highlights, yields, proceeds, otherInfo } =
        parsedFormState;
      setFormData(formData);
      setHighlights(highlights);
      setYields(yields);
      setProceeds(proceeds);
      setOtherInfo(otherInfo);
    }
  }, []);
  const basicInfoData = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: id,
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      setFindBasicInfo(res.Item);
      appContext.updateState("loading", false);
      if (id) {
        setFormData(res.Item?.basicInfo);
        setHighlights(res.Item?.highlights);
        setYields(res.Item?.yields);
        setProceeds(res.Item?.proceeds);
        setOtherInfo(res.Item?.otherInfo);
      }
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No DST Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };
  const [isSubmit, setIsSubmit] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmit(true);
    if (!formData.password) {
      return;
    }
  };

  const getStates = async () => {
    try {
      const payload = {
        queryStringParameters: {
          tableName: "state_info-dev",
          id: "all",
          LastEvaluatedKey: "None",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      setStateList(res);
    } catch (error) {
      console.log(error.message);
      toast(`No State Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };
  const stateOption = stateList
    ?.map((st) => ({
      state: st.state,
      code: st.code,
    }))
    .sort((a, b) => a.state.localeCompare(b.state));

  const applyMask = (fieldName, inputVal) => {
    const fieldsToMask = [
      "minimum1031Investment",
      "totalDstReserves",
      "purchasePrice",
      "year1NetOperatingIncome",
      "year1EffectiveGrossRevenue",
      "OOCosts",
      "brokerDealerCommissions",
      "wholesalingCosts",
      "transactionCommission",
      "financingFee",
      "otherFees",
      "thirdPartyClosingCosts",
      "appraisedValue",
      "offeringEquity",
      "offeringDebt",
      "year1TotalDebtService",
    ];

    if (fieldsToMask.includes(fieldName)) {
      const cleanInput = inputVal.replace(/,/g, "").replace(/\.(?=.*\.)/g, "");
      const [wholePart, decimalPart] = cleanInput.split(".");
      const formattedWholePart = wholePart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      if (decimalPart !== undefined) {
        return `${formattedWholePart}.${decimalPart}`;
      } else {
        return formattedWholePart;
      }
    } else {
      return inputVal;
    }
  };

  const handleChange = (e) => {
    setOutputNotify(false);
    setNumericalDifference(0);
    setErrMessage(false);
    const inputVal = e.target.value;
    const fieldName = e.target.name;
    const maskedInput = applyMask(fieldName, inputVal);

    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData, [fieldName]: maskedInput };
      handleSessionState(newFormData, highlights, yields, proceeds, otherInfo);
      return newFormData;
    });
  };

  const handleChangeStates = (e) => {
    setOutputNotify(false);
    const selectedOptions = e.target.value;
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData, states: selectedOptions };
      handleSessionState(newFormData, highlights, yields, proceeds, otherInfo);
      return newFormData;
    });
  };

  const handleChangeHighlights = (e) => {
    setOutputNotify(false);
    const { name, value } = e.target;
    setHighlights((prevHighlights) => {
      const newHighlights = { ...prevHighlights, [name]: value };
      handleSessionState(formData, newHighlights, yields, proceeds, otherInfo);
      return newHighlights;
    });
  };

  const handleChangeYield = (e) => {
    setOutputNotify(false);
    const inputVal = e.target.value;
    const fieldName = e.target.name;
    const maskedInput = applyMask(fieldName, inputVal);
    setYields((prevYields) => {
      const newYields = { ...prevYields, [fieldName]: maskedInput };
      handleSessionState(formData, highlights, newYields, proceeds, otherInfo);
      return newYields;
    });
  };

  const handleChangeProceeds = (e) => {
    setOutputNotify(false);
    const inputVal = e.target.value;
    const fieldName = e.target.name;
    const maskedInput = applyMask(fieldName, inputVal);
    setProceeds((prevProceeds) => {
      const newProceeds = { ...prevProceeds, [fieldName]: maskedInput };
      handleSessionState(formData, highlights, yields, newProceeds, otherInfo);
      return newProceeds;
    });
  };

  const handleChangeOtherInfo = (e) => {
    setOutputNotify(false);
    const { name, value } = e.target;
    setOtherInfo((prevOtherInfo) => {
      const newOtherInfo = { ...prevOtherInfo, [name]: value };
      handleSessionState(formData, highlights, yields, proceeds, newOtherInfo);
      return newOtherInfo;
    });
  };

  const handleSessionState = (
    updatedFormData = formData,
    updatedHighlights = highlights,
    updatedYields = yields,
    updatedProceeds = proceeds,
    updatedOtherInfo = otherInfo
  ) => {
    const formState = {
      formData: updatedFormData,
      highlights: updatedHighlights,
      yields: updatedYields,
      proceeds: updatedProceeds,
      otherInfo: updatedOtherInfo,
    };
    sessionStorage.setItem("addDstBasicInfoSession", JSON.stringify(formState));
  };

  const handleAddData = async () => {
    const getRepCommission = () => {
      if (
        !formData.hasOwnProperty("repCommission") ||
        formData.repCommission === null ||
        formData.repCommission === undefined ||
        formData.repCommission === ""
      ) {
        return "";
      }
      return formData.repCommission;
    };

    if (id) {
      try {
        appContext.updateState("loading", true);
        const params = {
          body: {
            tableName: "dsts_table-dev",
            Key: {
              id: id,
            },
            AttributeUpdates: {
              is_draft: {
                Action: "PUT",
                Value: false,
              },
              is_active: {
                Action: "PUT",
                Value: true,
              },
              basicInfo: {
                Value: { ...formData },
                Action: "PUT",
              },
              highlights: {
                Value: { ...highlights },
                Action: "PUT",
              },
              yields: {
                Value: { ...yields },
                Action: "PUT",
              },
              proceeds: {
                Value: { ...proceeds },
                Action: "PUT",
              },
              otherInfo: {
                Value: { ...otherInfo },
                Action: "PUT",
              },
              repCommission: {
                Value: getRepCommission(),
                Action: "PUT",
              },
            },
          },
        };
        const res = await API.put("dynamoRW", "/dsts", params);
        appContext.updateState("loading", false);
        toast("DST updated successfully", { type: "success" });
      } catch (error) {
        appContext.updateState("loading", false);
        console.log(error.message);
        toast(`Error updating DST! ${error.message}`, { type: "error" });
      } finally {
        setLoading(false);
      }
    } else {
      if (!formData.fullLegalNameOfDst) {
        setErrMessage(true);
        return;
      }
      try {
        appContext.updateState("loading", true);
        const currentUser = await Auth.currentUserInfo();
        const dstId = AWS.util.uuid.v4();
        const res = await API.post("dynamoRW", "/dsts", {
          body: {
            tableName: "dsts_table-dev",
            Item: {
              id: dstId,
              is_active: true,
              is_deleted: false,
              basicInfo: { ...formData },
              highlights: { ...highlights },
              yields: { ...yields },
              proceeds: { ...proceeds },
              otherInfo: { ...otherInfo },
              repCommission: getRepCommission(),
              createdBy: currentUser?.attributes?.email,
            },
          },
        });
        if (res) {
          const users = await handleAssignDSTs(
            currentUser?.attributes?.email,
            dstId
          );
        }
        appContext.updateState("loading", false);
        toast("New DST added successfully", { type: "success" });
        handleReset();
      } catch (error) {
        appContext.updateState("loading", false);
        console.log(error.message);
        toast(`Error adding DST! ${error.message}`, { type: "error" });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleAssignDSTs = async (currentUserId, dstId) => {
    try {
      appContext.updateState("loading", true);

      // Get all users with their broker dealer assignments
      const usersRes = await API.get(
        "dynamoRW",
        `/notifications?tablename=approvedUsers-dev`
      );
      const currentUser = usersRes.Items.find(
        (user) => user.id === currentUserId
      );
      console.log("The current user:::", currentUser);
      const selectedBrokerId = currentUser.brokerDealerIds[0];
      // Find users that have the selected broker dealer in their brokerDealerIds array
      const brokersUsers = usersRes.Items.filter((user) =>
        (user.brokerDealerIds || []).includes(selectedBrokerId)
      );
      console.log("the selected id:", selectedBrokerId);
      // Update DST assignments for all users with the selected broker dealer
      const updatePromises = brokersUsers.map(async (user) => {
        const updateParams = {
          body: {
            tableName: "approvedUsers-dev",
            Item: {
              ...user,
              assignedDsts: [...(user.assignedDsts || []), dstId],
              updatedAt: new Date().toISOString(),
            },
          },
        };

        await API.post("signUpAuthentication", "/signUpAuth", updateParams);
      });

      await Promise.all(updatePromises);

      toast.success("DSTs assigned successfully");
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Error assigning DSTs! ${error.message}`);
    } finally {
      appContext.updateState("loading", false);
    }
  };
  // const handleAssignDSTs = async (currentUserId, dstId) => {
  //   console.log("The user Name::", currentUserId, dstId);
  //   try {
  //     appContext.updateState("loading", true);

  //     // Get the current user's ID

  //     // Get all users with their broker dealer assignments
  //     const usersRes = await API.get(
  //       "dynamoRW",
  //       `/notifications?tablename=approvedUsers-dev`
  //     );

  //     // Find the current user's broker dealer assignment
  //     const currentUser = usersRes.Items.find(
  //       (user) => user.id === currentUserId
  //     );
  //     console.log("The user::", currentUser);
  //     if (
  //       currentUser &&
  //       currentUser.brokerDealerIds &&
  //       currentUser.brokerDealerIds.length > 0
  //     ) {
  //       // Get the first broker dealer ID assigned to the current user
  //       const selectedBrokerId = currentUser.brokerDealerIds[0];
  //       console.log("the selectedBrokerId:::", selectedBrokerId);
  //       // Update DST assignments for the current user
  //       const updateParams = {
  //         body: {
  //           tableName: "approvedUsers-dev",
  //           Item: {
  //             ...currentUser,
  //             assignedDsts: [...(currentUser.assignedDsts || []), dstId],
  //             updatedAt: new Date().toISOString(),
  //           },
  //         },
  //       };
  //       console.log("The update params are...", updateParams);
  //       await API.post("signUpAuthentication", "/signUpAuth", updateParams);

  //       //  // Update local state
  //       //  setSelectedDSTs((prev) => ({
  //       //    ...prev,
  //       //    [selectedBrokerId]: tempSelectedDSTs[selectedBrokerId] || [],
  //       //  }));

  //       toast.success("DSTs assigned successfully");
  //       //  setOpenDSTDialog(false);
  //     } else {
  //       toast.error("You are not linked with any broker dealer");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     toast.error(`Error assigning DSTs! ${error.message}`);
  //   } finally {
  //     appContext.updateState("loading", false);
  //   }
  // };
  const handleDraft = async () => {
    if (!formData.fullLegalNameOfDst) {
      setErrMessage(true);
      return;
    }
    try {
      appContext.updateState("loading", true);
      const currentUser = await Auth.currentUserInfo();
      const dstId = AWS.util.uuid.v4();
      const res = await API.post("dynamoRW", "/dsts", {
        body: {
          tableName: "dsts_table-dev",
          Item: {
            id: dstId,
            is_draft: true,
            is_active: false,
            is_deleted: false,
            basicInfo: { ...formData },
            highlights: { ...highlights },
            yields: { ...yields },
            proceeds: { ...proceeds },
            otherInfo: { ...otherInfo },
            repCommission: formData.repCommission,
            createdBy: currentUser?.attributes?.email,
          },
        },
      });
      const users = await handleAssignDSTs(
        currentUser?.attributes?.email,
        dstId
      );
      appContext.updateState("loading", false);
      handleReset();
      toast("DST added in draft", { type: "success" });
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`Error to add DST in draft! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setFormData({
      fullLegalNameOfDst: "",
      numberOfProperties: "",
      offeringEquity: "",
      nameOfTheSponsor: "",
      percentRetainedBySponsor: "",
      offeringDebt: "",
      MSA: "",
      concordeApprovalDate: "",
      totalUnitsInPortfolio: "",
      loanTerm: "",
      yearsPropertiesWereBuilt: "",
      interestOnlyPeriod: "",
      generalLocationRelativeToMajorMarket: "",
      states: [],
      currentDstOccupancy: "",
      loanRate: "",
      dstPropertiesSector: "",
      lender: "",
      dstPropertiesClass: "",
      purchasePrice: "",
      year1TotalDebtService: "",
      dstPropertiesStyle: "",
      appraisedValue: "",
      year1NetOperatingIncome: "",
      minimum1031Investment: "",
      rentGrowthAssumption: "",
      year1EffectiveGrossRevenue: "",
      msaTier: "",
      fixedVariable: "",
      upreit: "",
      BC: "",
      sponsorBullet1: "",
      sponsorBullet2: "",
      sponsorBullet3: "",
      uniqueDealRisk1: "",
      uniqueDealRisk2: "",
      uniqueDealRisk3: "",
      uniqueDealRisk4: "",
      uniqueDealRisk5: "",
      repCommission: "",
    });

    setHighlights({
      marketGrowthPoint1: "",
      marketGrowthPoint2: "",
      marketGrowthPoint3: "",
      marketEmploymentPoint: "",
      marketEconomicPoint: "",
      propertyLocationPoint1: "",
      propertyLocationPoint2: "",
      propertyLocationPoint3: "",
      propertyFeaturePoint1: "",
      propertyFeaturePoint2: "",
    });

    setYields({
      year1Yield: "",
      year2Yield: "",
      year3Yield: "",
      year4Yield: "",
      year5Yield: "",
      year6Yield: "",
      year7Yield: "",
      year8Yield: "",
      year9Yield: "",
      year10Yield: "",
    });
    setProceeds({
      OOCosts: "",
      brokerDealerCommissions: "",
      wholesalingCosts: "",
      transactionCommission: "",
      financingFee: "",
      otherFees: "",
      thirdPartyClosingCosts: "",
      totalDstReserves: "",
      year5ReservesBalance: "",
    });
    setOtherInfo({
      useOfProceedsPage: "",
      cashFlowPage: "",
      pppRiskFactorsPage: "",
      assumptionsPage: "",
      averageRemainingLeaseDuration: "",
      dstOfferingStrengthAndOppertunities: "",
      dstOfferingWeaknessesAndThreats: "",
      generalNotes: "",
      keyOverallMarketBulletPoints: "",
      dispositionFee: "",
    });
  };

  const handleOutput = () => {
    const cleanedEquity = formData?.offeringEquity.replace(/[^0-9.]/g, "");
    const cleanedDebt = formData?.offeringDebt.replace(/[^0-9.]/g, "");
    const cleanedPurchasePrice = formData?.purchasePrice.replace(
      /[^0-9.]/g,
      ""
    );
    const count = +cleanedEquity + +cleanedDebt;

    const sanitizedProceeds = { ...proceeds };
    Object.keys(sanitizedProceeds).forEach((key) => {
      if (key !== "year5ReservesBalance") {
        sanitizedProceeds[key] = parseFloat(
          sanitizedProceeds[key].replace(/[^0-9.]+/g, "")
        );
        if (isNaN(sanitizedProceeds[key])) {
          sanitizedProceeds[key] = 0;
        }
      }
    });
    const totalProceeds = Object.keys(sanitizedProceeds)
      .filter((key) => key !== "year5ReservesBalance")
      .reduce((acc, key) => acc + sanitizedProceeds[key], 0);
    const total = +cleanedPurchasePrice + +totalProceeds;

    const difference = Math.abs(+count - +total);

    if (difference === 0) {
      setOutputNotify(false);
      navigate(`/print-dst/${id}`);
    } else {
      setNumericalDifference(difference);
      setOutputNotify(true);
    }
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main">
          <CssBaseline />
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              marginTop: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <h3>Basic DST Offering Data</h3>
            </div>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  error={errMessage === true}
                  helperText={errMessage === true ? "Required field" : null}
                  name="fullLegalNameOfDst"
                  label="Full Legal Name of DST"
                  value={formData.fullLegalNameOfDst}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  size="small"
                  margin="normal"
                  fullWidth
                  name="equityAvailability"
                  label="Equity Availability"
                  style={{ margin: "0px" }}
                  value={formData.equityAvailability}
                  onChange={(e) =>
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        equityAvailability: e.target.value,
                      };
                      handleSessionState(
                        newFormData,
                        highlights,
                        yields,
                        proceeds,
                        otherInfo
                      );
                      return newFormData;
                    })
                  }
                >
                  <MenuItem value="" key="">
                    Clear
                  </MenuItem>
                  {[
                    ">10 million available",
                    "<10 million available",
                    "Almost fully reserved",
                    "Taking backups",
                  ].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="numberOfProperties"
                  label="Number of Properties"
                  value={formData.numberOfProperties}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="offeringEquity"
                  label="Offering Equity"
                  value={formData.offeringEquity}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="nameOfTheSponsor"
                  label="Name of the Sponsor"
                  value={formData.nameOfTheSponsor}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="percentRetainedBySponsor"
                  label="Percent Retained by Sponsor (Outside of offering)"
                  value={formData.percentRetainedBySponsor}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="offeringDebt"
                  label="Offering Debt"
                  value={formData.offeringDebt}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="MSA"
                  label="MSA(s)"
                  value={formData.MSA}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  size="small"
                  margin="normal"
                  fullWidth
                  name="msaTier"
                  label="MSA Tier"
                  style={{ margin: "0px" }}
                  value={formData.msaTier}
                  onChange={(e) =>
                    // setFormData({ ...formData, msaTier: e.target.value })
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        msaTier: e.target.value,
                      };
                      handleSessionState(
                        newFormData,
                        highlights,
                        yields,
                        proceeds,
                        otherInfo
                      );
                      return newFormData;
                    })
                  }
                >
                  <MenuItem value="" key="">
                    Clear
                  </MenuItem>
                  {["1", "2", "3"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="totalUnitsInPortfolio"
                  label="Total Units in Portfolio"
                  value={formData.totalUnitsInPortfolio}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="loanTerm"
                  label="Loan Term"
                  value={formData.loanTerm}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="yearsPropertiesWereBuilt"
                  label="Years Properties Were Built"
                  value={formData.yearsPropertiesWereBuilt}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="interestOnlyPeriod"
                  label="I/O Period"
                  value={formData.interestOnlyPeriod}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="generalLocationRelativeToMajorMarket"
                  label="General Location Relative to Major Market"
                  value={formData.generalLocationRelativeToMajorMarket}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  select
                  fullWidth
                  size="small"
                  margin="normal"
                  name="states"
                  id="userRoles"
                  variant="outlined"
                  label="Select States"
                  SelectProps={{
                    multiple: true,
                    value: formData.states || [],
                    onChange: (e) => handleChangeStates(e),
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 250,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="" key="">
                    Clear
                  </MenuItem>
                  {stateOption?.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {`${option.state} - ${option.code}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="currentDstOccupancy"
                  label="Current DST Occupancy"
                  value={formData.currentDstOccupancy}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="loanRate"
                  label="Loan Rate"
                  value={formData.loanRate}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="dstPropertiesSector"
                  label="DST Property Sector"
                  value={formData.dstPropertiesSector}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="lender"
                  label="Lender"
                  value={formData.lender}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="dstPropertiesClass"
                  label="DST Properties Class"
                  value={formData.dstPropertiesClass}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="purchasePrice"
                  label="Purchase Price"
                  value={formData.purchasePrice}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="year1TotalDebtService"
                  label="Year 1 Total Debt Service"
                  value={formData.year1TotalDebtService}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="dstPropertiesStyle"
                  label="DST Property Style"
                  value={formData.dstPropertiesStyle}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="appraisedValue"
                  label="Appraised Value"
                  value={formData.appraisedValue}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="year1NetOperatingIncome"
                  label="Year 1 Net Operating Income"
                  value={formData.year1NetOperatingIncome}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="minimum1031Investment"
                  label="Minimum 1031 Investment"
                  value={formData.minimum1031Investment}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="rentGrowthAssumption"
                  label="Rent Growth Assumption"
                  value={formData.rentGrowthAssumption}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="year1EffectiveGrossRevenue"
                  label="Year 1 Effective Gross Revenue"
                  value={formData.year1EffectiveGrossRevenue}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="concordeApprovalDate"
                  type="date"
                  style={{ margin: "0px" }}
                  value={formData.concordeApprovalDate}
                  onChange={(e) =>
                    // setFormData({
                    //   ...formData,
                    //   concordeApprovalDate: e.target.value,
                    // })
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        concordeApprovalDate: e.target.value,
                      };
                      handleSessionState(
                        newFormData,
                        highlights,
                        yields,
                        proceeds,
                        otherInfo
                      );
                      return newFormData;
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Concorde Approval Date"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  size="small"
                  margin="normal"
                  fullWidth
                  name="fixedVariable"
                  label="Fixed/Variable"
                  style={{ margin: "0px" }}
                  value={formData.fixedVariable}
                  onChange={(e) =>
                    // setFormData({ ...formData, fixedVariable: e.target.value })
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        fixedVariable: e.target.value,
                      };
                      handleSessionState(
                        newFormData,
                        highlights,
                        yields,
                        proceeds,
                        otherInfo
                      );
                      return newFormData;
                    })
                  }
                >
                  <MenuItem value="" key="">
                    Clear
                  </MenuItem>
                  {["Fixed", "V Capped", "Variable"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"upreit"}
                  label="UPREIT"
                  style={{ margin: "0px" }}
                  value={formData.upreit}
                  onChange={(e) =>
                    // setFormData({ ...formData, upreit: e.target.value })
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        upreit: e.target.value,
                      };
                      handleSessionState(
                        newFormData,
                        highlights,
                        yields,
                        proceeds,
                        otherInfo
                      );
                      return newFormData;
                    })
                  }
                >
                  <MenuItem value="" key="">
                    Clear
                  </MenuItem>
                  {[
                    "Mandatory",
                    "Option - Full",
                    "Option - Limited",
                    "Unclear",
                    "None",
                  ].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  size="small"
                  margin="normal"
                  fullWidth
                  name={"BC"}
                  label="506 B or C"
                  style={{ margin: "0px", marginTop: "10px" }}
                  value={formData.BC}
                  onChange={(e) =>
                    // setFormData({ ...formData, BC: e.target.value })
                    setFormData((prevFormData) => {
                      const newFormData = {
                        ...prevFormData,
                        BC: e.target.value,
                      };
                      handleSessionState(
                        newFormData,
                        highlights,
                        yields,
                        proceeds,
                        otherInfo
                      );
                      return newFormData;
                    })
                  }
                >
                  <MenuItem value="" key="">
                    Clear
                  </MenuItem>
                  {["None", "506 B", "506 C"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  margin="dense"
                  fullWidth
                  name="sponsorBullet1"
                  label="Sponsor Bullet Point #1"
                  value={formData.sponsorBullet1}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  margin="dense"
                  fullWidth
                  name="sponsorBullet2"
                  label="Sponsor Bullet Point #2"
                  value={formData.sponsorBullet2}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  margin="dense"
                  fullWidth
                  name="sponsorBullet3"
                  label="Sponsor Bullet Point #3"
                  value={formData.sponsorBullet3}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  margin="dense"
                  fullWidth
                  name="uniqueDealRisk1"
                  label="Unique Deal Risk #1"
                  value={formData.uniqueDealRisk1}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  margin="dense"
                  fullWidth
                  name="uniqueDealRisk2"
                  label="Unique Deal Risk #2"
                  value={formData.uniqueDealRisk2}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  margin="dense"
                  fullWidth
                  name="uniqueDealRisk3"
                  label="Unique Deal Risk #3"
                  value={formData.uniqueDealRisk3}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  margin="dense"
                  fullWidth
                  name="uniqueDealRisk4"
                  label="Unique Deal Risk #4"
                  value={formData.uniqueDealRisk4}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  margin="dense"
                  fullWidth
                  name="uniqueDealRisk5"
                  label="Unique Deal Risk #5"
                  value={formData.uniqueDealRisk5}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <h3 style={{ textAlign: "left", width: "100%" }}>
              DST Market and Property Highlights
            </h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={highlights.marketGrowthPoint1}
                  name="marketGrowthPoint1"
                  label="Market Growth Bullet Point #1"
                  margin="normal"
                  fullWidth
                  onChange={handleChangeHighlights}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={highlights.marketGrowthPoint2}
                  name="marketGrowthPoint2"
                  label="Market Growth Bullet Point #2"
                  margin="normal"
                  fullWidth
                  onChange={handleChangeHighlights}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={highlights.marketGrowthPoint3}
                  name="marketGrowthPoint3"
                  label="Market Growth Bullet Point #3"
                  margin="normal"
                  fullWidth
                  onChange={handleChangeHighlights}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={highlights.marketEmploymentPoint}
                  name="marketEmploymentPoint"
                  label="Market Employment Bullet Point"
                  margin="normal"
                  fullWidth
                  onChange={handleChangeHighlights}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={highlights.marketEconomicPoint}
                  name="marketEconomicPoint"
                  label="Market Economic Bullet Point"
                  margin="normal"
                  fullWidth
                  onChange={handleChangeHighlights}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={highlights.propertyLocationPoint1}
                  name="propertyLocationPoint1"
                  label="Property Location Bullet Point #1"
                  margin="normal"
                  fullWidth
                  onChange={handleChangeHighlights}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={highlights.propertyLocationPoint2}
                  name="propertyLocationPoint2"
                  label="Property Location Bullet Point #2"
                  margin="normal"
                  fullWidth
                  onChange={handleChangeHighlights}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={highlights.propertyLocationPoint3}
                  name="propertyLocationPoint3"
                  label="Property Location Bullet Point #3"
                  margin="normal"
                  fullWidth
                  onChange={handleChangeHighlights}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={highlights.propertyFeaturePoint1}
                  name="propertyFeaturePoint1"
                  label="Property Features Bullet Point #1"
                  margin="normal"
                  fullWidth
                  onChange={handleChangeHighlights}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={highlights.propertyFeaturePoint2}
                  name="propertyFeaturePoint2"
                  label="Property Features Bullet Point #2"
                  margin="normal"
                  fullWidth
                  onChange={handleChangeHighlights}
                />
              </Grid>
            </Grid>
            <h3 style={{ textAlign: "left", width: "100%" }}>
              Pro Forma Yields - Repeat Last Year if Less than 10
            </h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={yields.year1Yield}
                  margin="normal"
                  fullWidth
                  name="year1Yield"
                  label="Year 1 Yield"
                  onChange={handleChangeYield}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={yields.year2Yield}
                  margin="normal"
                  fullWidth
                  name="year2Yield"
                  label="Year 2 Yield"
                  onChange={handleChangeYield}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={yields.year3Yield}
                  margin="normal"
                  fullWidth
                  name="year3Yield"
                  label="Year 3 Yield"
                  onChange={handleChangeYield}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={yields.year4Yield}
                  margin="normal"
                  fullWidth
                  name="year4Yield"
                  label="Year 4 Yield"
                  onChange={handleChangeYield}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={yields.year5Yield}
                  margin="normal"
                  fullWidth
                  name="year5Yield"
                  label="Year 5 Yield"
                  onChange={handleChangeYield}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={yields.year6Yield}
                  margin="normal"
                  fullWidth
                  name="year6Yield"
                  label="Year 6 Yield"
                  onChange={handleChangeYield}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={yields.year7Yield}
                  margin="normal"
                  fullWidth
                  name="year7Yield"
                  label="Year 7 Yield"
                  onChange={handleChangeYield}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={yields.year8Yield}
                  margin="normal"
                  fullWidth
                  name="year8Yield"
                  label="Year 8 Yield"
                  onChange={handleChangeYield}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={yields.year9Yield}
                  margin="normal"
                  fullWidth
                  name="year9Yield"
                  label="Year 9 Yield"
                  onChange={handleChangeYield}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={yields.year10Yield}
                  margin="normal"
                  fullWidth
                  name="year10Yield"
                  label="Year 10 Yield"
                  onChange={handleChangeYield}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={5} className="Grid-Gap">
                <h3 style={{ textAlign: "left", width: "100%" }}>
                  Use Of Proceeds
                </h3>
              </Grid>
              <Grid item xs={3} className="Grid-Gap">
                <h3 style={{ textAlign: "right", width: "100%" }}>
                  Rep Commission:
                </h3>
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  key="repCommission"
                  size="small"
                  value={formData.repCommission}
                  margin="normal"
                  fullWidth
                  name="repCommission"
                  label="Rep Commission"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <h3
              style={{
                textAlign: "left",
                width: "100%",
                fontWeight: 500,
                marginBottom: "0px",
                fontSize: "16px",
              }}
            >
              Sales Load - PPM numbers may not neatly fit these categories
            </h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  key="OOCosts"
                  size="small"
                  value={proceeds.OOCosts}
                  margin="normal"
                  fullWidth
                  name="OOCosts"
                  label="O & O Costs"
                  onChange={handleChangeProceeds}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  key="brokerDealerCommissions"
                  size="small"
                  value={proceeds.brokerDealerCommissions}
                  margin="normal"
                  fullWidth
                  name="brokerDealerCommissions"
                  label="Broker-Dealer Commissions/DD Costs"
                  onChange={handleChangeProceeds}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  key="wholesalingCosts"
                  size="small"
                  value={proceeds.wholesalingCosts}
                  margin="normal"
                  fullWidth
                  name="wholesalingCosts"
                  label="Managing BD/Wholesaling Costs"
                  onChange={handleChangeProceeds}
                />
              </Grid>
            </Grid>
            <h3
              style={{
                textAlign: "left",
                width: "100%",
                fontWeight: 500,
                marginBottom: "0px",
                fontSize: "16px",
              }}
            >
              Sponsor Fee
            </h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  key="transactionCommission"
                  size="small"
                  value={proceeds.transactionCommission}
                  margin="normal"
                  fullWidth
                  name="transactionCommission"
                  label="Acquisition Fee or Transaction Fees"
                  onChange={handleChangeProceeds}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  key="financingFee"
                  size="small"
                  value={proceeds.financingFee}
                  margin="normal"
                  fullWidth
                  name="financingFee"
                  label="Closing/Financing Fees"
                  onChange={handleChangeProceeds}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  key="otherFees"
                  size="small"
                  value={proceeds.otherFees}
                  margin="normal"
                  fullWidth
                  name="otherFees"
                  label="Other Fees"
                  onChange={handleChangeProceeds}
                />
              </Grid>
            </Grid>
            <h3
              style={{
                textAlign: "left",
                width: "100%",
                fontWeight: 500,
                marginBottom: "0px",
                fontSize: "16px",
              }}
            >
              Costs & Reserves - Only include true third-party costs below -
              otherwise include with "Other Fees" above
            </h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  key="thirdPartyClosingCosts"
                  size="small"
                  value={proceeds.thirdPartyClosingCosts}
                  margin="normal"
                  fullWidth
                  name="thirdPartyClosingCosts"
                  label="Third-Party Closing Costs"
                  onChange={handleChangeProceeds}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  key="totalDstReserves"
                  size="small"
                  value={proceeds.totalDstReserves}
                  margin="normal"
                  fullWidth
                  name="totalDstReserves"
                  label="Total DST Reserves"
                  onChange={handleChangeProceeds}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  key="year5ReservesBalance"
                  size="small"
                  value={proceeds.year5ReservesBalance}
                  margin="normal"
                  fullWidth
                  name="year5ReservesBalance"
                  label="Year 5 Reserves Balance"
                  onChange={handleChangeProceeds}
                />
              </Grid>
            </Grid>
            <h3 style={{ textAlign: "left", width: "100%" }}>Other Info</h3>
            <Grid container sx={{ py: 2 }} spacing={2}>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={otherInfo.useOfProceedsPage}
                  margin="normal"
                  fullWidth
                  name="useOfProceedsPage"
                  label="PPM Use of Proceeds Page"
                  onChange={handleChangeOtherInfo}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={otherInfo.cashFlowPage}
                  margin="normal"
                  fullWidth
                  name="cashFlowPage"
                  label="PPM Cash Flow Page"
                  onChange={handleChangeOtherInfo}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={otherInfo.pppRiskFactorsPage}
                  margin="normal"
                  fullWidth
                  name="pppRiskFactorsPage"
                  label="PPM Risk Factors Page"
                  onChange={handleChangeOtherInfo}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={otherInfo.assumptionsPage}
                  margin="normal"
                  fullWidth
                  name="assumptionsPage"
                  label="PPM Assumptions Page"
                  onChange={handleChangeOtherInfo}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={otherInfo.averageRemainingLeaseDuration}
                  margin="normal"
                  fullWidth
                  name="averageRemainingLeaseDuration"
                  label="Average Remaining Lease Duration"
                  onChange={handleChangeOtherInfo}
                />
              </Grid>
              <Grid item xs={4} className="Grid-Gap">
                <TextField
                  size="small"
                  value={otherInfo.dispositionFee}
                  margin="normal"
                  fullWidth
                  name="dispositionFee"
                  label="Disposition Fee"
                  onChange={handleChangeOtherInfo}
                />
              </Grid>
              <Grid item xs={6} className="Grid-Gap">
                <TextField
                  size="small"
                  value={otherInfo.dstOfferingStrengthAndOppertunities}
                  margin="normal"
                  fullWidth
                  multiline
                  rows={4}
                  style={{ height: "100px" }}
                  name="dstOfferingStrengthAndOppertunities"
                  label="DST Offering Strengths and Opportunities"
                  onChange={handleChangeOtherInfo}
                />
              </Grid>
              <Grid item xs={6} className="Grid-Gap">
                <TextField
                  size="small"
                  value={otherInfo.dstOfferingWeaknessesAndThreats}
                  margin="normal"
                  fullWidth
                  multiline
                  rows={4}
                  style={{ height: "100px" }}
                  name="dstOfferingWeaknessesAndThreats"
                  label="DST Offering Weaknesses and Threats"
                  onChange={handleChangeOtherInfo}
                />
              </Grid>
              <Grid item xs={6} className="Grid-Gap">
                <TextField
                  size="small"
                  value={otherInfo.generalNotes}
                  margin="normal"
                  fullWidth
                  name="generalNotes"
                  label="General Notes"
                  multiline
                  rows={4}
                  style={{ height: "100px" }}
                  onChange={handleChangeOtherInfo}
                />
              </Grid>
              <Grid item xs={6} className="Grid-Gap">
                <TextField
                  size="small"
                  value={otherInfo.keyOverallMarketBulletPoints}
                  margin="normal"
                  fullWidth
                  multiline
                  rows={4}
                  style={{ height: "100px" }}
                  name="keyOverallMarketBulletPoints"
                  label="Key Overall Market Bullet Points"
                  onChange={handleChangeOtherInfo}
                />
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  marginTop: "1.5rem",
                }}
              >
                {outputNotify ? (
                  <Alert severity="error">
                    {`The difference of Total DST Capital and Total Use of
                    Proceeds equals ${Number(
                      numericalDifference
                    ).toLocaleString("en-US")}. This needs to equal zero.
                    Output cannot be generated.`}
                  </Alert>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  columnGap: "10px",
                  justifyContent: "end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {id ? null : (
                  <Button
                    onClick={handleDraft}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                  >
                    Save Draft
                  </Button>
                )}
                <Button
                  onClick={handleAddData}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  {id ? "Update" : "Publish"}
                </Button>
                <Button
                  onClick={handleReset}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Reset
                </Button>

                {id ? (
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    onClick={handleOutput}
                  >
                    Output
                  </Button>
                ) : null}
              </div>
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
    </Card>
  );
}
