import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  Tooltip,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Button } from "@material-ui/core";

import { alpha, makeStyles, styled } from "@material-ui/core/styles";
import {
  LandscapeOrientation,
  PortraitOrientation,
} from "../../utills/landscapeOrientation";
import { pink } from "@mui/material/colors";
import Chart from "react-google-charts";
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from "recharts";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import tagManagerEvents from "../../utills/GtmUtils";
import googleTagTriggers from "../../utills/google-tag-triggers.json";

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "10px 0px 10px 16px",
    },
    scenario1: {
      color: "blue",
    },
    scenario2: {
      color: "#f0e68c", // Skin color tone (adjust if needed)
    },
  },
});

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

const CashOutAnalysisReport = () => {
  const classes = useStyles();
  const componentRef = useRef();
  const companyName = localStorage.getItem("companyName");
  const { state } = useLocation();
  const [isConcordeBroker, setIsConcordeBroker] = useState(false);

  const { scenario1, scenario2, name, rows, tableData1, tableData2 } = state;

  useEffect(() => {
    fetchBrokerDealers();
    tagManagerEvents(googleTagTriggers.cashOutAnalysis);
  }, []);
  const fetchBrokerDealers = async () => {
    try {
      const params = {
        headers: { "Content-Type": "application/json" },
      };
      const res = await API.get("dynamoRW", "/1031_broker_Dealer", params);
      if (res.items) {
        const activeBrokers = res.items.filter(
          (broker) =>
            broker.status !== false && broker.name.toLowerCase() === "concorde"
        );
        setIsConcordeBroker(activeBrokers.length > 0);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Error fetching broker dealers! ${error.message}`);
    }
  };

  const [portraitLayout, setPortraitLayout] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          columnGap: "1.5rem",
          marginTop: "2rem",
          marginRight: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Typography
            style={{ color: portraitLayout === false ? "#1565c0" : "#333" }}
          >
            Portrait
          </Typography>
          <PinkSwitch
            checked={!portraitLayout}
            onChange={() => setPortraitLayout(!portraitLayout)}
          />
          <Typography
            style={{
              color: portraitLayout === true ? "#1565c0" : "#333",
            }}
          >
            Landscape
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {portraitLayout ? <PortraitOrientation /> : <LandscapeOrientation />}
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                }}
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        </div>
      </div>
      <div style={{ margin: "0rem 1rem" }} ref={componentRef}>
        <div>
          <div>
            <Grid style={{ alignItems: "center" }} container>
              <Grid item xs={5} className="Grid-Gap">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p>Prepared for: </p>{" "}
                  <b style={{ marginLeft: "0.5rem", fontSize: "1.3rem" }}>
                    {name}
                  </b>
                </div>
              </Grid>
              <Grid item xs={2} className="Grid-Gap"></Grid>
            </Grid>
          </div>
        </div>
        <br />

        <div style={{ margin: "1rem 0rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={6} className="Grid-Gap">
              <p style={{ margin: "0px", fontSize: "0.7rem" }}>
                This hypothetical example illustrates the mathematical
                difference between two scenarios. The first scenario involves
                paying taxes on a hypothetical capital gain, then investing the
                balance. The second scenario involves deferring the potential
                capital gains tax and investing the pre-tax gross amount.
                Hypothetical assumptions are made for scenario one and two
                regarding current cash flow and growth. The cash flow and growth
                percentages are hypothetical and not guaranteed.
                <br />
                <br />
                <div>
                  <Grid container>
                    <Grid item xs={6}>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          backgroundColor: "#203864",
                          color: "#fff",
                          padding: "0.05px",
                          margin: 0,
                        }}
                      >
                        <h2>Scenario #1</h2>
                      </div>
                      <TableContainer component={Paper}>
                        <Table
                          style={{ width: "100%", border: "1px solid black" }}
                        >
                          <TableHead
                            sx={{
                              backgroundColor: "#e1f2fa",
                              color: "black",
                            }}
                          >
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "black",
                                  borderRight: "1px solid black",
                                }}
                              >
                                Equity
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "black",
                                  borderRight: "1px solid black",
                                }}
                              >
                                Cash Flow
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "black",
                                  borderRight: "1px solid black",
                                }}
                              >
                                Growth
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody sx={{ border: "1px solid black" }}>
                            <TableRow>
                              <TableCell
                                sx={{
                                  borderRight: "1px solid black",
                                }}
                              >
                                {scenario1.equity}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderRight: "1px solid black",
                                }}
                              >
                                {scenario1.cashFlow}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderRight: "1px solid black",
                                }}
                              >
                                {scenario1.growth}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    <Grid item xs={6}>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          backgroundColor: "#C55A11",
                          color: "#fff",
                          borderBottom: "1px solid #000",
                          padding: "0.05px",
                        }}
                      >
                        <h2>Scenario #2</h2>
                      </div>
                      <TableContainer component={Paper}>
                        <Table style={{ width: "100%", margin: "0px" }}>
                          <TableHead
                            sx={{ backgroundColor: "#e1f2fa", color: "white" }}
                          >
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "black",
                                  borderRight: "1px solid black",
                                }}
                              >
                                Equity
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "black",
                                  borderRight: "1px solid black",
                                }}
                              >
                                Cash Flow
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "black",
                                  borderRight: "1px solid black",
                                }}
                              >
                                Growth
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{ borderBottom: "1px solid black" }}>
                              <TableCell
                                sx={{
                                  borderRight: "1px solid black",
                                }}
                              >
                                {scenario2.equity}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderRight: "1px solid black",
                                }}
                              >
                                {scenario2.cashFlow}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderRight: "1px solid black",
                                }}
                              >
                                {scenario2.growth}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </div>
                <br />
                <div>
                  <Box
                    style={{
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      backgroundColor: "#203864",
                      alignSelf: "center",
                      color: "white",
                      padding: "0.05px ",
                    }}
                  >
                    <h3
                      style={{
                        color: "white",
                        alignSelf: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      Scenario #1
                    </h3>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{
                        border: "1px solid #f3f3f3",
                      }}
                    >
                      <TableBody>
                        <TableRow
                          sx={{
                            borderBottom: "1px solid #f3f3f3",
                            backgroundColor: "#e1f2fa",
                          }}
                        >
                          <TableCell
                            sx={{ borderRight: "1px solid #777", width: "50%" }}
                          >
                            Income
                          </TableCell>
                          <TableCell align="right">
                            {scenario1.income.toLocaleString()}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            borderBottom: "1px solid #f3f3f3",
                          }}
                        >
                          <TableCell
                            sx={{ borderRight: "1px solid gray", width: "50%" }}
                          >
                            Future Value
                          </TableCell>
                          <TableCell align="right">
                            {scenario1.futureValue.toLocaleString()}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            borderBottom: "1px solid #f3f3f3",
                            backgroundColor: "#e1f2fa",
                          }}
                        >
                          <TableCell
                            sx={{
                              borderRight: "1px solid gray",
                              fontWeight: "bold",
                              width: "50%",
                            }}
                          >
                            10-Yr Total
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="right">
                            {scenario1.tenYearTotal.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <br />
                  <Box
                    alignItems="center"
                    textAlign="center"
                    style={{
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                      backgroundColor: "#C55A11",
                      alignSelf: "center",
                      color: "white",
                      padding: "0.05px",
                    }}
                  >
                    <h3
                      style={{
                        color: "white",
                        alignSelf: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      Scenario #2
                    </h3>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow
                          sx={{
                            borderBottom: "1px solid #f3f3f3",
                            backgroundColor: "#e1f2fa",
                          }}
                        >
                          <TableCell
                            sx={{ borderRight: "1px solid gray", width: "50%" }}
                          >
                            Income
                          </TableCell>
                          <TableCell align="right">
                            {scenario2.income.toLocaleString()}
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ borderBottom: "1px solid #f3f3f3" }}>
                          <TableCell
                            sx={{ borderRight: "1px solid gray", width: "50%" }}
                          >
                            Future Value
                          </TableCell>
                          <TableCell align="right">
                            {scenario2.futureValue.toLocaleString()}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            borderBottom: "1px solid #f3f3f3",
                            backgroundColor: "#e1f2fa",
                          }}
                        >
                          <TableCell
                            sx={{
                              borderRight: "1px solid gray",
                              fontWeight: "bold",
                              width: "50%",
                            }}
                          >
                            10-Yr Total
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="right">
                            {scenario2.tenYearTotal.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <br />
                <br />
              </p>
            </Grid>
            <Grid item xs={5} className="Grid-Gap">
              <p
                style={{
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: -50,
                }}
              >
                See notes on next page
              </p>
              <Grid item xs={12} className="Grid-Gap">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "center",
                    marginTop: "4.5rem",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LineChart width={600} height={580} data={rows}>
                    <CartesianGrid strokeDasharray="5 5" />
                    <XAxis dataKey="year" />
                    <YAxis width={120} />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="scenario1"
                      stroke="#203864"
                    />{" "}
                    {/* Assuming 'total1' is the key for Y-axis data in table */}
                    <Line
                      type="monotone"
                      dataKey="scenario2"
                      stroke="#C55A11"
                    />{" "}
                  </LineChart>

                  <div
                    style={{
                      alignSelf: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      align="center"
                      component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      sx={{
                        pt: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="/AI-logo-black.png"
                        style={{ height: "25px" }}
                      />
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <p style={{ fontSize: "0.7rem" }}>
            <Grid container>
              <Grid item xs={12}>
                Because investor situations and objectives may vary, this
                information is not intended to indicate suitability or a
                recommendation for any individual investor.
                <br />
                <br />
                This is for informational purposes only, does not constitute
                individual investment advice, and should not be relied upon as
                tax or legal advice. Please consult the appropriate professional
                regarding your individual circumstances.
                <br />
                <br />
                {isConcordeBroker && (
                  <i>
                    {" "}
                    Securities offered through Concorde Investment Services, LLC
                    (CIS), member FINRA/SIPC. Advisory services offered through
                    Concorde Asset Management, LLC (CAM), an SEC registered
                    investment adviser.
                  </i>
                )}
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </p>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <div>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", textAlign: "center" }}
                >
                  Scenario #1
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "black" }}>
                        <TableCell sx={{ color: "white" }} align="center">
                          Year
                        </TableCell>
                        <TableCell sx={{ color: "white" }} align="center">
                          Beginning
                        </TableCell>
                        <TableCell sx={{ color: "white" }} align="center">
                          Growth
                        </TableCell>
                        <TableCell sx={{ color: "white" }} align="center">
                          End
                        </TableCell>
                        <TableCell sx={{ color: "white" }} align="center">
                          Cash Flow
                        </TableCell>
                        <TableCell sx={{ color: "white" }} align="center">
                          Total
                        </TableCell>
                        {/* Add more headers if necessary */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData1.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="right">{row.year}</TableCell>
                          <TableCell align="right">
                            {row.beg.toLocaleString()}
                          </TableCell>
                          <TableCell align="right">{row.growth}</TableCell>
                          <TableCell align="right">
                            {row.end.toLocaleString()}
                          </TableCell>
                          <TableCell align="right">
                            {row.cf.toLocaleString()}
                          </TableCell>
                          <TableCell align="right">
                            {row.total.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", textAlign: "center" }}
                >
                  Scenario #2
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "black" }}>
                        <TableCell sx={{ color: "white" }} align="center">
                          Year
                        </TableCell>
                        <TableCell sx={{ color: "white" }} align="center">
                          Beginning
                        </TableCell>
                        <TableCell sx={{ color: "white" }} align="center">
                          Growth
                        </TableCell>
                        <TableCell sx={{ color: "white" }} align="center">
                          End
                        </TableCell>
                        <TableCell sx={{ color: "white" }} align="center">
                          Cash Flow
                        </TableCell>
                        <TableCell sx={{ color: "white" }} align="center">
                          Total
                        </TableCell>
                        {/* Add more headers if necessary */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData2.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="right">{row.year}</TableCell>
                          <TableCell align="right">
                            {row.beg.toLocaleString()}
                          </TableCell>
                          <TableCell align="right">{row.growth}</TableCell>
                          <TableCell align="right">
                            {row.end.toLocaleString()}
                          </TableCell>
                          <TableCell align="right">
                            {row.cf.toLocaleString()}
                          </TableCell>
                          <TableCell align="right">
                            {row.total2.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default CashOutAnalysisReport;
