import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import MyContext from "../context/appContext";
import { Storage, API } from "aws-amplify";
import { toast } from "react-toastify";
import SearchIcon from "@material-ui/icons/Search";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import tagManagerEvents from "../utills/GtmUtils";
import googleTagTriggers from "../utills/google-tag-triggers.json";
const style = {
  position: "absolute",
  top: "15%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const OfferingLibrary = () => {
  const appContext = useContext(MyContext);
  const [open, setOpen] = React.useState(false);
  const [pdfURLs, setPdfURLs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTextByDst, setSearchTextByDst] = useState("");
  const [rows, setRows] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedDeleteKey, setSelectedDeleteKey] = useState(null);
  const [dsts, setDsts] = useState([]);
  const role = localStorage.getItem("role");

  useEffect(() => {
    tagManagerEvents(googleTagTriggers.offeringSummary);
    getDsts();
  }, []);

  useEffect(() => {
    if (dsts?.length > 0) {
      fetchData();
    }
  }, [dsts]);

  useEffect(() => {
    const filteredRows = pdfURLs.filter((file) => {
      const dst = file.key || "";
      return dst.toLowerCase().includes(searchTextByDst.toLowerCase());
    });
    setRows(
      filteredRows?.map((pdf) => ({
        id: pdf.key,
        dst: pdf.key.replace(/\.pdf$/, ""),
      }))
    );
  }, [pdfURLs, searchText, searchTextByDst]);

  const handleSearchByDst = (event) => {
    const query = event.target.value;
    setSearchTextByDst(query);
  };

  const fetchData = async () => {
    try {
      appContext.updateState("loading", true);
      Storage.configure({
        AWSS3: {
          bucket: process.env.REACT_APP_S3_BUCKET,
          region: process.env.REACT_APP_LAMBDA_REGION,
        },
      });
      const result = await Storage.list("", { level: "public" });
      const pdfFiles = result.filter((item) => item.key.endsWith(".pdf"));
      const matchedDsts = [];

      pdfFiles.forEach((pdfFile) => {
        const matchingDst = dsts.find(
          (dstItem) => `${dstItem}.pdf` === pdfFile.key
        );
        if (matchingDst) {
          matchedDsts.push({ key: `${matchingDst}.pdf` });
        }
      });
      const matchedKeys = matchedDsts.map((item) => item.key);

      const filteredPdfFiles = pdfFiles.filter((item) =>
        matchedKeys.includes(item.key)
      );
      const pdfURLss = await Promise.all(
        filteredPdfFiles.map(async (pdfFile) => {
          const fileName = pdfFile.key;
          const url = await Storage.get(pdfFile.key, { level: "public" });
          return {
            key: fileName,
            url,
          };
        })
      );
      pdfURLss.sort((a, b) =>
        a.key.localeCompare(b.key, undefined, { sensitivity: "base" })
      );

      setPdfURLs(pdfURLss);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.error("Error retrieving PDFs:", error);
    }
  };

  const downloadOriginalPdf = async (pdfKey) => {
    try {
      const pdf = pdfURLs.find(
        (item) => item.key.replace(/\.pdf$/, "") === pdfKey
      );
      if (pdf) {
        const response = await fetch(pdf.url);
        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = pdf.key;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      appContext.updateState("loading", false);
      console.error(`Error downloading PDF for ${pdfKey}:`, error);
    }
  };

  const deletePdf = async (pdfKey) => {
    try {
      appContext.updateState("loading", true);
      setOpen(false);
      const keyWithoutExtension = pdfKey.replace(/\.pdf$/, "");
      await Storage.remove(`${keyWithoutExtension}.pdf`, { level: "public" });

      setPdfURLs((prevPdfURLs) =>
        prevPdfURLs.filter(
          (pdf) => pdf.key.replace(/\.pdf$/, "") !== keyWithoutExtension
        )
      );
      toast.success("PDF deleted successfully!");
    } catch (error) {
      console.error(`Error deleting PDF for ${pdfKey}:`, error);
      toast.error("Error deleting PDF.");
    } finally {
      appContext.updateState("loading", false);
      await fetchData();
    }
  };

  const getDsts = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: "id-name",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);

      const userRole = localStorage.getItem("userRole");
      const userEmail = localStorage.getItem("userEmail");

      // Fetch user's assigned DSTs
      const userRes = await API.get(
        "dynamoRW",
        `/notifications?tablename=approvedUsers-dev`
      );

      const currentUser = userRes.Items.find(
        (user) => user.userEmail === userEmail
      );

      // Get all DSTs assigned to any of user's broker dealers
      let allAssignedDsts = [];
      if (currentUser?.brokerDealerIds?.length > 0) {
        // Get all users that share any of the current user's broker dealers
        const relatedUsers = userRes.Items.filter((user) =>
          user.brokerDealerIds?.some((id) =>
            currentUser.brokerDealerIds.includes(id)
          )
        );

        // Combine all assigned DSTs from related users
        allAssignedDsts = [
          ...new Set(relatedUsers.flatMap((user) => user.assignedDsts || [])),
        ];
      }

      let filteredDsts = res;

      // Show filtered DSTs only if:
      // 1. User is not Super Admin
      // 2. User has broker dealers with assigned DSTs
      if (userRole !== "Super Admin" && allAssignedDsts.length > 0) {
        filteredDsts = res.filter((dst) => allAssignedDsts.includes(dst.id));
      }
      // If no DSTs are assigned or user is Super Admin, show all DSTs

      // Format numbers
      filteredDsts.forEach((item) => {
        item.basicInfo.offeringEquity = parseFloat(
          item.basicInfo.offeringEquity
        ).toLocaleString();
        item.basicInfo.offeringDebt = parseFloat(
          item.basicInfo.offeringDebt
        ).toLocaleString();
      });

      // Sort by DST name
      filteredDsts.sort((a, b) =>
        a.basicInfo.fullLegalNameOfDst.localeCompare(
          b.basicInfo.fullLegalNameOfDst
        )
      );

      // Extract full names for state
      const fullNames = filteredDsts.map(
        (item) => item.basicInfo.fullLegalNameOfDst
      );
      setDsts(fullNames);
    } catch (error) {
      console.log(error.message);
      toast.error(`Error fetching DSTs! ${error.message}`);
    } finally {
      appContext.updateState("loading", false);
    }
  };

  const handleDeleteRow = (pdfKey) => {
    setSelectedDeleteKey(pdfKey);
    setConfirmDeleteOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setConfirmDeleteOpen(false);
    setSelectedDeleteKey(null);
  };

  const columns = [
    {
      field: "dst",
      headerName: "DST Name",
      headerAlign: "left",
      align: "left",
      minWidth: 400,
      sortable: true,
    },
    {
      field: "download",
      headerName: "Download",
      headerAlign: "center",
      align: "center",
      sortable: false,
      minWidth: 300,
      renderCell: (params) => (
        <DownloadIcon
          onClick={() => downloadOriginalPdf(params.row.dst)}
          style={{ cursor: "pointer", color: "#1976d2" }}
        />
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 300,
      renderCell: (params) => (
        <>
          {role === "Super Admin" && (
            <DeleteIcon
              onClick={() => handleDeleteRow(params.row.dst)}
              style={{
                cursor: "pointer",
                color: "#e54b45",
              }}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: "0px 20px" }}>
      <h2>Offering Summary Library</h2>
      <div style={{ padding: "5px 5px" }}>
        <Box sx={{ height: "800px", width: 1 }}>
          <div>
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <TextField
                size="small"
                margin="normal"
                fullWidth
                style={{
                  marginBottom: "1rem",
                  width: "20%",
                  backgroundColor: "#fff",
                }}
                type="text"
                placeholder="Search by DST"
                value={searchTextByDst}
                onChange={(event) => handleSearchByDst(event)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <DataGrid
                key={rows.length}
                rows={rows}
                columns={columns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                getRowId={(row) => row.dst}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: false,
                  },
                }}
                disableRowSelectionOnClick
                initialState={{
                  ...rows.initialState,
                  pagination: {
                    ...rows.initialState?.pagination,
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
              />
            </div>
            <Modal
              open={confirmDeleteOpen}
              onClose={handleCloseDeleteModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" component="h2">
                  {`Are you sure you want to delete `}
                  <b>{`${selectedDeleteKey} ?`}</b>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <Button onClick={handleCloseDeleteModal} variant="text">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      deletePdf(selectedDeleteKey);
                      handleCloseDeleteModal();
                    }}
                    type="submit"
                    variant="text"
                  >
                    Confirm
                  </Button>
                </Typography>
              </Box>
            </Modal>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default OfferingLibrary;
