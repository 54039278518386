import React, { useRef, useState, useContext, useEffect } from "react";
import DstDetailPrint from "../components/printScreens/dstDetailPrint";
import DstDetailPrint1 from "../components/printScreens/dstDetailPrint1";
import DstDetailPrint2 from "../components/printScreens/dstDetailPrint2";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Button, Switch, Typography } from "@mui/material";
import MyContext from "../context/appContext";
import { API, Storage } from "aws-amplify";
import { toast } from "react-toastify";
import {
  LandscapeOrientation,
  PortraitOrientation,
} from "../utills/landscapeOrientation.js";
import { pink } from "@mui/material/colors";
import { alpha, makeStyles, styled } from "@material-ui/core/styles";
import DstDetailPrint3 from "../components/printScreens/dstDetailPrint3";

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "10px 0px 10px 16px",
    },
  },
});

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

const DSTPrintView = () => {
  let { id } = useParams();
  const [findDst, setFindDst] = useState();
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const appContext = useContext(MyContext);
  const [image, setImage] = useState("");
  const [lastDynamicValues, setLastDynamicValues] = useState({});
  const [lastDynamicValuesYields, setLastDynamicValuesYields] = useState({});
  const [lastDynamicValuesProperty, setLastDynamicValuesProperty] = useState(
    {}
  );
  const [lastDynamicValuesProceeds, setLastDynamicValuesProceeds] = useState(
    {}
  );
  const [lastDynamicValuesOtherInfo, setLastDynamicValuesOtherInfo] = useState(
    {}
  );
  const [portraitLayout, setPortraitLayout] = useState(false);

  useEffect(() => {
    if (id) {
      getDstById();
    }
  }, [id]);

  useEffect(() => {
    fetchImage();
  }, [findDst]);

  const getDstById = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: id,
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      console.log("RESPONSE::", res);
      setFindDst(res.Item);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No DST Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const fetchImage = async () => {
    try {
      const imageUrl = await Storage.get(findDst.featureImage);
      setImage(imageUrl);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    const dynamicValues = {
      offeringEquity: findDst?.basicInfo?.offeringEquity,
      offeringDebt: findDst?.basicInfo?.offeringDebt,
      totalUnitsInPortfolio: findDst?.basicInfo?.totalUnitsInPortfolio,
      appraisedValue: findDst?.basicInfo?.appraisedValue,
      currentDstOccupancy: findDst?.basicInfo?.currentDstOccupancy,
      loanRate: findDst?.basicInfo?.loanRate,
      minimum1031Investment: findDst?.basicInfo?.minimum1031Investment,
      percentRetainedBySponsor: findDst?.basicInfo?.percentRetainedBySponsor,
      purchasePrice: findDst?.basicInfo?.purchasePrice,
      rentGrowthAssumption: findDst?.basicInfo?.rentGrowthAssumption,
      totalUnitsInPortfolio: findDst?.basicInfo?.totalUnitsInPortfolio,
      year1EffectiveGrossRevenue:
        findDst?.basicInfo?.year1EffectiveGrossRevenue,
      year1NetOperatingIncome: findDst?.basicInfo?.year1NetOperatingIncome,
      year1TotalDebtService: findDst?.basicInfo?.year1TotalDebtService,
    };

    const dynamicValuesYields = {
      year1Yield: findDst?.yields?.year1Yield,
      year2Yield: findDst?.yields?.year2Yield,
      year3Yield: findDst?.yields?.year3Yield,
      year4Yield: findDst?.yields?.year4Yield,
      year5Yield: findDst?.yields?.year5Yield,
      year6Yield: findDst?.yields?.year6Yield,
      year7Yield: findDst?.yields?.year7Yield,
      year8Yield: findDst?.yields?.year8Yield,
      year9Yield: findDst?.yields?.year9Yield,
      year10Yield: findDst?.yields?.year10Yield,
    };

    const dynamicValuesProperty = findDst?.property?.map((propertyItem) => ({
      NumberOfBuildings: propertyItem.NumberOfBuildings,
      otherUnits: propertyItem.otherUnits,
      oneBedrooms: propertyItem.oneBedrooms,
      twoBedrooms: propertyItem.twoBedrooms,
      threeBedrooms: propertyItem.threeBedrooms,
      acres: propertyItem.acres,
      currentOccup: propertyItem.currentOccup,
      // msaProjectedPopGrowth: propertyItem.msaProjectedPopGrowth,
      // msaProjectedRentGrowth: propertyItem.msaProjectedRentGrowth,
      // msaRecentPopGrowth: propertyItem.msaRecentPopGrowth,
      // msaRecentRentGrowth: propertyItem.msaRecentRentGrowth,
      // subMarketProjectedPopGrowth: propertyItem.subMarketProjectedPopGrowth,
      // subMarketRecentPopGrowth: propertyItem.subMarketRecentPopGrowth,
      // subMarketRecentRentGrowth: propertyItem.subMarketRecentRentGrowth,
      // subProjectedRentGrowth: propertyItem.subProjectedRentGrowth,
      totalSf: propertyItem.totalSf,
      totalUnits: propertyItem.totalUnits,
    }));

    const dynamicValuesProceeds = {
      OOCosts: findDst?.proceeds?.OOCosts,
      brokerDealerCommissions: findDst?.proceeds?.brokerDealerCommissions,
      financingFee: findDst?.proceeds?.financingFee,
      otherFees: findDst?.proceeds?.otherFees,
      thirdPartyClosingCosts: findDst?.proceeds?.thirdPartyClosingCosts,
      totalDstReserves: findDst?.proceeds?.totalDstReserves,
      transactionCommission: findDst?.proceeds?.transactionCommission,
      wholesalingCosts: findDst?.proceeds?.wholesalingCosts,
      year5ReservesBalance: findDst?.proceeds?.year5ReservesBalance,
    };

    const dynamicValuesOtherInfo = {
      dispositionFee: findDst?.otherInfo?.dispositionFee,
    };

    if (
      JSON.stringify(dynamicValues) !== JSON.stringify(lastDynamicValues) ||
      JSON.stringify(dynamicValuesYields) !==
      JSON.stringify(lastDynamicValuesYields) ||
      JSON.stringify(dynamicValuesProperty) !==
      JSON.stringify(lastDynamicValuesProperty) ||
      JSON.stringify(dynamicValuesProceeds) !==
      JSON.stringify(lastDynamicValuesProceeds) ||
      JSON.stringify(dynamicValuesOtherInfo) !==
      JSON.stringify(lastDynamicValuesOtherInfo)
    ) {
      updateBasicInfo(findDst, dynamicValues);
      updateYields(findDst, dynamicValuesYields);
      updateProperty(findDst, dynamicValuesProperty);
      updateProceeds(findDst, dynamicValuesProceeds);
      // updateOtherInfo(findDst, dynamicValuesOtherInfo);

      setLastDynamicValues(dynamicValues);
      setLastDynamicValuesYields(dynamicValuesYields);
      setLastDynamicValuesProperty(dynamicValuesProperty);
      setLastDynamicValuesProceeds(dynamicValuesProceeds);
      setLastDynamicValuesOtherInfo(dynamicValuesOtherInfo);
    }
  }, [findDst]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  function updateBasicInfo(dataObject, updates) {
    if (dataObject && dataObject.basicInfo) {
      const basicInfo = dataObject.basicInfo;
      let updatedBasicInfo = { ...basicInfo };
      let hasChanges = false;

      for (const fieldName in updates) {
        if (updates.hasOwnProperty(fieldName)) {
          const newValue = updates[fieldName];
          const cleanedValue = newValue.toString().replace(/[^0-9.]/g, "");

          if (basicInfo[fieldName] !== cleanedValue) {
            updatedBasicInfo[fieldName] = cleanedValue;
            hasChanges = true;
          }
        }
      }

      if (hasChanges) {
        setFindDst((prevFindDst) => ({
          ...prevFindDst,
          basicInfo: updatedBasicInfo,
        }));
      }
    } else {
      console.error("Invalid data structure.");
    }
  }

  function updateProperty(dataObject, updatesArray) {
    if (
      dataObject &&
      dataObject.property &&
      Array.isArray(dataObject.property)
    ) {
      const properties = dataObject.property.map((propertyToUpdate, index) => {
        let updatedProperty = { ...propertyToUpdate };
        let hasChanges = false;

        const updates = updatesArray[index];

        for (const fieldName in updates) {
          if (updates.hasOwnProperty(fieldName)) {
            const newValue = updates[fieldName];
            const cleanedValue = newValue.toString().replace(/[^0-9.]/g, "");

            if (propertyToUpdate[fieldName] !== cleanedValue) {
              updatedProperty[fieldName] = cleanedValue;
              hasChanges = true;
            }
          }
        }

        return hasChanges ? updatedProperty : propertyToUpdate;
      });

      setFindDst((prevFindDst) => ({
        ...prevFindDst,
        property: properties,
      }));
    } else {
      console.error("Invalid data structure or missing property array.");
    }
  }

  function updateYields(dataObject, updates) {
    if (dataObject && dataObject.yields) {
      const yields = dataObject.yields;
      let updatedYields = { ...yields };
      let hasChanges = false;

      for (const fieldName in updates) {
        if (updates.hasOwnProperty(fieldName)) {
          const newValue = updates[fieldName];
          const cleanedValue = newValue.toString().replace(/[^0-9.]/g, "");

          if (yields[fieldName] !== cleanedValue) {
            updatedYields[fieldName] = cleanedValue;
            hasChanges = true;
          }
        }
      }

      if (hasChanges) {
        setFindDst((prevFindDst) => ({
          ...prevFindDst,
          yields: updatedYields,
        }));
      }
    } else {
      console.error("Invalid data structure.");
    }
  }

  function updateProceeds(dataObject, updates) {
    if (dataObject && dataObject.proceeds) {
      const proceeds = dataObject.proceeds;
      let updatedProceeds = { ...proceeds };
      let hasChanges = false;

      for (const fieldName in updates) {
        if (updates.hasOwnProperty(fieldName)) {
          const newValue = updates[fieldName];
          const cleanedValue = newValue.toString().replace(/[^0-9.]/g, "");

          if (proceeds[fieldName] !== cleanedValue) {
            updatedProceeds[fieldName] = cleanedValue;
            hasChanges = true;
          }
        }
      }

      if (hasChanges) {
        setFindDst((prevFindDst) => ({
          ...prevFindDst,
          proceeds: updatedProceeds,
        }));
      }
    } else {
      console.error("Invalid data structure.");
    }
  }

  function updateOtherInfo(dataObject, updates) {
    if (dataObject && dataObject.otherInfo) {
      const otherInfo = dataObject.otherInfo;
      let updatedOtherInfo = { ...otherInfo };
      let hasChanges = false;

      for (const fieldName in updates) {
        if (updates.hasOwnProperty(fieldName)) {
          const newValue = updates[fieldName];
          const cleanedValue = newValue.toString().replace(/[^0-9.]/g, "");

          if (otherInfo[fieldName] !== cleanedValue) {
            updatedOtherInfo[fieldName] = cleanedValue;
            hasChanges = true;
          }
        }
      }

      if (hasChanges) {
        setFindDst((prevFindDst) => ({
          ...prevFindDst,
          otherInfo: updatedOtherInfo,
        }));
      }
    } else {
      console.error("Invalid data structure.");
    }
  }

  console.log(findDst, "findDstfindDstfindDstfindDst");

  return (
    <>
      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <ReactToPrint
          trigger={() => (
            <Button variant="contained" sx={{ mt: 3, mr: 4 }}>
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          columnGap: "1.5rem",
          marginTop: "2rem",
          marginRight: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Typography
            style={{ color: portraitLayout === false ? "#1565c0" : "#333" }}
          >
            Portrait
          </Typography>
          <PinkSwitch
            checked={portraitLayout}
            onChange={() => setPortraitLayout(!portraitLayout)}
          />
          <Typography
            style={{
              color: portraitLayout === true ? "#1565c0" : "#333",
            }}
          >
            Landscape
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {portraitLayout ? <LandscapeOrientation /> : <PortraitOrientation />}
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                }}
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        </div>
      </div>
      <div ref={componentRef} style={{ margin: "1rem 1rem" }}>
        <div style={{ pageBreakInside: "avoid" }}>
          <DstDetailPrint findDst={findDst} image={image} />
          <div style={{ pageBreakAfter: "always" }}></div>
        </div>
        <hr />
        <div
          // if want to brake the page every component then uncomment this
          style={{ pageBreakInside: "avoid" }}
        >
          <DstDetailPrint1 findDst={findDst} />
          <div style={{ pageBreakAfter: "always" }}></div>
        </div>
        <div style={{ pageBreakInside: "avoid" }}>
          <DstDetailPrint3 findDst={findDst} />
          <div style={{ pageBreakAfter: "always" }}></div>
        </div>

        <DstDetailPrint2 findDst={findDst} />
      </div>
    </>
  );
};

export default DSTPrintView;
