import {
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography,
} from "@material-ui/core";
import React, {
  Fragment,
  useRef,
  useContext,
  useEffect,
  useState,
} from "react";
import { Box, Button } from "@mui/material";
import ReactToPrint from "react-to-print";
import { API } from "aws-amplify";
import { useLocation, useParams } from "react-router-dom";
import MyContext from "../context/appContext";
import { toast } from "react-toastify";
import {
  LandscapeOrientation,
  PortraitOrientation,
} from "../utills/landscapeOrientation.js";
import { pink } from "@mui/material/colors";
import { alpha, makeStyles, styled } from "@material-ui/core/styles";
import Chart from "../components/taxEstimatorChart.js";

const AnalysisTaxReport = () => {
  const componentRef = useRef();
  const domRef = sessionStorage.getItem("domRef");
  const appContext = useContext(MyContext);
  const companyName = localStorage.getItem("companyName");
  const [portraitLayout, setPortraitLayout] = useState(false);
  const { state } = useLocation();
  const taxDataFromState = state?.formData;
  const [loading, setLoading] = useState(false);
  const [taxData, setTaxAnalysisData] = useState([]);
  const [federalTaxList, setFederalTaxList] = useState([]);
  const [isConcordeBroker, setIsConcordeBroker] = useState(false);
  const [stateList, setStateList] = useState([]);

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  useEffect(() => {
    if (taxDataFromState && typeof taxDataFromState === "object") {
      setTaxAnalysisData(taxDataFromState);
    }
  }, [taxDataFromState]);

  useEffect(() => {
    getFederalTax();
    getStates();
    fetchBrokerDealers();

  }, []);

  useEffect(() => {
    if (taxData) {
      const dynamicValues = {
        allOther: taxData.allOther,
        amountBorrowed: taxData.amountBorrowed,
        OtherMisc: taxData.OtherMisc,
        adjustedbasis: taxData.adjustedbasis,
        annualPropertyTax: taxData.annualPropertyTax,
        associationDues: taxData.associationDues,
        bookkeeping: taxData.bookkeeping,
        capexSincePurchase: taxData.capexSincePurchase,
        depreciationClaimed: taxData.depreciationClaimed,
        eachUnitVacany: taxData.eachUnitVacany,
        estimatedGrossValue: taxData.estimatedGrossValue,
        expectedSale: taxData.expectedSale,
        facilityMaintenance: taxData.facilityMaintenance,
        forwardlosses: taxData.forwardlosses,
        grossRent: taxData.grossRent,
        hardscaping: taxData.hardscaping,
        hardscapingYears: taxData.hardscapingYears,
        hoaFee: taxData.hoaFee,
        hvac: taxData.hvac,
        hvacYears: taxData.hvacYears,
        interestRate: taxData.interestRate,
        issurance: taxData.issurance,
        landscape: taxData.landscape,
        legal: taxData.legal,
        loanBalance: taxData.loanBalance,
        loanTerms: taxData.loanTerms,
        majorRepairs: taxData.majorRepairs,
        marketing: taxData.marketing,
        mortgagepayment: taxData.mortgagepayment,
        personalhoursSpent: taxData.personalhoursSpent,
        plumbing: taxData.plumbing,
        plumbingYears: taxData.plumbingYears,
        propertyManagement: taxData.propertyManagement,
        purchaseAmount: taxData.purchaseAmount,
        reimbursedUtility: taxData.reimbursedUtility,
        reimbursements: taxData.reimbursements,
        roof: taxData.roof,
        roofYears: taxData.roofYears,
        security: taxData.security,
        structural: taxData.structural,
        structuralYears: taxData.structuralYears,
        tenants: taxData.tenants,
        trash: taxData.trash,
        unitTurnOver: taxData.unitTurnOver,
        water: taxData.water,
        valueImprovements: taxData.valueImprovements,
        cumulativeDepreciation: taxData.cumulativeDepreciation,
      };

      updateNumericFields(taxData, dynamicValues);
    }
  }, [taxData]);

  const fetchBrokerDealers = async () => {
    try {
      const params = {
        headers: { 'Content-Type': 'application/json' }
      };
      const res = await API.get("dynamoRW", "/1031_broker_Dealer", params);
      if (res.items) {
        const activeBrokers = res.items.filter(broker =>
          broker.status !== false &&
          broker.name.toLowerCase() === 'concorde'
        );
        setIsConcordeBroker(activeBrokers.length > 0);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(`Error fetching broker dealers! ${error.message}`);
    }
  };
  function updateNumericFields(dataObject, updates) {
    if (dataObject) {
      const updatedTaxData = { ...dataObject };
      let hasChanges = false;

      for (const fieldName in updates) {
        if (updates.hasOwnProperty(fieldName)) {
          const newValue = updates[fieldName];
          if (dataObject.hasOwnProperty(fieldName)) {
            const cleanedValue = newValue
              ? newValue.toString().replace(/[^0-9.]/g, "")
              : "";

            if (dataObject[fieldName] !== cleanedValue) {
              updatedTaxData[fieldName] = cleanedValue;
              hasChanges = true;
            }
          } else {
            console.warn(`Field "${fieldName}" not found in dataObject.`);
          }
        }
      }

      if (hasChanges) {
        setTaxAnalysisData(updatedTaxData);
      }
    } else {
      console.error("Invalid data structure.");
    }
  }

  console.log("after setting", taxData);

  const getFederalTax = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "federal_tax-dev",
          id: "all",
          LastEvaluatedKey: "None",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      setFederalTaxList(res);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No Data Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };
  console.log(federalTaxList, "SELECTED DST");

  const getStates = async () => {
    try {
      appContext.updateState("loading", true);
      const payload = {
        queryStringParameters: {
          tableName: "state_info-dev",
          id: "all",
          LastEvaluatedKey: "None",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      console.log(res, "RES");
      setStateList(res);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
      toast(`No State Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  console.log(stateList, "SELECTED DST");

  {
    /* Just For Calculations Purpose ------------  Start  */
  }
  const purchase_Price = +taxData?.purchaseAmount;
  const capital_Improvements = +taxData?.capexSincePurchase;
  const totalCapital_In = purchase_Price + capital_Improvements;
  const valueOf_Improvements =
    (+taxData?.valueImprovements * purchase_Price) / 100 + capital_Improvements;
  const divide_Usefull =
    taxData?.residentialOrCommercial === "residential" ? 27.5 : 39;
  const annual_Depreciation = (valueOf_Improvements / divide_Usefull).toFixed(
    2
  );
  const multiply_Depreciated = +taxData?.depreciationClaimed;
  const total_depAcuumulated = annual_Depreciation * multiply_Depreciated;
  const minus_TotalDepreciation = total_depAcuumulated;
  const adjustedCost_Basis = totalCapital_In - total_depAcuumulated;

  {
    /* Just For Calculations Purpose ------------  End */
  }
  const netSalesPrice =
    +taxData?.estimatedGrossValue -
    +((+taxData?.estimatedGrossValue * +taxData?.expectedSale) / 100);

  const propertyTypeValue =
    taxData?.residentialOrCommercial === "residential" ? 27.5 : 39;

  const totalCumulative =
    +taxData?.cumulativeDepreciation > 0
      ? +taxData?.cumulativeDepreciation
      : ((+taxData?.purchaseAmount * +(+taxData?.valueImprovements / 100)) /
        +propertyTypeValue) *
      +taxData?.depreciationClaimed;

  const adjBasis =
    +taxData?.adjustedbasis > 0
      ? +taxData?.adjustedbasis
      : +taxData?.purchaseAmount -
      +totalCumulative +
      +taxData?.capexSincePurchase;

  const grossCapGain = (
    +(
      +taxData?.estimatedGrossValue -
      +((+taxData?.estimatedGrossValue * +taxData?.expectedSale) / 100)
    ) - +adjBasis
  ).toFixed(2);
  const netCapitalGain = +grossCapGain - +taxData?.forwardlosses;

  const fedLTCGObj = federalTaxList?.find(
    (item) => item.type === "Long-Term Capital Gain"
  );

  const DepreceationRecaptureObj = federalTaxList?.find(
    (item) => item.type === "Depreciation Recapture"
  );

  const NIITObj = federalTaxList?.find((item) => item.type === "NIIT Tax");

  // const fedLTCGValue2 =
  //   +(+fedLTCGObj?.percent * (netCapitalGain - total_depAcuumulated)) / 100;

  const fedLTCGValue2 =
    (+netCapitalGain - +totalCumulative) * (+fedLTCGObj?.percent / 100);

  // const DepreceationRecaptureValue2 =
  //   (+DepreceationRecaptureObj?.percent * total_depAcuumulated) / 100;

  const DepreceationRecaptureValue2 =
    +totalCumulative * (+DepreceationRecaptureObj?.percent / 100);

  // const NIITValue2 = +(+NIITObj?.percent * netCapitalGain) / 100;

  const NIITValue2 = (+NIITObj?.percent / 100) * +netCapitalGain;

  const propertyStateObj = stateList?.find(
    (itm) => `${itm.state} - ${itm.code}` === taxData?.propertyState
  );

  const stateTax = (+netCapitalGain * +propertyStateObj?.gainTax) / 100;

  const residenceStateObj = stateList?.find(
    (itm) => `${itm.state} - ${itm.code}` === taxData?.clientStateResidance
  );

  const propertyStateTaxRate = +propertyStateObj?.gainTax || 0;
  const residenceStateTaxRate = +residenceStateObj?.gainTax || 0;
  const higherTaxRate = Math.max(propertyStateTaxRate, residenceStateTaxRate);

  const EsttaxOnGain =
    +fedLTCGValue2 +
    +DepreceationRecaptureValue2 +
    +NIITValue2 +
    +netCapitalGain * (higherTaxRate / 100);
  const EsttaxOnGainValue2 = (EsttaxOnGain / netCapitalGain) * 100;

  const graphLTCGPercent = +(
    (fedLTCGValue2 / (netSalesPrice - +taxData?.loanBalance)) *
    100
  ).toFixed(1);
  const graphDepRecapPercent = +(
    (DepreceationRecaptureValue2 / (netSalesPrice - +taxData?.loanBalance)) *
    100
  ).toFixed(1);
  const graphNIITPercent = +(
    (NIITValue2 / (netSalesPrice - +taxData?.loanBalance)) *
    100
  ).toFixed(1);

  const graphStatePercent = +(
    ((netCapitalGain * (higherTaxRate / 100)) /
      (netSalesPrice - +taxData?.loanBalance)) *
    100
  ).toFixed(1);

  const taxTotals = +netSalesPrice - +taxData?.loanBalance;
  const graphTotal =
    fedLTCGValue2 + DepreceationRecaptureValue2 + NIITValue2 + stateTax;

  const availableToReInvest = +taxTotals - +graphTotal;

  const availableToReInvestPercent =
    +graphLTCGPercent +
    +graphDepRecapPercent +
    +graphNIITPercent +
    +graphStatePercent;

  const data = [
    { name: "Total Tax", value: EsttaxOnGain },
    { name: "Available", value: availableToReInvest },
  ].map(({ name, value }) => ({ name, value: Math.round(value) }));

  const reportNewDate = new Date(taxData?.reportDate);
  const ReportformattedDate = reportNewDate.toLocaleDateString("en-US");
  const ResponseDataPage = () => (
    <Box p={2} style={{ pageBreakBefore: "always" }}>
      <Typography variant="h5" gutterBottom>
        Additional Response Data
      </Typography>
      <Box>
        {Object.entries(taxData).map(([key, value]) => (
          <Typography key={key}>
            <strong>{key}:</strong> {value || "N/A"}
          </Typography>
        ))}
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          columnGap: "1.5rem",
          margin: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Typography
            style={{ color: portraitLayout === false ? "#1565c0" : "#333" }}
          >
            Portrait
          </Typography>
          <PinkSwitch
            checked={!portraitLayout}
            onChange={() => setPortraitLayout(!portraitLayout)}
          />
          <Typography
            style={{
              color: portraitLayout === true ? "#1565c0" : "#333",
            }}
          >
            Landscape
          </Typography>
        </div>
        {portraitLayout ? <PortraitOrientation /> : <LandscapeOrientation />}

        <ReactToPrint
          trigger={() => <Button variant="contained">Print</Button>}
          content={() => componentRef.current}
        // Before printing, set the state to show the response data
        />
      </div>
      <div>
        <Grid
          container
          style={{
            paddingRight: `2rem`,
            paddingLeft: "2rem",
            marginTop: "1rem",
          }}
          ref={componentRef}
        >
          <Grid container style={{ marginBottom: "1rem" }}>
            <Grid item xs={12} sm={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "0.8rem",
                }}
              >
                <h3 style={{ margin: 0 }}>TAX ILLUSTRATOR (ANALYSIS)</h3>
              </div>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <p style={{ margin: "0rem", fontSize: "0.9rem" }}>
                  <i>Prepared For</i> | <b>{taxData?.clientName}</b>
                </p>
                <p style={{ marginTop: "0.2rem", fontSize: "0.9rem" }}>
                  <i>Property</i> | <b>{taxData?.streetAddress}</b>
                </p>
              </Grid>
              <Grid item xs={3} style={{ textAlign: "end" }}>
                <p style={{ marginTop: "0.2rem", fontSize: "0.9rem" }}>
                  <i>Report Date</i> &nbsp; |{" "}
                  <b>
                    {ReportformattedDate == "Invalid Date"
                      ? "N/A"
                      : ReportformattedDate || ""}
                  </b>
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ color: "#c18e0d", fontSize: "0.9rem" }}>
              Transaction Data from Questionnaire
            </div>
            <Card
              style={{
                border: `1px solid black`,
                margin: 5,
                padding: 14,
                background: "#a7cce882",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 4, fontSize: 15 }}>
                  {" "}
                  <div style={{ fontSize: "0.8rem" }}>Gross Sales Price</div>
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontSize: "0.8rem" }}>$</div>
                    <div>
                      {" "}
                      <div style={{ fontSize: "0.8rem" }}>
                        {Number(
                          (+taxData?.estimatedGrossValue).toFixed()
                        ).toLocaleString("en-US") || 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 4, fontSize: 15 }}>
                  {" "}
                  <div style={{ fontSize: "0.8rem" }}>Selling Costs</div>
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontSize: "0.8rem" }}>$</div>
                    <div>
                      {" "}
                      <div style={{ fontSize: "0.8rem" }}>
                        {Number(
                          (
                            (+taxData?.estimatedGrossValue *
                              +taxData?.expectedSale) /
                            100
                          ).toFixed()
                        ).toLocaleString("en-US") || 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 4, fontSize: 15 }}>
                  {" "}
                  <div style={{ fontSize: "0.8rem" }}>Net Sales Price</div>
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontSize: "0.8rem" }}>$</div>
                    <div>
                      {" "}
                      <div style={{ fontSize: "0.8rem" }}>
                        {Number(netSalesPrice.toFixed()).toLocaleString(
                          "en-US"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 4, fontSize: 15 }}>
                  {" "}
                  <div style={{ fontSize: "0.8rem" }}>
                    Total Cumulative Depreciation Claimed
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontSize: "0.8rem" }}>$</div>
                    <div>
                      {" "}
                      <div style={{ fontSize: "0.8rem" }}>
                        {Number(+totalCumulative.toFixed()).toLocaleString(
                          "en-US"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 4, fontSize: 15 }}>
                  {" "}
                  <div style={{ fontSize: "0.8rem" }}>Adjusted Basis</div>
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontSize: "0.8rem" }}>$</div>
                    <div>
                      {" "}
                      <div style={{ fontSize: "0.8rem" }}>
                        {Number(+adjBasis.toFixed()).toLocaleString("en-US")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 4, fontSize: 15 }}>
                  {" "}
                  <div style={{ fontSize: "0.8rem" }}>Gross Capital Gain</div>
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontSize: "0.8rem" }}>$</div>
                    <div>
                      {" "}
                      <div style={{ fontSize: "0.8rem" }}>
                        {Number((+grossCapGain).toFixed()).toLocaleString(
                          "en-US"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 4, fontSize: 15 }}>
                  {" "}
                  <div style={{ fontSize: "0.8rem" }}>
                    Capital Gain Offset (Sec. 121 or applied losses)
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontSize: "0.8rem" }}>$</div>
                    <div>
                      {" "}
                      <div style={{ fontSize: "0.8rem" }}>
                        {Number(
                          (+taxData?.forwardlosses).toFixed()
                        ).toLocaleString("en-US")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 4, fontSize: 15 }}>
                  {" "}
                  <div style={{ fontSize: "0.8rem" }}>Net Capital Gain</div>
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontSize: "0.8rem" }}>$</div>
                    <div>
                      {" "}
                      <div style={{ fontSize: "0.8rem" }}>
                        {Number(netCapitalGain.toFixed()).toLocaleString(
                          "en-US"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 4, fontSize: 15 }}>
                  {" "}
                  <div style={{ fontSize: "0.8rem" }}>Current Loan Amount</div>
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontSize: "0.8rem" }}>$</div>
                    <div>
                      {" "}
                      <div style={{ fontSize: "0.8rem" }}>
                        {Number(
                          (+taxData?.loanBalance).toFixed()
                        ).toLocaleString("en-US")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            <br />
            <Card
              style={{
                border: `1px solid black`,
                margin: 5,
                padding: 14,
                background: "#DADADA",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontSize: "0.9rem" }}>
                  {" "}
                  Net Proceeds Before Tax &nbsp;
                </div>
                <div style={{ fontSize: "0.9rem" }}>
                  {" "}
                  <b>{`$ ${Number(taxTotals.toFixed()).toLocaleString(
                    "en-US"
                  )}`}</b>
                </div>
              </div>
            </Card>
            <Card
              style={{
                border: `1px solid black`,
                margin: 5,
                padding: 14,
                background: "#DADADA",
                marginTop: "0.5rem",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontSize: "0.9rem" }}> Estimated Tax &nbsp;</div>
                <div style={{ fontSize: "0.9rem" }}>
                  {" "}
                  <b>{`$ ${Number(EsttaxOnGain.toFixed()).toLocaleString(
                    "en-US"
                  )}`}</b>
                </div>
              </div>
            </Card>
          </Grid>

          <Grid item xs={5} style={{ paddingLeft: 15 }}>
            <div
              style={{ color: "#c18e0d", marginBottom: 5, fontSize: "0.9rem" }}
            >
              Tax Category Breakdown
            </div>
            <List style={{ width: "100%", padding: `0` }}>
              {[
                {
                  name: `Tax Category`,
                  value: `Assumed Rate`,
                  value2: `Amount`,
                },
                {
                  name: `Federal LTCG`,
                  value: `${+fedLTCGObj?.percent}%`,
                  value2: ` ${Number(fedLTCGValue2.toFixed()).toLocaleString(
                    "en-US"
                  )}`,
                },
                {
                  name: `Depreciation Recapture`,
                  value: `${+DepreceationRecaptureObj?.percent}%`,
                  value2: ` ${Number(
                    DepreceationRecaptureValue2.toFixed()
                  ).toLocaleString("en-US")}`,
                },
                {
                  name: `NIIT`,
                  value: `${+NIITObj?.percent}%`,
                  value2: ` ${Number(NIITValue2.toFixed()).toLocaleString(
                    "en-US"
                  )}`,
                },
                {
                  name: `State`,
                  value: `${higherTaxRate}%`,
                  // value2: ` ${Number(stateTax.toFixed()).toLocaleString(
                  //   "en-US"
                  // )}`,
                  value2: Number(
                    (netCapitalGain * (higherTaxRate / 100)).toFixed(0)
                  ).toLocaleString("en-US"),
                },
                {
                  name: `Estimated Tax on Gain`,
                  value: `${EsttaxOnGainValue2.toFixed(1)}%`,
                  value2: ` ${Number(EsttaxOnGain.toFixed()).toLocaleString(
                    "en-US"
                  )}`,
                },
              ].map((value, index) => (
                <ListItem
                  key={value}
                  style={{
                    padding: `0.2rem 1rem`,
                    background: index % 2 === 0 ? `white` : `#dadada`,
                    borderTop:
                      index === 5
                        ? "1px solid black"
                        : index === 0
                          ? "1px solid black"
                          : "",
                    borderBottom: index === 5 ? "1px solid black" : "",
                    borderRight: "1px solid black",
                    borderLeft: "1px solid black",
                  }}
                  disableGutters
                >
                  <Grid container spacing={2}>
                    <Grid item xs={5} style={{ borderRight: "1px solid #fff" }}>
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            fontSize: "0.8rem",
                            fontWeight:
                              index === 0 ? "bold" : index === 5 ? "bold" : "",
                          },
                        }}
                        primary={`${value.name}`}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        textAlign: "center",
                        borderRight: "1px solid #fff",
                      }}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            fontSize: "0.8rem",
                            fontWeight:
                              index === 0 ? "bold" : index === 5 ? "bold" : "",
                          },
                        }}
                        primary={`${value.value}`}
                      />
                    </Grid>
                    <Grid item xs={4} style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          textAlign: "right",
                        }}
                      >
                        <Grid item xs={5} style={{ textAlign: "right" }}>
                          <p style={{ margin: "0px", fontSize: "0.8rem" }}>$</p>
                        </Grid>
                        <Grid item xs={7}>
                          <ListItemText
                            primaryTypographyProps={{
                              style: {
                                fontSize: "0.8rem",
                                fontWeight:
                                  index === 0
                                    ? "bold"
                                    : index === 5
                                      ? "bold"
                                      : "",
                              },
                            }}
                            primary={`${value.value2}`}
                          />
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
            <br />
            <br />
            <Grid container spacing={0}>
              <Grid item xs={7}>
                <div style={{ color: "#c18e0d" }}>Equity Breakdown</div>
                <List
                  style={{
                    background: `#ededed`,
                    border: `1px solid black`,
                    padding: `0`,
                  }}
                >
                  {[
                    {
                      name: `Federal LTCG`,
                      value: graphLTCGPercent,
                    },
                    {
                      name: `Depreciation Recapture`,
                      value: graphDepRecapPercent,
                    },
                    { name: `NIIT`, value: graphNIITPercent },
                    {
                      name: `State`,
                      value: graphStatePercent,
                    },
                    {
                      name: `Total Tax`,
                      value: availableToReInvestPercent,
                    },
                  ].map((value, index) => (
                    <ListItem
                      key={value}
                      style={{
                        padding: `0 6px`,
                        borderTop: index === 4 ? "1px solid black" : "",
                      }}
                      disableGutters
                    >
                      <div
                        style={{
                          display: `flex`,
                          alignItems: "center",
                          textAlign: "left",
                          width: "100%",
                        }}
                      >
                        <Grid item xs={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Grid item xs={8}>
                              <ListItemText
                                primaryTypographyProps={{
                                  style: {
                                    marginLeft: 5,
                                    fontSize: 12,
                                    fontWeight: "bold",
                                  },
                                }}
                                primary={` ${value.name}`}
                              />
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: "right" }}>
                              <ListItemText
                                primaryTypographyProps={{
                                  style: {
                                    marginLeft: 5,
                                    fontSize: 12,
                                    fontWeight: "bold",
                                  },
                                }}
                                primary={` ${value.value.toFixed(2)}%`}
                              />
                            </Grid>
                          </div>
                        </Grid>
                      </div>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid
                item
                xs={5}
                className="Grid-Gap"
                style={{ height: "180px" }}
              >
                <Chart data={data} />
              </Grid>
            </Grid>
          </Grid>
          <div style={{ marginTop: "auto 0" }}>
            <p style={{ color: "red", fontSize: "0.9rem" }}>
              ESTIMATED FIGURES FOR ILLUSTRATIVE PURPOSES ONLY. DO NOT RELY ON
              THIS INFORMATION AS TAX GUIDANCE. CONSULT WITH YOUR QUALIFIED TAX
              OR LEGAL PROFESSIONAL.
            </p>
            <Grid container spacing={2}>
              <Grid item xs={9} className="Grid-Gap">
                <p style={{ fontSize: "0.7rem" }}>
                  <i>
                    This illustration is for information purposes only and is
                    not a solicitation to buy or cell any security. Such
                    solicitations must be made by prospectus or offering
                    memorandum. <b>{companyName}</b> does not provide tax or
                    legal advice, as such advice can only be provided by a
                    qualified tax or legal professional, who all investors
                    should consult prior to making any investment decision.
                    {isConcordeBroker && <i> Securities offered through Concorde Investment Services, LLC
                      (CIS), member FINRA/SIPC. Advisory services offered through
                      Concorde Asset Management, LLC (CAM), an SEC registered
                      investment adviser.</i>}
                  </i>
                </p>
              </Grid>
              <Grid item xs={3} className="Grid-Gap">
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "2rem",
                  }}
                >
                  <img
                    src="/faviconPNG.png"
                    style={{ height: "30px" }}
                    className="img-invert"
                  />
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div
            style={{ pageBreakInside: "avoid" }}
            className="print-only"
            dangerouslySetInnerHTML={{ __html: domRef }}
          />
        </Grid>
      </div>
    </Fragment>
  );
};

export default AnalysisTaxReport;
